import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './../Orders/orders.module';
import GIDS from './constants/gids';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CircularProgress from '@material-ui/core/CircularProgress';

import jwt from 'jsonwebtoken';

class DownloadExcel extends React.Component {
  state = {
    link: null,
    loading: false,
    id: null,
    options: null,
    access_token: null,
    gid: null,
    gids: GIDS,
  };

  componentDidMount() {
    let gid = this.state.gids.others;
    if (this.props.partner in this.state.gids) {
      gid = this.state.gids[this.props.partner];
    }
    this.setState({
      access_token: this.props.access_token,
      id: this.props.id,
      gid: gid,
      options: this.props.options,
      link: this.props.link.substring(0, this.props.link.lastIndexOf('/ed')),
      name:
        this.props.name + '-' + this.props.date.split('-')[1] + '/' + this.props.date.split('-')[0],
    });
  }

  componentDidUpdate() {
    if (this.props.access_token !== this.state.access_token) {
      this.setState({ access_token: this.props.access_token });
    }
  }

  googleAuth = async () => {
    if (!this.state.access_token) {
      await this.setState({ loading: true });
      var pHeader = { algorithm: 'RS256' };

      var pClaim = {};
      pClaim.aud = 'https://www.googleapis.com/oauth2/v3/token';
      pClaim.scope = 'https://www.googleapis.com/auth/analytics.readonly';
      pClaim.iss = 'vlhub-413@vlhub-278518.iam.gserviceaccount.com';

      var dt = new Date().getTime() / 1000;
      dt = Math.round(dt);
      pClaim.exp = dt + 3600;
      pClaim.iat = dt;

      const GOOGLE_PRIVATE_KEY =
        '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCySNghiEh1i1+j\nZ3VJ68k90MnK7j8iMIuQwrb+aYxew8nS86lpHCBPilYxommMkFBXgj8ZxpPi5aT/\naN9ridRGnNbIIDI0risxherOpjt0M/Sed1R0tE29Qsj18MNOtsnHOCACo+/TG+Jo\nTx0K4vd1JNyZwJYwNSNDQa/1i8+EiT2McVkjt8aFADoMKAJyCzatfXOFDBWXzxND\n19pl5uzKm6RnKHxJSE8VgMtdd4ZprImuVuB8obeGH+yvOIOTZKxgI8bqXPqZSkV8\nYNLcTHW5yIkUFPLBSUhww/Q0sM7OPiJXOntg3kyGul0rbiXWXZ5Zn7I8bs2EbzUf\nuOz/3aCvAgMBAAECggEAK1hNf72QDE/x1Rr6p5m1SBuia/2HiOrvi6bjcbA8qe+E\n+o42AnGNo3j5IsEfU0bk2Zm3eK9QVipJ9rTLGOyeGPrZtzcorakopDYwU8STkNlu\n4DYX6PXLLrf1fuHlwhXrsc1B7xJnZNaTMZQGdhafXjIzlgf+G8NXiusF+jdfIRQJ\ni9w5GE+xMHwJOE/guddxZH1CVovZ5BVXYE98/VexwouhrY44l2mJL92FbSL/aJF+\n7D2g0y5Hq4VA2q6nRQirCTQAgW5AplWIRWW/fK6Y/DZlocbcPnzRy8Mkd5MpYg2M\nU/ayU+2GEjEF8k0HI4ltnjeSoZ4kAQQTijWV3L67zQKBgQD7REMBSJNPu7bOhr2M\n0RAFGxa7MjLM0AFy6y9uOZls1wmA24hBVndR5QLoraCFF9usvyoq8ToYLr4dizq1\n6+QabZYIpGTI/ne4ijseu3HjfDODJDJn8QFnJ4oKNvm6w5hCHwHd7aEjRlYIsCEI\nZHTtscfBNVAf6JTrfxREJ9YSQwKBgQC1pJ/79idz0bGyuTi7cYiHgZGhDdS0zneb\nAG3rKNhtdnf4c4kPQsM56ldBm1sfHUdvnLP7em/DPNtnjyHyQmtJ3a6tQnqXJ0iJ\nBtdKpJ23NInVzmTT0MD0Z3kRn9OthimTv+WLjL1A6AoIUeXGQ/0wU/Sv+niNww3o\nxbs5ZC+/JQKBgFKGsA863A9jEv6wKI+Yls9mf0fKi/7TDrbE4hYs2uRxmFB3SkBT\neust8Y+kf0VXebI6M0pZYoBQ0eZ4NTdNG+VGOvHptKSDV6UH2bUVUamqwGyBNgEH\nmjNcPC1wswMRfO+yqXVPuA4S6uz9lEviWrk7G+Wscf2Y8ViLq4dbIidrAoGAWq0P\n4GwFTeAEYT/tfkUy7tJTrJ6G7KuVMZ+JP7doM1zMjw6+AQxHAQZnAK1ZU7L+Ek2b\n25JHcKNUs+HUBGf7vygXJw7ku8g0gZMUroJ26RolJMKfus2fRWLSIWYz+Y/b+ZH2\nOS/M2jX7rCOWHN+b6SBAKglTXRFeRDFRQVkuLLECgYAQOc9OKz0R/31/qx8nxFFo\nZWSBCcfHumMV0vt0jZ7L34kRZE2peZch2Qo7Ij1n4ZFWojlOYrSLh+7xIJmzo+pB\nZUNzs+DPBzwB0bjmYdx3dV1KqpTnILco9AAj5ZN2/5UkxmRNnvyaXL2tKneTnhA1\nG+r5W22g5Pz7sZbDzS1gRQ==\n-----END PRIVATE KEY-----\n';

      var sJWS = jwt.sign(pClaim, GOOGLE_PRIVATE_KEY, pHeader);

      var XHR = new XMLHttpRequest();
      var urlEncodedData = '';
      var urlEncodedDataPairs = [];

      urlEncodedDataPairs.push(
        encodeURIComponent('grant_type') +
          '=' +
          encodeURIComponent('urn:ietf:params:oauth:grant-type:jwt-bearer'),
      );
      urlEncodedDataPairs.push(encodeURIComponent('assertion') + '=' + encodeURIComponent(sJWS));
      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      // We define what will happen if the data are successfully sent
      XHR.addEventListener('load', () => {
        var response = JSON.parse(XHR.responseText);
        var token = response['access_token'];
        this.props.actions.changeGoogleAccessToken(token);
        this.setState({ access_token: token }, this.updateSheetValues);
      });

      // We define what will happen in case of error
      XHR.addEventListener('error', function(event) {
        console.log('Oops! Something went wrong.');
      });

      XHR.open('POST', 'https://www.googleapis.com/oauth2/v3/token');
      XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      XHR.send(urlEncodedData);
    }
  };

  updateSheetValues = () => {
    this.setState({ loading: true }, () => {
      fetch(`${this.state.link}/export?format=pdf&gid=${this.state.gid}&portrait=false`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.access_token}`,
        },
      })
        .then(response => {
          if (response.ok) {
            return response.blob();
          } else {
            this.setState({ loading: false });
            this.props.setGoogleErrorMessage(`${response.status} ${response.statusText}`);
            throw new Error(response.statusText);
          }
        })
        .then(blob => {
          this.download(blob, this.state.link.substring(this.state.link.indexOf('d/') + 2));
          let ids = [this.state.id];
          this.props.actions.updateDownloadedOrder(ids, null).then(this.fetchLabOrders);
        })
        .catch(error => {
          console.log('Error');
        });
    });
  };

  fetchLabOrders = () => {
    const options = this.state.options;
    this.props.actions.loadLabOrders(options);
  };

  download = (blob, sheet_id) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', this.state.name + '.pdf'); //or any other extension
    link.click();
    this.setState({ loading: false });
  };

  render() {
    return !this.state.loading ? (
      !this.state.access_token ? (
        <span onClick={this.googleAuth}>
          {' '}
          <CloudDownloadIcon />{' '}
        </span>
      ) : (
        <span onClick={this.updateSheetValues}>
          {' '}
          <CloudDownloadIcon />{' '}
        </span>
      )
    ) : (
      <CircularProgress size="1.5rem" />
    );
  }
}

const mapStateToProps = state => {
  return {
    access_token: state.orders.googleAccessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadExcel);
