const OBSERVATION_THEMES = [
  'In-vehicle monitoring system audit',
  'In-field roads audit',
  'Audit of excavation works',
  'Audit of work at height',
  'Audit of communication and shift handover process',
  'Audit of instances of Process Safeguarding Systems',
  'Fitness/Wellness compliance audit',
  'HSSE MS Audit',
  'Hazard management effectiveness audit',
  'Fire and gas detection system audit',
  'RSMT daily report',
  'HSSE site inspection',
  'Camps and production sites complex audit',
  'Complex vehicle documents and loading procedures audit',
  'Transportation contractors audit',
  'Actions from shareholders LFI',
  'Hazard hunt',
  'Hardware barrier assessment',
  'Standing committee lvl 2',
  'Standing committee lvl 3',
  'Management site inspection',
  'Falling objects audit',
  '“Hands-free” program inspection',
  'Lifting equipment inspection',
  'Contractor demobilization inspection',
  'Camp and trailer inspection during hazard period',
  'ISA quality check',
  'Quality check of IRP action items closure',
  'Training quality check',
  'Contractor mobilization inspection',
  'Equipment inspection',
  'Camp general conditions inspection',
  'Check of isolation equipment (electrical, mechanical, etc)',
  'Fire-fighting condition inspection ',
  'Hygiene inspection',
  'Grey area inspection',
  'Permits/Work orders effectiveness inspection',
  'Well control inspection',
  'PPE compliance inspection',
  'Record keeping audit',
  'Inspection of compliance with environmental requirements',
  'Start-up criterion inspection',
  'Camp Fire & Electric safety conditionsinspection',
  'Camp trailer inspection',
  'Punch lists',
  'Sponsorship audit',
];

export default OBSERVATION_THEMES;
