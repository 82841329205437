import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../orders.module';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './filters.css';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CreateManualOrder extends Component {
  state = {
    open: false,
    availableCountries: ['CA', 'US'],
    availableStates: {
      CA: ['BC', 'AB', 'MB', 'NB', 'NL', 'NT', 'NS', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
      US: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FL',
        'GA',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NY',
        'NJ',
        'NM',
        'NC',
        'ND',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY',
      ],
    },
    availablePartners: [],
    activeStates: [],
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    city: '',
    completed_at: '',
    order_link: '',
    country: '',
    state: '',
    partner: '',
    zip: '',
    refill: false,
    ingredient_attributes: [],
    availableUnits: ['mcg', 'mg', 'Bn CFU', 'IU'],
    ingredients: [],
    options: [],
  };

  componentDidMount() {
    this.setState(
      {
        open: this.props.open,
        ingredients: this.props.ingredients,
        availablePartners: this.props.partners,
      },
      this.generateOptions,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open }, this.fetchIngredients);
    }
    if (prevProps.ingredients !== this.props.ingredients) {
      this.setState({ ingredients: this.props.ingredients }, this.generateOptions);
    }
    if (prevProps.partners !== this.props.partners) {
      this.setState({ availablePartners: this.props.partners });
    }
  }

  fetchIngredients = () => {
    if (this.state.open && this.state.ingredients.length === 0) {
      this.props.actions.loadIngredients();
    }
  };

  generateOptions = () => {
    let options = [];
    this.state.ingredients.forEach(ingredient => {
      options.push(ingredient.label_name);
    });
    this.setState({ options: options });
  };

  submitHandler = async (e, close = true) => {
    e.preventDefault();

    if (
      this.state.first_name !== '' &&
      this.state.last_name !== '' &&
      this.state.address !== '' &&
      this.state.city !== '' &&
      this.state.country !== '' &&
      this.state.state
    ) {
      let ingredient_attributes = [];
      this.state.ingredient_attributes.forEach(ingredient => {
        if (ingredient.name !== '' && ingredient.dosage !== '' && ingredient.unit !== '') {
          let _ingredient = {};
          _ingredient['name'] = ingredient.name;
          _ingredient['dosage'] = ingredient.dosage;
          _ingredient['unit'] = ingredient.unit;
          ingredient_attributes.push(_ingredient);
        }
      });

      let requestObj = {};
      requestObj['order'] = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone,
        address: this.state.address,
        address2: this.state.address2,
        order_date: this.state.completed_at,
        order_link: this.state.order_link,
        city: this.state.city,
        state: this.state.state,
        country: this.state.country,
        zip: this.state.zip,
        refill: this.state.refill,
        ingredients_attributes: ingredient_attributes,
      };

      await this.props.actions.createOrder(requestObj, this.state.partner);
      if (!this.props.error) {
        await this.props.loadData(true);
        await this.setState({
          activeStates: [],
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          address: '',
          address2: '',
          city: '',
          completed_at: '',
          order_link: '',
          country: '',
          state: '',
          partner: '',
          zip: '',
          ingredient_attributes: [],
        });
      }

      if (close) {
        this.props.handleClose();
      }
    }
  };

  renderStateOptions = () => {
    let options = [];
    if (this.state.country !== '') {
      options = this.state.availableStates[this.state.country];
    }
    this.setState({ activeStates: options, state: '' });
  };

  removeIngredient(ingredient) {
    this.setState(
      prevState => ({
        ingredient_attributes: prevState.ingredient_attributes.filter(
          _ingredient => _ingredient.index !== ingredient.index,
        ),
      }),
      this.checkIngredientFormValidity,
    );
  }

  updateIngredientForm(index, field, value) {
    let new_ingredient_attributes = [];
    this.state.ingredient_attributes.forEach(ingredient => {
      if (ingredient.index === index) {
        ingredient[field] = value;
        if (field === 'name') {
          this.state.ingredients.forEach(_ingredient => {
            if (_ingredient.label_name === value) {
              ingredient.unit = _ingredient.unit;
            }
          });
        }
      }
      new_ingredient_attributes.push(ingredient);
    });

    this.setState(
      { ingredient_attributes: new_ingredient_attributes },
      this.checkIngredientFormValidity,
    );
  }

  addIngredient = () => {
    let maxIndex = 0;
    let ingredient_attributes = this.state.ingredient_attributes;
    ingredient_attributes.forEach(ingredient => {
      if (ingredient.index > maxIndex) {
        maxIndex = ingredient.index;
      }
    });

    ingredient_attributes.push({ index: maxIndex + 1, name: '', dosage: '', unit: '' });
    this.setState(
      { ingredient_attributes: ingredient_attributes },
      this.checkIngredientFormValidity,
    );
  };

  renderAddedIngredients = () => {
    return this.state.ingredient_attributes.map(ingredient => {
      return (
        <div className="row" key={ingredient.index}>
          <div className="col">
            <Autocomplete
              options={this.state.options}
              getOptionLabel={option => option}
              value={ingredient.name}
              onChange={(event, newValue) => {
                if (newValue === null) {
                  newValue = '';
                }
                this.updateIngredientForm(ingredient.index, 'name', newValue);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Name"
                  variant="outlined"
                  style={{ width: 180 }}
                  required
                />
              )}
            />
          </div>
          <div className="col">
            <TextField
              label="Dosage"
              type="number"
              onChange={e => this.updateIngredientForm(ingredient.index, 'dosage', e.target.value)}
              value={ingredient.dosage}
              variant="outlined"
              required
            />
          </div>
          <div className="col">
            <TextField label="Unit" value={ingredient.unit} variant="outlined" disabled required />
          </div>
          <div className="col">
            <Button
              color="secondary"
              variant="contained"
              type="button"
              onClick={e => this.removeIngredient(ingredient)}
              style={{ padding: '18.5px 14px', fontSize: '1rem' }}
            >
              <i className="fa fa-trash"></i>
            </Button>
          </div>
        </div>
      );
    });
  };

  renderCreateManualOrderForm = () => {
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">CREATE MANUAL ORDER</DialogTitle>

        <div className="create-manual-order">
          <div style={{ paddingLeft: 22.5, paddingRight: 22.5 }}>
            <Button
              variant="contained"
              type="button"
              onClick={this.addIngredient}
              style={{ marginBottom: 10, marginRight: 7.5, marginLeft: 7.5 }}
            >
              Add Ingredient &nbsp; <i className="fa fa-plus"></i>
            </Button>
            {this.renderAddedIngredients()}
          </div>
        </div>

        <form onSubmit={e => this.submitHandler(e)} className="create-manual-order">
          <DialogContent>
            <div className="row">
              <div className="col">
                <TextField
                  name="first_name"
                  label="First Name"
                  onChange={e => this.setState({ first_name: e.target.value })}
                  value={this.state.first_name}
                  variant="outlined"
                  required
                />
              </div>
              <div className="col">
                <TextField
                  name="last_name"
                  label="Last Name"
                  onChange={e => this.setState({ last_name: e.target.value })}
                  value={this.state.last_name}
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  name="email"
                  label="Email"
                  id="email"
                  onChange={e => this.setState({ email: e.target.value })}
                  value={this.state.email}
                  variant="outlined"
                />
              </div>
              <div className="col">
                <TextField
                  name="phone"
                  label="Phone"
                  onChange={e => this.setState({ phone: e.target.value })}
                  value={this.state.phone}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  name="address"
                  label="Address"
                  onChange={e => this.setState({ address: e.target.value })}
                  value={this.state.address}
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  name="address2"
                  label="Address (cont'd)"
                  onChange={e => this.setState({ address2: e.target.value })}
                  value={this.state.address2}
                  variant="outlined"
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  name="order_link"
                  label="Order Link"
                  onChange={e => this.setState({ order_link: e.target.value })}
                  value={this.state.order_link}
                  variant="outlined"
                />
              </div>
              <div className="col">
                <TextField
                  label="Order Date"
                  type="date"
                  onChange={e => this.setState({ completed_at: e.target.value })}
                  value={this.state.completed_at}
                  name="completed_at"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <Autocomplete
                  options={this.state.availableCountries}
                  getOptionLabel={option => option}
                  value={this.state.country}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      this.setState({ country: newValue }, this.renderStateOptions);
                    } else {
                      this.setState({ country: '' }, this.renderStateOptions);
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Country" variant="outlined" required />
                  )}
                />
              </div>
              <div className="col">
                <Autocomplete
                  options={this.state.activeStates}
                  getOptionLabel={option => option}
                  value={this.state.state}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      this.setState({ state: newValue });
                    } else {
                      this.setState({ state: '' });
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} label="State" variant="outlined" required />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  name="city"
                  label="City"
                  onChange={e => this.setState({ city: e.target.value })}
                  value={this.state.city}
                  variant="outlined"
                  required
                />
              </div>
              <div className="col">
                <TextField
                  name="zip"
                  label="Zip"
                  onChange={e => this.setState({ zip: e.target.value })}
                  value={this.state.zip}
                  variant="outlined"
                  required
                />
              </div>
              <div className="col">
                <Autocomplete
                  options={this.state.availablePartners}
                  getOptionLabel={option => option}
                  value={this.state.partner}
                  onChange={(event, newValue) => {
                    if (newValue !== null) {
                      this.setState({ partner: newValue });
                    } else {
                      this.setState({ partner: '' });
                    }
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      style={{ width: '300px' }}
                      label="Partner"
                      variant="outlined"
                      required
                    />
                  )}
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.refill}
                      onChange={e => this.setState({ refill: e.target.checked })}
                      color="primary"
                    />
                  }
                  label="Refill"
                />
              </div>
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              type="button"
              onClick={e => this.submitHandler(e, false)}
            >
              Create & Add new Order
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Create
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  render() {
    return <div>{this.state.options.length > 0 ? this.renderCreateManualOrderForm() : null}</div>;
  }
}

const mapStateToProps = state => {
  return {
    error: state.orders.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateManualOrder);
