import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditOrder from './EditOrder';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function EditOrderModal(props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <EditOrder handleClose={handleClose} order={props.order} />
    </div>
  );

  return (
    <div>
      <Button
        type="button"
        variant="contained"
        disabled={
          props.current_user &&
          props.current_user.user &&
          (props.current_user.user.role === 'admin' || props.current_user.user.role === 'lab')
            ? false
            : true
        }
        onClick={handleOpen}
      >
        <EditIcon className={classes.extendedIcon} />
        Edit Information
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}
