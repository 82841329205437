import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';

import gSheetsIcon from '../media/google-sheets.png';
import folderIcon from '../media/open-file-folder-emoji.png';
import HeaderBar from './HeaderBar';
const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: 8,
  },
  root: {
    display: 'flex',
  },
  orderInfo: {
    display: 'flex',
  },
  coreInfo: {
    width: '65%',
    display: 'flex',
  },
  paymentInfo: {
    width: '35%',
  },
  keys: {
    width: '30%',
    backgroundColor: theme.palette.background.paper,
  },
  values: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  payment: {
    backgroundColor: 'blue',
  },
  paymentDetails: {
    '& div': {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
    },
  },
  clientInformation: {
    paddingTop: '2px !important',
    paddingBottom: '2px !important',
    paddingLeft: '2px !important',
    paddingRight: '2px !important',
  },
  completed: {
    color: 'green',
  },
  subTitle: {
    display: 'flex',
    justifyContent: 'center',
  },
  displayBlock: {
    display: 'block',
  },
}));

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 16,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function ClientInfo(props) {
  const { order } = props;
  let payments = [];
  if (order.spree_info !== null && !order.spree_info.message) {
    payments = order.spree_info.payments.sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at),
    );
  }
  const fullName = `${order.first_name} ${order.last_name}`;
  const title = `${order.partner.toUpperCase()} > ${fullName} > Platform Id: ${order.platform_id}`;
  const classes = useStyles();

  return (
    <div>
      <HeaderBar title={title} />
      <div className={classes.orderInfo}>
        <div className={classes.coreInfo}>
          <Table>
            <TableBody>
              {Object.entries(order.client_info)
                .filter(([key, value]) => value !== null)
                .map(([key, value]) => (
                  <StyledTableRow key={key}>
                    <StyledTableCell aling="left" className={classes.tableCell}>
                      <b>{key}:</b>
                    </StyledTableCell>
                    <StyledTableCell aling="left" className={classes.tableCell}>
                      {value}
                    </StyledTableCell>

                    <StyledTableCell className={classes.tableCell}></StyledTableCell>
                    <StyledTableCell className={classes.tableCell}></StyledTableCell>
                  </StyledTableRow>
                ))}
              {order.order_link ? (
                <StyledTableRow>
                  <StyledTableCell className={classes.tableCell}>
                    <b>Order V6: </b>
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    <a href={order.order_link} target="_blank" without="true" rel="noreferrer">
                      <img
                        className={classes.images}
                        src={gSheetsIcon}
                        alt="Order Sheet"
                        width="45"
                      />
                    </a>
                  </StyledTableCell>

                  <StyledTableCell className={classes.tableCell}>
                    <b>Folder URL:</b>
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    {order.folder_url ? (
                      <a href={order.folder_url} target="_blank" without="true" rel="noreferrer">
                        <img
                          className={classes.images}
                          src={folderIcon}
                          alt="Order Sheet"
                          width="45"
                        />
                      </a>
                    ) : (
                      'N/A'
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ) : null}
            </TableBody>
          </Table>
        </div>
        {order.spree_info !== null ? (
          <Card className={classes.paymentInfo}>
            {!order.spree_info.message ? (
              <div className={classes.paymentDetails}>
                <CardHeader title="Payment Status" component="h5" />
                <Typography align="center">
                  {`Next expected payment on: ${moment(order.spree_info.payments[0].created_at)
                    .add(1, 'M')
                    .format('MM/DD/YY')}`}
                </Typography>
                {order.spree_info.payments.map(payment => (
                  <div style={{ borderBottom: '1px solid #ddd' }} key={payment.id}>
                    <div>{payment.display_amount}</div>
                    <div>{moment(payment.created_at).format('MM/DD/YY')}</div>
                    <div>
                      <p
                        className={classes.paymentHeader}
                        style={
                          payment.state === 'completed' ? { color: 'green' } : { color: 'red' }
                        }
                      >
                        {payment.state.toUpperCase()}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <CardContent>{order.spree_info.message}</CardContent>
              </div>
            )}
          </Card>
        ) : (
          <div>No record of payments on VLHub, Wordpress Payments coming soon.</div>
        )}
      </div>
    </div>
  );
}
