import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './partners.module';
import { actions as milActions } from './../MIL/mil.module';
import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import { Button, TextField, Typography, MenuItem, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },

  form: {
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '32px',
  },

  textField: {
    marginTop: theme.spacing(3),
  },
  signInButton: {
    margin: theme.spacing(3, 0),
  },

  table: {
    width: '100%',
  },
});

class EditPartner extends React.Component {
  state = {
    id: null,
    name: '',
    refill_lead_time: 0,
    google_folder_id: '',
    spreadsheet_id: '',
    order_sheet_partner_data: '',
    master_sheet_id: '',
    master_sheet_name: '',
    partner_ingredients: [],
    base_orders: [],
    base_ingredients: [],
    mil: [],
    availableUnits: ['mg', 'billion', 'mcg', 'MCU', 'IU', 'g'],
    partner_ingredients_view: false,
    base_ingredients_view: false,
    base_orders_view: false,
    base_ingredients_order_id: null,
    main_view: true,
    successMessage: null,
    loading: false,
    open: false,
  };

  componentDidMount() {
    this.setState({ id: this.props.match.params.id }, () => {
      this.fetchPartner();
      this.fetchMIL();
    });
  }

  fetchMIL = () => {
    this.props.actions.loadMIL().then(() => {
      this.setState({ mil: this.props.mil });
    });
  };

  fetchPartner = () => {
    this.props.actions.loadPartner(this.state.id).then(() => {
      this.setState({
        name: this.props.partner.name,
        // refill_lead_time: this.props.partner.refill_lead_time,
        // google_folder_id: this.props.partner.google_folder_id,
        // spreadsheet_id: this.props.partner.spreadsheet_id,
        // order_sheet_partner_data: this.props.partner.order_sheet_partner_data,
        // master_sheet_id: this.props.partner.master_sheet_id,
        // master_sheet_name: this.props.partner.master_sheet_name,
        partner_ingredients: this.props.partner.partner_ingredients,
        base_orders: this.props.partner.base_orders,
        base_ingredients: this.props.partner.base_ingredients,
      });
    });
  };

  handleSubmit = async () => {
    await this.setState({ open: false });

    let partner_ingredients_attributes = [];
    this.state.partner_ingredients.forEach(ingredient => {
      if (this.props.partner.partner_ingredients.length >= ingredient.id) {
        partner_ingredients_attributes.push({
          id: ingredient.id,
          master_ingredient_list_id: ingredient.master_ingredient_list_id,
          ingredient_name: ingredient.ingredient_name,
          unit: ingredient.unit,
          unit_conversion: ingredient.unit_conversion,
        });
      } else {
        partner_ingredients_attributes.push({
          master_ingredient_list_id: ingredient.master_ingredient_list_id,
          ingredient_name: ingredient.ingredient_name,
          unit: ingredient.unit,
          unit_conversion: ingredient.unit_conversion,
        });
      }
    });

    let base_orders_attributes = [];
    this.state.base_orders.forEach(order => {
      let base_ingredients_attributes = [];
      this.state.base_ingredients.forEach(ingredient => {
        if (ingredient.base_order_id === order.id) {
          if (order.base_ingredients.length <= ingredient.id) {
            base_ingredients_attributes.push({
              name: ingredient.name,
              dosage: ingredient.dosage,
              unit: ingredient.unit,
              weight_with_retention: ingredient.weight_with_retention,
            });
          } else {
            base_ingredients_attributes.push({
              id: ingredient.id,
              name: ingredient.name,
              dosage: ingredient.dosage,
              unit: ingredient.unit,
              weight_with_retention: ingredient.weight_with_retention,
            });
          }
        }
      });

      base_orders_attributes.push({
        id: order.id,
        name: order.name,
        base_version: order.base_version,
        base_ingredients_attributes: base_ingredients_attributes,
      });
    });

    let data = {
      name: this.state.name,
      active: true,
      partner_ingredients_attributes: partner_ingredients_attributes,
      base_orders_attributes: base_orders_attributes,
      refill_lead_time: this.state.refill_lead_time,
      google_folder_id: this.state.google_folder_id,
      spreadsheet_id: this.state.spreadsheet_id,
      order_sheet_partner_data: this.state.order_sheet_partner_data,
      master_sheet_id: this.state.master_sheet_id,
      master_sheet_name: this.state.master_sheet_name,
    };

    await this.setState({ loading: true });
    await this.props.actions.updatePartner(this.state.id, data);
    if (!this.props.error) {
      await this.setState({
        successMessage: 'Partner updated successfully!',
        loading: false,
      });
      setTimeout(() => this.setState({ successMessage: null }), 5000);
    }
  };

  handleInputChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  handleSelectionChangeInPartnerIngredients = (event, id) => {
    let partner_ingredients = [];
    this.state.partner_ingredients.forEach(ingredient => {
      if (ingredient.id === id) {
        let index = this.state.mil.findIndex(mi => mi.id === event.target.value);
        if (index > -1) {
          let mi = this.state.mil[index];
          ingredient.ingredient_name = mi.ingredient_name;
          ingredient.item_code = mi.item_code;
          ingredient.master_ingredient_list_id = mi.id;
          ingredient.master_ingredient_list = mi;
          ingredient.unit = mi.unit;
        }
      }

      partner_ingredients.push(ingredient);
    });

    this.setState({ partner_ingredients: partner_ingredients });
  };

  handleInputChangeInPartnerIngredients = (event, id) => {
    let partner_ingredients = [];
    this.state.partner_ingredients.forEach(ingredient => {
      if (ingredient.id === id) {
        ingredient[event.target.name] = event.target.value;
      }
      partner_ingredients.push(ingredient);
    });

    this.setState({ partner_ingredients: partner_ingredients });
  };

  renderMILSelection = (p_id, id) => {
    return (
      <TextField
        select
        value={id}
        onChange={e => this.handleSelectionChangeInPartnerIngredients(e, p_id)}
        variant="outlined"
      >
        {this.state.mil.map(option => (
          <MenuItem key={option.id} value={option.id}>
            {option.ingredient_name}
          </MenuItem>
        ))}
      </TextField>
    );
  };

  handleInputChangeInBaseOrders = (event, id) => {
    let base_orders = [];
    this.state.base_orders.forEach(order => {
      if (order.id === id) {
        order[event.target.name] = event.target.value;
      }
      base_orders.push(order);
    });
    this.setState({ base_orders: base_orders });
  };

  handleInputChangeInBaseIngredients = (event, id) => {
    let base_ingredients = [];
    this.state.base_ingredients.forEach(ingredient => {
      if (ingredient.id === id) {
        ingredient[event.target.name] = event.target.value;
      }
      base_ingredients.push(ingredient);
    });
    this.setState({ base_ingredients: base_ingredients });
  };

  // deletePartnerIngredient = id => {
  //   let partner_ingredients = [];
  //   this.state.partner_ingredients.forEach(ingredient => {
  //     if(ingredient.id !== id){
  //       partner_ingredients.push(ingredient);
  //     }
  //   })
  //   this.setState({partner_ingredients: partner_ingredients});
  // }

  addBaseIngredient = () => {
    let base_ingredients_length = 0;
    this.state.base_ingredients.forEach(ingredient => {
      if (ingredient.base_order_id === this.state.base_ingredients_order_id) {
        base_ingredients_length += 1;
      }
    });
    let base_ingredients = [];
    base_ingredients.push({
      id: base_ingredients_length + 1,
      name: '',
      dosage: 0,
      unit: '',
      weight_with_retention: '',
      base_order_id: this.state.base_ingredients_order_id,
      unit_code: '',
    });
    base_ingredients = [...base_ingredients, ...this.state.base_ingredients];
    this.setState({ base_ingredients: base_ingredients });
  };

  addPartnerIngredient = () => {
    let partner_ingredients = [];
    partner_ingredients.push({
      id: this.state.partner_ingredients.length + 1,
      ingredient_name: '',
      unit: '',
      unit_conversion: 0,
      master_ingredient_list_id: 0,
      master_ingredient_list: {},
      partner_id: this.state.id,
    });
    partner_ingredients = [...partner_ingredients, ...this.state.partner_ingredients];
    this.setState({ partner_ingredients: partner_ingredients });
  };

  addBaseOrder = async () => {
    let base_orders = [];

    let data = {
      name: '',
      base_version: 1,
      partner_id: this.state.id,
      base_ingredients_attributes: [],
    };

    await this.props.actions.createBaseOrder(data);

    if (!this.props.error) {
      base_orders.push({
        id: this.props.base_order.id,
        name: this.props.base_order.name,
        base_version: this.props.base_order.base_version,
        partner_id: this.props.base_order.partner_id,
      });

      base_orders = [...base_orders, ...this.state.base_orders];
      this.setState({ base_orders: base_orders });
    }
  };

  renderBaseOrders = () => {
    return this.state.base_orders.map(row => {
      return (
        <TableRow key={row.id}>
          <TableCell>
            <TextField
              type="text"
              name="name"
              value={row.name}
              onChange={e => this.handleInputChangeInBaseOrders(e, row.id)}
              variant="outlined"
              required
            />
          </TableCell>
          <TableCell>
            <TextField
              type="number"
              name="base_version"
              value={row.base_version}
              onChange={e => this.handleInputChangeInBaseOrders(e, row.id)}
              variant="outlined"
              required
            />
          </TableCell>
          <TableCell>
            <Button
              size="small"
              type="button"
              variant="contained"
              onClick={() =>
                this.setState({
                  base_ingredients_view: true,
                  base_orders_view: false,
                  base_ingredients_order_id: row.id,
                })
              }
            >
              View
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  };

  renderBaseIngredients = () => {
    return this.state.base_ingredients.map(row => {
      if (row.base_order_id === this.state.base_ingredients_order_id) {
        return (
          <TableRow key={row.id}>
            <TableCell>
              <TextField
                type="text"
                name="name"
                value={row.name}
                onChange={e => this.handleInputChangeInBaseIngredients(e, row.id)}
                variant="outlined"
                required
              />
            </TableCell>
            <TableCell>
              <TextField
                type="number"
                name="dosage"
                value={row.dosage}
                onChange={e => this.handleInputChangeInBaseIngredients(e, row.id)}
                variant="outlined"
                required
              />
            </TableCell>
            <TableCell>
              <TextField
                select
                value={row.unit}
                name="unit"
                onChange={e => this.handleInputChangeInBaseIngredients(e, row.id)}
                variant="outlined"
              >
                {this.state.availableUnits.map(unit => (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </TextField>
            </TableCell>
            <TableCell>
              <TextField
                type="text"
                name="weight_with_retention"
                value={row.weight_with_retention ? row.weight_with_retention : ''}
                onChange={e => this.handleInputChangeInBaseIngredients(e, row.id)}
                variant="outlined"
              />
            </TableCell>
            <TableCell>
              <TextField
                type="text"
                name="unit_code"
                value={row.unit_code ? row.unit_code : ''}
                onChange={e => this.handleInputChangeInBaseIngredients(e, row.id)}
                variant="outlined"
              />
            </TableCell>
          </TableRow>
        );
      }
      return null;
    });
  };

  renderPartnerIngredients = () => {
    return this.state.partner_ingredients.map(row => {
      return (
        <TableRow key={row.id}>
          <TableCell>
            <TextField
              type="text"
              name="ingredient_name"
              value={row.ingredient_name}
              onChange={e => this.handleInputChangeInPartnerIngredients(e, row.id)}
              variant="outlined"
            />
          </TableCell>

          <TableCell>
            <TextField
              select
              value={row.unit}
              name="unit"
              onChange={e => this.handleInputChangeInPartnerIngredients(e, row.id)}
              variant="outlined"
            >
              {this.state.availableUnits.map(unit => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </TextField>
          </TableCell>

          <TableCell>
            <TextField
              type="number"
              name="unit_conversion"
              value={row.unit_conversion}
              onChange={e => this.handleInputChangeInPartnerIngredients(e, row.id)}
              variant="outlined"
              required
            />
          </TableCell>

          <TableCell>{this.renderMILSelection(row.id, row.master_ingredient_list_id)}</TableCell>
          {/* <TableCell>
            <TextField
              type="text"
              name="item_code"
              value={row.item_code}
              onChange={e => this.handleInputChangeInPartnerIngredients(e, row.id)}
              variant="outlined"
            />
          </TableCell> */}
          {/* <TableCell>
              <Button
                color="secondary"
                size="small"
                type="button"
                variant="contained"
                onClick={() => this.deletePartnerIngredient(row.id)}
              >
                Delete
              </Button>
          </TableCell> */}
        </TableRow>
      );
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, error, loading } = this.props;
    const {
      name,
      refill_lead_time,
      google_folder_id,
      spreadsheet_id,
      order_sheet_partner_data,
      master_sheet_id,
      master_sheet_name,
      successMessage,
    } = this.state;

    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );

    return (
      <form className={classes.form}>
        <Dialog open={this.state.open} onClose={this.handleClose}>
          <DialogTitle>{'Are you sure, you want to continue updating?'}</DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary" type="button" variant="contained">
              Cancel
            </Button>
            <Button
              color="primary"
              size="medium"
              variant="contained"
              type="button"
              onClick={this.handleSubmit}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>

        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '5px',
              marginTop: '5px',
              padding: '5px',
            }}
          >
            {this.state.main_view || this.state.base_ingredients_view ? (
              <>
                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      partner_ingredients_view: true,
                      main_view: false,
                      base_orders_view: false,
                      base_ingredients_view: false,
                    })
                  }
                >
                  Edit Partner Ingredients
                </Button>

                <Button
                  color="primary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      base_orders_view: true,
                      main_view: false,
                      partner_ingredients_view: false,
                      base_ingredients_view: false,
                    })
                  }
                >
                  Edit Base Orders
                </Button>
              </>
            ) : (
              <>
                <Button
                  color="secondary"
                  size="medium"
                  type="button"
                  variant="contained"
                  onClick={() =>
                    this.setState({
                      partner_ingredients_view: false,
                      base_ingredients_view: false,
                      base_orders_view: false,
                      main_view: true,
                    })
                  }
                >
                  Back
                </Button>
              </>
            )}

            <Button
              color="primary"
              size="medium"
              type="button"
              variant="contained"
              onClick={() => this.setState({ open: true })}
            >
              Update{' '}
              {this.state.loading ? (
                <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          </div>
        </Paper>

        {error ? <Alert severity="error"> Error! {error} </Alert> : null}
        {successMessage ? <Alert severity="success"> {successMessage} </Alert> : null}

        {this.state.main_view ? (
          <Paper style={{ padding: 15 }}>
            <Typography className={classes.title} variant="h2">
              Edit Partner
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  type="text"
                  name="name"
                  label="Name"
                  value={name}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.textField}
                  fullWidth
                  type="text"
                  name="google_folder_id"
                  label="Google Folder Id"
                  value={google_folder_id}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.textField}
                  fullWidth
                  type="number"
                  name="refill_lead_time"
                  label="Refill Lead Time"
                  value={refill_lead_time}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.textField}
                  fullWidth
                  name="spreadsheet_id"
                  label="Spreadsheet Id"
                  value={spreadsheet_id}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  className={classes.textField}
                  fullWidth
                  type="text"
                  name="master_sheet_id"
                  label="Master Sheet ID"
                  value={master_sheet_id}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />

                <TextField
                  className={classes.textField}
                  fullWidth
                  name="master_sheet_name"
                  label="Master Sheet Name"
                  value={master_sheet_name}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  name="order_sheet_partner_data"
                  label="Order Sheet Partner Data"
                  value={order_sheet_partner_data}
                  onChange={this.handleInputChange}
                  variant="outlined"
                  required
                />
              </Grid>
            </Grid>
          </Paper>
        ) : null}

        {this.state.partner_ingredients_view ? (
          <Paper style={{ padding: 15 }}>
            <Button
              onClick={this.addPartnerIngredient}
              variant="contained"
              color="primary"
              type="button"
              style={{ float: 'right' }}
            >
              Add
            </Button>

            <Typography className={classes.title} variant="h2">
              Partner Ingredients
            </Typography>

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Ingredient Name</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Unit Conversion</TableCell>
                  <TableCell>Master Ingredient</TableCell>
                  {/* <TableCell>Item Code</TableCell> */}
                  {/* <TableCell></TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>{this.renderPartnerIngredients()}</TableBody>
            </Table>
          </Paper>
        ) : null}

        {this.state.base_orders_view ? (
          <Paper style={{ padding: 15 }}>
            <Button
              onClick={this.addBaseOrder}
              variant="contained"
              color="primary"
              type="button"
              style={{ float: 'right' }}
            >
              Add
            </Button>

            <Typography className={classes.title} variant="h2">
              Base Orders
            </Typography>

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Base Version</TableCell>
                  <TableCell>Base Ingredients</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderBaseOrders()}</TableBody>
            </Table>
          </Paper>
        ) : null}

        {this.state.base_ingredients_view ? (
          <Paper style={{ padding: 15 }}>
            <Button
              onClick={this.addBaseIngredient}
              variant="contained"
              color="primary"
              type="button"
              style={{ float: 'right' }}
            >
              Add
            </Button>

            <Typography className={classes.title} variant="h2">
              Base Ingredients
            </Typography>

            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Dosage</TableCell>
                  <TableCell>Unit</TableCell>
                  <TableCell>Weight With Retention</TableCell>
                  <TableCell>Unit Code</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{this.renderBaseIngredients()}</TableBody>
            </Table>
          </Paper>
        ) : null}
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    partner: state.partners.partner,
    loading: state.partners.loading,
    error: state.partners.error,
    mil: state.mil.mil,
    base_order: state.partners.base_order,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Object.assign({}, actions, milActions), dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditPartner));
