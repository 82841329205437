import vali from '../../middleware/vali';

const userValidationSchema = {
  email: {
    expression: vali.email().required(),
    message: 'Please enter a valid email address.',
  },
  firstName: {
    expression: vali.name().required(),
    message: 'First name cannot contain numbers or symbols.',
  },
  lastName: {
    expression: vali.name().required(),
    message: 'Last name cannot contain numbers or symbols.',
  },
  role: {
    expression: vali.string().required(),
    message: 'Role is required.',
  },
  password: {
    expression: vali.password().required(),
    message:
      'Make sure your password has one of three: upper case, lower case, numbers or symbols.',
  },
  passwordConfirmation: {
    expression: vali.confirmPassword().required(),
    message: 'Passwords do not match.',
  },
  companyName: {
    expression: vali.string().required(),
    message: 'Company name is required.',
  },
  companyEmail: {
    expression: vali.email().required(),
    message: 'Please enter a valid company email address.',
  },
  jobTitle: {
    expression: vali.string().required(),
    message: 'Job title is required.',
  },
  companyCity: {
    expression: vali.string(),
    message: 'Please enter a valid company city.',
  },
  companyAddress: {
    expression: vali.string(),
    message: 'Please enter a valid company address.',
  },
  companyCountry: {
    expression: vali.string(),
    message: 'Please enter a valid company country.',
  },
  companyPhone: {
    expression: vali.string().required(),
    message: 'Company phone is required.',
  },
  companyIndustry: {
    expression: vali.string().required(),
    message: 'Please enter a valid company industry.',
  },
};

export default userValidationSchema;
