import React, { Component } from 'react';
// Styled Components
import { withStyles } from '@material-ui/core/styles';

import { Paper, Card, CardContent, CardHeader, CircularProgress } from '@material-ui/core';

import { DashboardTable } from './components/index';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { HeaderBar } from '../Orders/components/index';
import { actions } from './dashboard.module';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  hide: {
    display: 'none',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  navBar: {
    textDecoration: 'none',
    color: '#808080',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  totalPartnerTicker: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    padding: '15px',
  },
  totalPartnerCard: {
    width: '19%',
    marginBottom: 10,
    background: '#eee',
  },
  partnerNames: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  partnerTotals: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '28px',
    fontWeight: 'bold',
    padding: '16px !important',
  },
});

class Home extends Component {
  componentDidMount = () => {
    this.props.actions.loadDashboardEntries();
  };
  render() {
    const { user, dashboardEntries, loading, classes } = this.props;
    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    if (!dashboardEntries.total_partner_orders)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    return (
      <div>
        <h1>
          Welcome, {user ? user.first_name : null} {user ? user.last_name : null}
        </h1>
        <h1>Total Orders: {dashboardEntries.total_orders}</h1>
        <Paper style={{ marginBottom: '15px' }}>
          <HeaderBar title="Orders by Partner" />
          <div className={classes.totalPartnerTicker}>
            {Object.entries(dashboardEntries.total_partner_orders).map(([key, value]) => (
              <Card key={key} className={classes.totalPartnerCard}>
                <CardHeader className={classes.partnerNames} title={key} />
                <CardContent className={classes.partnerTotals}>{value}</CardContent>
              </Card>
            ))}
          </div>
        </Paper>
        <Paper style={{ marginBottom: '15px' }}>
          <HeaderBar title="Today's Orders" />
          <Card>
            {dashboardEntries.new_orders_today.length !== 0 ? (
              <DashboardTable orders={dashboardEntries.new_orders_today} />
            ) : (
              <div>No New Orders Today</div>
            )}
          </Card>
        </Paper>
        <Paper>
          <HeaderBar title="Upcoming Genopalate Refills" />
          <Card>
            {dashboardEntries.upcoming_gp_refills.length !== 0 ? (
              <DashboardTable orders={dashboardEntries.upcoming_gp_refills} />
            ) : (
              <div>No Refills</div>
            )}
          </Card>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    dashboardEntries: state.dashboard.dashboardEntries,
    loading: state.orders.loading,
    error: state.orders.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles, { withTheme: true })(
  connect(mapStateToProps, mapDispatchToProps)(Home),
);
