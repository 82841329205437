import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../styles/orderCommentStyles';
import { actions } from './orderComments.module';
import OrderCommentsForm from '../components/OrderCommentForm';
import CommentsCard from '../components/CommentsCard';
class OrderComments extends Component {
  handleSubmit = async values => {
    try {
      const { orderId } = this.props;
      let payload = {
        id: orderId,
        comment: values,
      };
      await this.props.actions.createOrderComment(payload);
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount = async _ => {
    const { orderId } = await this.props;
    this.props.actions.loadOrderComments(orderId);
  };

  render() {
    const { orderComments, loading, error } = this.props;
    if (error) return <div>Error! {error.message}</div>;
    if (loading) return <div>Loading..</div>;
    if (!orderComments) return <div>Loading..</div>;
    return (
      <div>
        <OrderCommentsForm onSubmit={this.handleSubmit} />
        {orderComments.length !== 0 ? (
          orderComments.map(comment => <CommentsCard key={comment.id} comment={comment} />)
        ) : (
          <div style={{ marginTop: 5, marginBottom: 5, fontSize: 16 }}>
            No comments about this order.
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orderComments: state.orderComments.orderComments,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OrderComments));
