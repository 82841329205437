import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../orders.module';
import OrderForm from '../components/OrderForm';

class EditOrder extends Component {
  state = {
    partner: '',
  };

  componentDidMount() {
    this.setState({ partner: this.props.order.partner });
  }

  handleSubmit = async values => {
    try {
      const { order } = this.props;
      values['partner_name'] = this.state.partner;
      await this.props.actions.updateOrder(order.id, values);
      this.props.handleClose();
    } catch (e) {
      console.log(e);
    }
  };

  handlePartnerChange = partner => {
    this.setState({ partner: partner });
    console.log(this.state);
  };

  render() {
    const { order, loading, error, handleClose } = this.props;
    if (error) return <div>Error! {error.message}</div>;
    if (loading) return <div>Loading..</div>;
    return (
      <OrderForm
        order={order}
        initialValues={order.client_info_form}
        partner={order.partner}
        partnersList={order.partners}
        handleClose={handleClose}
        handlePartnerChange={this.handlePartnerChange}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(EditOrder);
