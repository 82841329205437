import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './usersmanagement.module';

import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import vali from '../../middleware/vali';
import userValidationSchema from '../../lib/validationSchemas/userValidationSchema';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '40px',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
});

class EditUser extends React.Component {
  state = {
    id: null,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    department: '',
    role: '',
    availableDepartments: ['Developer', 'Lab', 'HR', 'Manager', 'Order Processing'],
    availableRoles: [],
    errors: {},
    loading: false,
    passwordLoading: false,
  };

  componentDidMount() {
    this.setState({ id: this.props.match.params.id }, this.fetchUser);
  }

  fetchUser = () => {
    this.props.actions.loadUser(this.state.id).then(() => {
      this.setState({
        email: this.props.user.email,
        firstName: this.props.user.first_name,
        lastName: this.props.user.last_name,
        department: this.props.user.department,
        role: this.props.user.role,
        availableRoles: this.props.user.allowed_roles,
      });
    });
  };

  handleSubmit(event, type) {
    event.preventDefault();

    const {
      firstName,
      lastName,
      email,
      department,
      role,
      password,
      passwordConfirmation,
      errors,
    } = this.state;

    if (type === 'profile') {
      let error = vali.validate(
        {
          firstName,
          lastName,
          email,
        },
        userValidationSchema,
      );
      if (error) {
        errors[error.field] = error.message;
        this.setState({ errors });
        return;
      }

      this.setState({ loading: true }, () => {
        const payload = {
          first_name: firstName,
          email: email,
          last_name: lastName,
          department: department,
          role: role,
        };
        this.props.actions.updateUser(this.state.id, payload).then(() => {
          this.setState({ loading: false }, () => {
            if (!this.props.error) {
              this.props.history.push('/teams');
            }
          });
        });
      });
    } else {
      let error = vali.validate(
        {
          password,
          passwordConfirmation,
        },
        userValidationSchema,
      );

      if (error) {
        errors[error.field] = error.message;
        this.setState({ errors });
        return;
      }

      this.setState({ passwordLoading: true }, () => {
        const payload = {
          password: password,
          password_confirmation: passwordConfirmation,
        };
        this.props.actions.updateUser(this.state.id, payload).then(() => {
          this.setState({ passwordLoading: false }, () => {
            if (!this.props.error) {
              this.props.history.push('/teams');
            }
          });
        });
      });
    }
  }

  handleInputChange = event => {
    const { value, name } = event.target;
    const { errors } = this.state;

    errors[name] = null;
    errors.page = null;

    this.setState({ [name]: value, errors });
  };

  render() {
    if (this.props.current_user && this.props.current_user.user) {
      if (this.props.current_user.user.role === 'admin') {
        const { classes, error } = this.props;
        const {
          firstName,
          lastName,
          email,
          password,
          passwordConfirmation,
          department,
          role,
          availableDepartments,
          availableRoles,
          errors,
        } = this.state;

        return (
          <Grid container spacing={3}>
            <Grid item md={12}>
              {error ? <Alert severity="error"> Error! {error} </Alert> : null}
            </Grid>
            <Grid item md={6}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={event => this.handleSubmit(event, 'profile')}
                >
                  <Typography className={classes.title} variant="h2">
                    Edit Profile
                  </Typography>

                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="firstName"
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    value={firstName}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="lastName"
                    label="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    value={lastName}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email}
                    value={email}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    select
                    name="department"
                    label="Select Department"
                    value={department}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  >
                    {availableDepartments.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    className={classes.textField}
                    fullWidth
                    select
                    name="role"
                    label="Select Role"
                    value={role}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  >
                    {availableRoles.map(option => (
                      <MenuItem key={option.replace(',', '')} value={option.replace(',', '')}>
                        {option.replace(',', '')}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Edit Profile{' '}
                    {this.state.loading ? (
                      <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
                    ) : null}
                  </Button>
                </form>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={classes.contentBody}>
                <form
                  className={classes.form}
                  onSubmit={event => this.handleSubmit(event, 'password')}
                >
                  <Typography className={classes.title} variant="h2">
                    Edit Password
                  </Typography>

                  <TextField
                    className={classes.textField}
                    fullWidth
                    type="password"
                    name="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password}
                    value={password}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    type="password"
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation}
                    value={passwordConfirmation}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Edit Password{' '}
                    {this.state.passwordLoading ? (
                      <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
                    ) : null}
                  </Button>
                </form>
              </div>
            </Grid>
          </Grid>
        );
      }
    }
    return (
      <div>
        <h1 style={{ fontSize: 48, fontWeight: 'bold', marginBottom: 0 }}>405 Not Allowed</h1>
        <p style={{ fontSize: 24, marginTop: 0 }}>You are not allowed to view this page.</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.users.user,
    loading: state.users.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditUser));
