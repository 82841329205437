import React, { Component } from 'react';
import { Route, Link, withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../views/Users/users.module';

import './index.css';
// No Auth Components
// Authenticated Components
import AccountsIndex from '../views/Accounts/index';
import accountsShow from '../views/Accounts/show';
import accountsCreate from '../views/Accounts/create';
import Dashboard from '../views/Dashboard/index';
import Partners from '../views/Partners/index';
import PartnersEdit from '../views/Partners/edit';
import CreatePartner from '../views/Partners/create';
import OrdersIndex from '../views/Orders/index';
import OrdersShow from '../views/Orders/show';
import ordersCreate from '../views/Orders/create';
import Users from '../views/UsersManagement/index';
import CreateUser from '../views/UsersManagement/create';
import EditUser from '../views/UsersManagement/edit';
import Profile from '../views/Profile/index';
import Security from '../views/Profile/security';
import Lab from '../views/Lab/index';
import Shipping from '../views/Shipping/index';
import MIL from '../views/MIL/index';
import Ingredients from '../views/Ingredients/index';
import CreateMIL from '../views/MIL/create';

//Style Components
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewListIcon from '@material-ui/icons/ViewList';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill';
import Box from '@material-ui/core/Box';
import logo from '../mediaAssets/logo.png';
import { Typography } from '@material-ui/core';
const drawerWidth = 300;

const applicationRouter = props => {
  const { user } = props;
  // if (user.status === 'signin') return <div>Loading...</div>;
  return (
    <div>
      <main>
        <Route exact path="/" render={props => <Dashboard {...props} user={user.user} />} />
        <Route exact path="/accounts" component={AccountsIndex} />
        <Route exact path="/accounts/create" component={accountsCreate} />
        <Route exact path="/accounts/:id" component={accountsShow} />

        <Route
          exact
          path="/orders"
          render={props => <OrdersIndex {...props} current_user={user} />}
        />
        <Route exact path="/orders/create" component={ordersCreate} />
        <Route
          exact
          path="/orders/:id"
          render={props => <OrdersShow {...props} current_user={user} />}
        />
        <Route exact path="/teams" render={props => <Users {...props} current_user={user} />} />
        <Route
          exact
          path="/teams/create"
          render={props => <CreateUser {...props} current_user={user} />}
        />
        <Route
          exact
          path="/teams/edit/:id"
          render={props => <EditUser {...props} current_user={user} />}
        />
        <Route exact path="/profile" render={props => <Profile {...props} />} />
        <Route exact path="/security" render={props => <Security {...props} />} />
        <Route exact path="/lab" render={props => <Lab {...props} />} />
        <Route exact path="/shipping" render={props => <Shipping {...props} />} />
        <Route exact path="/mil" render={props => <MIL {...props} current_user={user} />} />
        <Route exact path="/mil/create" render={props => <CreateMIL {...props} />} />
        <Route
          exact
          path="/partners"
          render={props => <Partners {...props} current_user={user} />}
        />
        <Route exact path="/partners/create" render={props => <CreatePartner {...props} />} />
        <Route exact path="/partners/edit/:id" render={props => <PartnersEdit {...props} />} />

        <Route
          exact
          path="/ingredients"
          render={props => <Ingredients {...props} current_user={user} />}
        />
        {/* <Route exact path="/partners/:id" component={partnersShow} /> */}
        {/* <Route exact path="/mil/edit/:id" render={props => <EditMIL {...props} />} /> */}
      </main>
    </div>
  );
};

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 8,
    marginTop: 8,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  navBar: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#808080',
  },
  logo: {
    maxHeight: 50,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

class AuthRouter extends Component {
  state = {
    user: null,
    open: true,
  };

  componentDidMount() {
    this.setState({ user: this.props.user }, this.changePage);
  }

  componentDidUpdate() {
    if (this.props.user !== this.state.user) {
      this.setState({ user: this.props.user }, this.changePage);
    }
  }

  changePage = () => {
    if (this.state.user.status !== 'authenticated') {
      this.props.history.push('/login');
    }
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  handleLogout = () => {
    this.props.actions.logoutUser().then(() => {
      this.props.history.push('/login');
    });
  };

  render() {
    const { open } = this.state;
    const { classes, theme } = this.props;
    const { user } = this.props;
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          color="inherit"
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: this.state.open,
          })}
        >
          <Toolbar disableGutters={!this.state.open}>
            <Box
              width="100%"
              className="box"
              display="flex"
              justifyContent={open ? 'flex-end' : 'space-between'}
            >
              <Box
                className={classNames({
                  [classes.hide]: this.state.open,
                })}
                alignItems="center"
              >
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={this.handleDrawerOpen}
                  className={classNames(classes.menuButton, {
                    [classes.hide]: this.state.open,
                  })}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box display="flex" alignItems="center">
                <Link to="/profile">
                  <IconButton style={{ borderRadius: 0 }}>
                    <Typography style={{ paddingLeft: 10 }}>
                      <span style={{ marginRight: 10 }}>Profile</span>
                    </Typography>
                    <AccountCircleIcon />
                  </IconButton>
                </Link>
                <IconButton style={{ borderRadius: 0 }} onClick={this.handleLogout}>
                  <Typography style={{ paddingLeft: 10 }}>
                    <span style={{ marginRight: 10 }}>Log Out</span>
                  </Typography>
                  <ExitToAppIcon />
                </IconButton>
              </Box>
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbar}>
            <img className={classes.logo} src={logo} alt="VitaminLab" />
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
            <NavLink exact className={classes.navBar} activeClassName="is-active" to="/">
              <ListItem button key={'dashboard'}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={'DASHBOARD'} />
              </ListItem>
            </NavLink>
            <NavLink exact className={classes.navBar} activeClassName="is-active" to="/orders">
              <ListItem button key={'orders'}>
                <ListItemIcon>
                  <ViewListIcon />
                </ListItemIcon>
                <ListItemText primary={'ORDER QUEUE'} />
              </ListItem>
            </NavLink>
            <NavLink exact className={classes.navBar} activeClassName="is-active" to="/lab">
              <ListItem button key={'lab'}>
                <ListItemIcon>
                  <LocalHospitalIcon />
                </ListItemIcon>
                <ListItemText primary={'LAB'} />
              </ListItem>
            </NavLink>
            <NavLink exact className={classes.navBar} activeClassName="is-active" to="/shipping">
              <ListItem button key={'privacy'}>
                <ListItemIcon>
                  <LocalShippingIcon />
                </ListItemIcon>
                <ListItemText primary={'SHIPPING'} />
              </ListItem>
            </NavLink>
            {user && user.user && user.user.role === 'admin' ? (
              <NavLink exact className={classes.navBar} to="/teams" activeClassName="is-active">
                <ListItem button key={'teams'}>
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary={'TEAM MANAGEMENT'} />
                </ListItem>
              </NavLink>
            ) : null}
            <NavLink exact className={classes.navBar} to="/mil" activeClassName="is-active">
              <ListItem button key={'mil'}>
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary={'MIL'} />
              </ListItem>
            </NavLink>
            <NavLink exact className={classes.navBar} to="/partners" activeClassName="is-active">
              <ListItem button key={'partners'}>
                <ListItemIcon>
                  <PeopleAltIcon />
                </ListItemIcon>
                <ListItemText primary={'PARTNERS'} />
              </ListItem>
            </NavLink>

            <NavLink exact className={classes.navBar} to="/ingredients" activeClassName="is-active">
              <ListItem button key={'ingredients'}>
                <ListItemIcon>
                  <FormatColorFillIcon />
                </ListItemIcon>
                <ListItemText primary={'DISPENSED INGREDIENTS'} />
              </ListItem>
            </NavLink>

            <NavLink exact className={classes.navBar} activeClassName="is-active" to="/security">
              <ListItem button key={'privacy'}>
                <ListItemIcon>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primary={'PRIVACY/SECURITY'} />
              </ListItem>
            </NavLink>
          </List>
        </Drawer>

        <main className={classes.content}>
          <div className={classes.toolbar} />
          <div>{applicationRouter(this.props)}</div>
        </main>
      </div>
    );
  }
}

AuthRouter.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withStyles(styles, { withTheme: true })(
  connect(null, mapDispatchToProps)(withRouter(AuthRouter)),
);
