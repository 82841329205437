import { getWithExpiry } from '../apiModules/getWithTtl';
import { ACCESS_TOKEN } from '../api-config';
export function getToken() {
  if (getWithExpiry(ACCESS_TOKEN) === 'null') {
    return localStorage.clear();
  }
  return getWithExpiry(ACCESS_TOKEN);
}
export function isLoggedIn() {
  const token = getToken();

  return token;
}

export function requireAuth(replace) {
  if (!isLoggedIn()) {
    replace({ pathname: '/' });
  }
}
