import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './mil.module';

import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import { Button, TextField, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '40px',
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
});

class CreateMIL extends React.Component {
  state = {
    label_name: '',
    ingredient_name: '',
    percent_active: 0,
    item_code: '',
    unit: '',
    rdv: 0,
    density_conversion_factor: '',
    packed_density: 0,
    product_lot_number: '',
    overage_less_twelve: 0,
    overage_more_twelve: 0,
    availableUnits: ['mg', 'billion', 'mcg', 'MCU', 'IU', 'g'],
    successMessage: null,
  };

  handleSubmit = event => {
    event.preventDefault();
    let data = {
      label_name: this.state.label_name,
      ingredient_name: this.state.ingredient_name,
      percent_active: this.state.percent_active,
      item_code: this.state.item_code,
      unit: this.state.unit,
      rdv: this.state.rdv,
      density_conversion_factor: this.state.density_conversion_factor,
      packed_density: this.state.packed_density,
      product_lot_number: this.state.product_lot_number,
      overage_less_twelve: this.state.overage_less_twelve,
      overage_more_twelve: this.state.overage_more_twelve,
    };

    this.props.actions.createMI(data).then(() => {
      if (!this.props.error) {
        this.setState({ successMessage: 'Master Ingredient Created Successfully' });
        setTimeout(
          () =>
            this.setState({ successMessage: null }, () => {
              this.props.history.push('/mil');
            }),
          5000,
        );
      }
    });
  };

  handleInputChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, error } = this.props;
    const {
      label_name,
      ingredient_name,
      percent_active,
      item_code,
      unit,
      rdv,
      density_conversion_factor,
      packed_density,
      product_lot_number,
      overage_less_twelve,
      overage_more_twelve,
      availableUnits,
      successMessage,
    } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        <Typography className={classes.title} variant="h2">
          Add MIL
        </Typography>

        {error ? <Alert severity="error"> Error! {error} </Alert> : null}
        {successMessage ? <Alert severity="success"> {successMessage} </Alert> : null}

        <Grid container spacing={10}>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              fullWidth
              type="text"
              name="label_name"
              label="Label Name"
              value={label_name}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="text"
              name="ingredient_name"
              label="Ingredient Name"
              value={ingredient_name}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="percent_active"
              label="Percent Active"
              value={percent_active}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              name="item_code"
              label="Item Code"
              value={item_code}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              select
              name="unit"
              label="Unit"
              value={unit}
              onChange={this.handleInputChange}
              variant="outlined"
            >
              {availableUnits.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="rdv"
              label="RDV"
              value={rdv}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              fullWidth
              name="density_conversion_factor"
              label="Density Conversion Factor"
              value={density_conversion_factor}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              name="packed_density"
              label="Packed Density"
              value={packed_density}
              onChange={this.handleInputChange}
              type="number"
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              name="product_lot_number"
              label="Product Lot Number"
              value={product_lot_number}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="overage_less_twelve"
              label="Overage Less Twelve"
              value={overage_less_twelve}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="overage_more_twelve"
              label="Overage More Twelve"
              value={overage_more_twelve}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <Button
              className={classes.signInButton}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Add MIL{' '}
              {this.state.loading ? (
                <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.mil.loading,
    error: state.mil.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateMIL));
