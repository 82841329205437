import React, { Component } from 'react';
import moment from 'moment';
import toFormData from 'json-form-data';

// REDUX
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './orders.module';

// Styles
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Paper, TextField, Button, Typography } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';

// Validation
import vali from '../../middleware/vali';

//Constants
import issueNames from '../../lib/constants/inspectionNames';
import issueStatus from '../../lib/constants/issueStatus';
import issuePriorities from '../../lib/constants/issuePriority';
import observationThemes from '../../lib/constants/observationThemes';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  issueForm: {
    padding: theme.spacing(5, 4, 5),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
});

const schema = {
  name: {
    expression: vali.string().required(),
    message: 'Facility name is required.',
  },
  street: {
    expression: vali.string().required(),
    message: 'Facility street is required.',
  },
  postCode: {
    expression: vali.string().required(),
    message: 'Facility postCode is required.',
  },
  facilitySupervisor: {
    expression: vali.string().required(),
    message: 'Facility Supervisor must be appointed name is required.',
  },
};

class CreateIssue extends Component {
  state = {
    inspectionName: issueNames[0],
    status: issueStatus[0],
    priority: issuePriorities[0],
    inspectionDate: moment(new Date().getTime()).format('YYYY-MM-DDThh:mm'),
    observers: [],
    inspectionTeam: '',
    observationTheme: observationThemes[0],
    facilityId: '',
    specificLocation: '',
    inspectedContractor: '',
    subContractor: '',
    checkListUrl: '',
    comment: '',
    files: [],
    errors: {},
  };

  componentWillMount = () => {
    this.props.actions.loadIssues();
  };

  handleNameInput = (event, value) => {
    if (value !== null) {
      this.setState({ inspectionName: value });
    }
  };

  handleStatusInput = (event, value) => {
    if (value !== null) {
      this.setState({ status: value });
    }
  };

  handleObservationThemeInput = (event, value) => {
    if (value !== null) {
      this.setState({ observationTheme: value });
    }
  };

  handlePriorityInput = (event, value) => {
    if (value !== null) {
      this.setState({ priority: value });
    }
  };

  handleObserverInput = async (event, values) => {
    if (values !== null) {
      this.setState({ observers: values });
    }
  };

  handleFacilityInput = async (event, value) => {
    if (value !== null) {
      this.setState({ facilityId: value._id });
    }
  };

  handleTeamInput = async (event, value) => {
    if (value !== null) {
      this.setState({ inspectionTeam: value._id });
    }
  };

  handleContractorInput = async (event, value) => {
    if (value !== null) {
      this.setState({ inspectedContractor: value._id });
    }
  };

  handleFileInput = files => {
    this.setState({ files: files });
  };

  handleSubmit = async event => {
    event.preventDefault();

    const {
      inspectionName,
      inspectionDate,
      observers,
      inspectionTeam,
      status,
      priority,
      observationTheme,
      facilityId,
      inspectedContractor,
      files,
      errors,
    } = this.state;

    let data = {
      inspectionName,
      inspectionDate,
      inspectionTeam,
      status,
      priority,
      observationTheme,
      facilityId,
      inspectedContractor,
    };
    let formData = toFormData(data);

    files.forEach(file => {
      formData.append('files', file);
    });
    formData.append('observers', JSON.stringify(observers));
    await this.props.actions.createIssue(formData);
    let { issue, error } = this.props;
    if (!error) {
      this.setState({ errors: '' });
      this.props.history.push({ pathname: `/issues/${issue._id}`, state: { issue } });
    }
  };

  handleInputChange = event => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  handleOpen = () => {
    this.setState({ modalOpen: true });
  };

  handleClose = () => {
    this.setState({ modalOpen: false });
  };

  render() {
    const { classes, users, teams, facilities, contractors } = this.props;
    const {
      inspectionName,
      inspectionDate,
      observers,
      status,
      priority,
      observationTheme,
      errors,
    } = this.state;
    const { error, loading } = this.props;
    if (loading) return <div>Loading..</div>;
    if (!contractors) return <div>Loading..</div>;
    let userCredentials = users.map(user => ({
      name: `${user.firstName} ${user.lastName}`,
      observerId: user._id,
    }));
    userCredentials = userCredentials.filter(
      ({ observerId }) => !observers.some(user => user.observerId === observerId),
    );
    let facilitiesData = facilities.map(facility => ({
      name: facility.name,
      _id: facility._id,
    }));
    let teamsData = teams.map(team => ({
      name: team.teamName,
      _id: team._id,
    }));
    let contractorsData = contractors.map(contractor => ({
      name: contractor.contractorName,
      _id: contractor._id,
    }));
    return (
      <Paper>
        {error ? <div>Error! {error.message}</div> : null}
        <form className={classes.issueForm} onSubmit={this.handleSubmit}>
          <Typography variant="h6" component="h6">
            Generate Issue
          </Typography>
          <Autocomplete
            id="combo-box-demo"
            options={issueNames}
            onChange={(event, value) => this.handleNameInput(event, value)}
            getOptionLabel={option => option}
            defaultValue={inspectionName}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                required
                name="inspectionName"
                label="Inspection Name"
                error={!!errors.inspectionName}
                helperText={errors.inspectionName}
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={issueStatus}
            onChange={(event, value) => this.handleStatusInput(event, value)}
            getOptionLabel={option => option}
            defaultValue={status}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                required
                name="status"
                label="Issue Status"
                error={!!errors.status}
                helperText={errors.status}
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={issuePriorities}
            onChange={(event, value) => this.handlePriorityInput(event, value)}
            getOptionLabel={option => option}
            defaultValue={priority}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                required
                name="priority"
                label="Issue Priority"
                error={!!errors.priority}
                helperText={errors.priority}
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={observationThemes}
            onChange={(event, value) => this.handleObservationThemeInput(event, value)}
            getOptionLabel={option => option}
            defaultValue={observationTheme}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                required
                name="observationTheme"
                label="Observation Theme"
                error={!!errors.observationTheme}
                helperText={errors.observationTheme}
              />
            )}
          />
          <TextField
            fullWidth
            id="datetime-local"
            label="Next appointment"
            onChange={this.handleInputChange}
            name="inspectionDate"
            type="datetime-local"
            required
            defaultValue={inspectionDate}
            className={classes.textField}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <Autocomplete
            multiple
            id="tags-standard"
            options={userCredentials}
            onChange={(event, values) => this.handleObserverInput(event, values)}
            getOptionLabel={option => option.name}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                fullWidth
                className={classes.textField}
                variant="standard"
                type="textField"
                label="Observers"
                placeholder="Select Observers"
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={facilitiesData}
            getOptionLabel={option => option.name}
            onChange={(event, value) => this.handleFacilityInput(event, value)}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                style={{ marginBottom: 15 }}
                type="textfield"
                required
                fullWidth
                value={this.state.facilityId}
                name="facilityId"
                label="Select Facility"
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={teamsData}
            getOptionLabel={option => option.name}
            onChange={(event, value) => this.handleTeamInput(event, value)}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                style={{ marginBottom: 15 }}
                type="textfield"
                required
                fullWidth
                value={this.state._id}
                name="inspectionTeam"
                label="Select Observation Team"
              />
            )}
          />
          <Autocomplete
            id="combo-box-demo"
            options={contractorsData}
            getOptionLabel={option => option.name}
            onChange={(event, value) => this.handleContractorInput(event, value)}
            renderInput={params => (
              <TextField
                {...params}
                className={classes.textField}
                style={{ marginBottom: 15 }}
                type="textfield"
                required
                fullWidth
                value={this.state._id}
                name="inspectedContractor"
                label="Select Inspected Contractor"
              />
            )}
          />
          <DropzoneArea onChange={this.handleFileInput} />
          <div>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              onClick={this.props.handleModalCloseFromChild}
            >
              Create
            </Button>
          </div>
        </form>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    issue: state.issues.issue,
    users: state.issues.users,
    teams: state.issues.teams,
    facilities: state.issues.facilities,
    contractors: state.issues.contractors,
    loading: state.issues.loading,
    error: state.issues.error,
    errors: state.issues.errors,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CreateIssue));
