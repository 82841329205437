import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './../Orders/orders.module';
import GIDS from './constants/gids';

import jwt from 'jsonwebtoken';

class BulkDownloadExcel extends React.Component {
  state = {
    selection: [],
    labOrders: [],
    links: [],
    loading: false,
    options: null,
    access_token: null,
    gids: GIDS,
  };

  componentDidMount() {
    this.setState(
      {
        access_token: this.props.access_token,
        selection: this.props.selection,
        labOrders: this.props.labOrders,
        options: this.props.options,
      },
      this.spliceLinks,
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selection !== this.props.selection ||
      prevProps.labOrders !== this.props.labOrders
    ) {
      this.setState(
        { selection: this.props.selection, labOrders: this.props.labOrders },
        this.spliceLinks,
      );
    }
    if (this.props.access_token !== this.state.access_token) {
      this.setState({ access_token: this.props.access_token });
    }
  }

  googleAuth = async () => {
    if (!this.state.access_token) {
      await this.setState({ loading: true });
      var pHeader = { algorithm: 'RS256' };

      var pClaim = {};
      pClaim.aud = 'https://www.googleapis.com/oauth2/v3/token';
      pClaim.scope = 'https://www.googleapis.com/auth/analytics.readonly';
      pClaim.iss = 'vlhub-413@vlhub-278518.iam.gserviceaccount.com';

      var dt = new Date().getTime() / 1000;
      dt = Math.round(dt);
      pClaim.exp = dt + 3600;
      pClaim.iat = dt;

      const GOOGLE_PRIVATE_KEY =
        '-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCySNghiEh1i1+j\nZ3VJ68k90MnK7j8iMIuQwrb+aYxew8nS86lpHCBPilYxommMkFBXgj8ZxpPi5aT/\naN9ridRGnNbIIDI0risxherOpjt0M/Sed1R0tE29Qsj18MNOtsnHOCACo+/TG+Jo\nTx0K4vd1JNyZwJYwNSNDQa/1i8+EiT2McVkjt8aFADoMKAJyCzatfXOFDBWXzxND\n19pl5uzKm6RnKHxJSE8VgMtdd4ZprImuVuB8obeGH+yvOIOTZKxgI8bqXPqZSkV8\nYNLcTHW5yIkUFPLBSUhww/Q0sM7OPiJXOntg3kyGul0rbiXWXZ5Zn7I8bs2EbzUf\nuOz/3aCvAgMBAAECggEAK1hNf72QDE/x1Rr6p5m1SBuia/2HiOrvi6bjcbA8qe+E\n+o42AnGNo3j5IsEfU0bk2Zm3eK9QVipJ9rTLGOyeGPrZtzcorakopDYwU8STkNlu\n4DYX6PXLLrf1fuHlwhXrsc1B7xJnZNaTMZQGdhafXjIzlgf+G8NXiusF+jdfIRQJ\ni9w5GE+xMHwJOE/guddxZH1CVovZ5BVXYE98/VexwouhrY44l2mJL92FbSL/aJF+\n7D2g0y5Hq4VA2q6nRQirCTQAgW5AplWIRWW/fK6Y/DZlocbcPnzRy8Mkd5MpYg2M\nU/ayU+2GEjEF8k0HI4ltnjeSoZ4kAQQTijWV3L67zQKBgQD7REMBSJNPu7bOhr2M\n0RAFGxa7MjLM0AFy6y9uOZls1wmA24hBVndR5QLoraCFF9usvyoq8ToYLr4dizq1\n6+QabZYIpGTI/ne4ijseu3HjfDODJDJn8QFnJ4oKNvm6w5hCHwHd7aEjRlYIsCEI\nZHTtscfBNVAf6JTrfxREJ9YSQwKBgQC1pJ/79idz0bGyuTi7cYiHgZGhDdS0zneb\nAG3rKNhtdnf4c4kPQsM56ldBm1sfHUdvnLP7em/DPNtnjyHyQmtJ3a6tQnqXJ0iJ\nBtdKpJ23NInVzmTT0MD0Z3kRn9OthimTv+WLjL1A6AoIUeXGQ/0wU/Sv+niNww3o\nxbs5ZC+/JQKBgFKGsA863A9jEv6wKI+Yls9mf0fKi/7TDrbE4hYs2uRxmFB3SkBT\neust8Y+kf0VXebI6M0pZYoBQ0eZ4NTdNG+VGOvHptKSDV6UH2bUVUamqwGyBNgEH\nmjNcPC1wswMRfO+yqXVPuA4S6uz9lEviWrk7G+Wscf2Y8ViLq4dbIidrAoGAWq0P\n4GwFTeAEYT/tfkUy7tJTrJ6G7KuVMZ+JP7doM1zMjw6+AQxHAQZnAK1ZU7L+Ek2b\n25JHcKNUs+HUBGf7vygXJw7ku8g0gZMUroJ26RolJMKfus2fRWLSIWYz+Y/b+ZH2\nOS/M2jX7rCOWHN+b6SBAKglTXRFeRDFRQVkuLLECgYAQOc9OKz0R/31/qx8nxFFo\nZWSBCcfHumMV0vt0jZ7L34kRZE2peZch2Qo7Ij1n4ZFWojlOYrSLh+7xIJmzo+pB\nZUNzs+DPBzwB0bjmYdx3dV1KqpTnILco9AAj5ZN2/5UkxmRNnvyaXL2tKneTnhA1\nG+r5W22g5Pz7sZbDzS1gRQ==\n-----END PRIVATE KEY-----\n';

      var sJWS = jwt.sign(pClaim, GOOGLE_PRIVATE_KEY, pHeader);

      var XHR = new XMLHttpRequest();
      var urlEncodedData = '';
      var urlEncodedDataPairs = [];

      urlEncodedDataPairs.push(
        encodeURIComponent('grant_type') +
          '=' +
          encodeURIComponent('urn:ietf:params:oauth:grant-type:jwt-bearer'),
      );
      urlEncodedDataPairs.push(encodeURIComponent('assertion') + '=' + encodeURIComponent(sJWS));
      urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

      // We define what will happen if the data are successfully sent
      XHR.addEventListener('load', () => {
        var response = JSON.parse(XHR.responseText);
        var token = response['access_token'];
        this.props.actions.changeGoogleAccessToken(token);
        this.setState({ access_token: token }, this.fetchSheets);
      });

      // We define what will happen in case of error
      XHR.addEventListener('error', function(event) {
        console.log('Oops! Something went wrong.');
      });

      XHR.open('POST', 'https://www.googleapis.com/oauth2/v3/token');
      XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
      XHR.send(urlEncodedData);
    }
  };

  spliceLinks = () => {
    let links = [];
    this.state.labOrders.forEach(order => {
      if (this.state.selection.includes(order.id) && order.order_link) {
        links.push({
          id: order.id,
          link: order.order_link.substring(0, order.order_link.lastIndexOf('/ed')),
          partner: order.partner,
          name:
            order.first_name +
            ' ' +
            order.last_name +
            '-' +
            order.completed_at.split('-')[1] +
            '/' +
            order.completed_at.split('-')[0],
        });
      }
    });
    this.setState({ links: links });
  };

  fetchSheets = () => {
    let promiseArray = [];
    this.setState({ loading: true });

    this.state.links.forEach(link => {
      promiseArray.push(
        new Promise((resolve, reject) => {
          this.fetchSingleSheet(link, resolve, reject);
        }),
      );
    });

    Promise.all(promiseArray).then(() => {
      this.setState({ loading: false }, () => {
        this.props.actions
          .updateDownloadedOrder(this.state.selection, null)
          .then(this.fetchLabOrders);
      });
    });
  };

  fetchSingleSheet = (link, resolve, reject) => {
    let gid = this.state.gids.others;
    if (link.partner in this.state.gids) {
      gid = this.state.gids[link.partner];
    }
    fetch(`${link.link}/export?format=pdf&gid=${gid}&portrait=false`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.access_token}`,
      },
    })
      .then(response => {
        // return response.blob();
        if (response.ok) {
          return response.blob();
        } else {
          // this.setState({ loading: false });
          this.props.setGoogleErrorMessage(
            `${response.status} ${response.statusText} while downloading sheet for ${link.name}`,
          );
          let array = this.state.selection;
          const index = array.indexOf(link.id);
          if (index > -1) {
            array.splice(index, 1);
          }
          this.setState({ selection: array });
          resolve(true);
          throw new Error(response.statusText);
        }
      })
      .then(blob => {
        // this.download(blob, link.link.substring(link.link.indexOf('d/') + 2), link.name);
        const url = window.URL.createObjectURL(blob);
        const downloadlink = document.createElement('a');
        downloadlink.href = url;
        downloadlink.setAttribute('download', link.name + '.pdf'); //or any other extension
        downloadlink.click();
        resolve(true);
      })
      .catch(err => {
        console.error(err);
      });
  };

  fetchLabOrders = () => {
    const options = this.state.options;
    this.props.actions.loadLabOrders(options);
  };

  render() {
    if (this.state.access_token) {
      return (
        <span onClick={this.fetchSheets}>
          {' '}
          Download {this.state.selection.length} orders spreadsheets{' '}
          {this.state.loading ? <i className="fa fa-spinner fa-spin"></i> : null}{' '}
        </span>
      );
    } else {
      return (
        <span onClick={this.googleAuth}>
          {' '}
          Download {this.state.selection.length} orders spreadsheets{' '}
          {this.state.loading ? <i className="fa fa-spinner fa-spin"></i> : null}{' '}
        </span>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    access_token: state.orders.googleAccessToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BulkDownloadExcel);
