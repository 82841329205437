import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import logger from 'redux-logger';

//Reducers
//TODO import all reducers
import accounts from './views/Accounts/accounts.module';
import partners from './views/Partners/partners.module';
import orders from './views/Orders/orders.module';
import orderComments from './views/Orders/OrderComments/orderComments.module';
import user from './views/Users/users.module';
import dashboard from './views/Dashboard/dashboard.module';
import users from './views/UsersManagement/usersmanagement.module';
import mil from './views/MIL/mil.module';
import ingredients from './views/Ingredients/ingredients.module';

const configureStore = (initialState = {}) => {
  const reducers = combineReducers({
    accounts,
    partners,
    orders,
    orderComments,
    user,
    users,
    dashboard,
    mil,
    ingredients,
    form: formReducer,
  });
  const middlewares = [thunk, process.env.NODE_ENV !== 'production' && logger].filter(Boolean);
  const enhancer = compose(applyMiddleware(...middlewares));
  const store = createStore(reducers, initialState, enhancer);

  return store;
};

const store = configureStore();

export default store;
