import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../orders.module';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class EditOrderLink extends Component {
  state = {
    open: false,
    orders: [],
    orderId: null,
    order_link: '',
    first_name: '',
    last_name: '',
    edit_order: {},
    order_date: '',
    partner: '',
    availablePartners: ['igs', 'practitioner', 'online'],
  };
  componentDidMount() {
    this.setState(
      {
        orders: this.props.orders,
        orderId: this.props.orderId,
        open: this.props.open,
      },
      this.setEditOrder,
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState(
        {
          orders: this.props.orders,
          orderId: this.props.orderId,
          open: this.props.open,
        },
        this.setEditOrder,
      );
    }
  }

  setEditOrder = () => {
    if (this.state.orders.length > 0) {
      this.state.orders.forEach(order => {
        if (order.id === this.state.orderId) {
          let order_link = order.order_link;
          if (order.order_link === null) {
            order_link = '';
          }
          this.setState({
            order_link: order_link,
            first_name: order.first_name,
            last_name: order.last_name,
            order_date: order.completed_at,
            partner: order.partner,
          });
        }
      });
    }
  };

  submitHandler = async e => {
    e.preventDefault();
    let data = {};
    data['order_link'] = this.state.order_link;
    data['first_name'] = this.state.first_name;
    data['last_name'] = this.state.last_name;
    data['order_date'] = this.state.order_date;
    data['partner_name'] = this.state.partner;
    await this.props.actions.updateOrder(this.state.orderId, data);
    await this.props.loadData(true);
    this.setState({ order_link: '' });
    this.props.handleClose();
  };

  render() {
    return (
      <Dialog
        open={this.state.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>QUICK UPDATE</DialogTitle>

        <form onSubmit={this.submitHandler} className="create-manual-order">
          <DialogContent>
            <div className="row">
              <div className="col">
                <TextField
                  label="First Name"
                  onChange={e => this.setState({ first_name: e.target.value })}
                  value={this.state.first_name}
                  variant="outlined"
                  required
                />
              </div>
              <div className="col">
                <TextField
                  label="Last Name"
                  onChange={e => this.setState({ last_name: e.target.value })}
                  value={this.state.last_name}
                  variant="outlined"
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="col">
                <TextField
                  label="Order Link"
                  onChange={e => this.setState({ order_link: e.target.value })}
                  value={this.state.order_link}
                  variant="outlined"
                  required
                />
              </div>
              <div className="col">
                <TextField
                  type="date"
                  label="Order Date"
                  onChange={e => this.setState({ order_date: e.target.value })}
                  value={this.state.order_date}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  required
                />
              </div>
            </div>
            {this.state.availablePartners.includes(this.state.partner) ? (
              <div className="row">
                <div className="col">
                  <Autocomplete
                    options={this.state.availablePartners}
                    getOptionLabel={option => option}
                    value={this.state.partner}
                    onChange={(event, newValue) => {
                      if (newValue !== null) {
                        this.setState({ partner: newValue });
                      } else {
                        this.setState({ partner: '' });
                      }
                    }}
                    renderInput={params => (
                      <TextField {...params} label="Partner" variant="outlined" required />
                    )}
                  />
                </div>
              </div>
            ) : null}
          </DialogContent>

          <DialogActions>
            <Button color="primary" variant="contained" type="submit">
              Update
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(EditOrderLink);
