import { hubApi } from '../../middleware/api';

//GET orders constants
const GET_ORDERS_BEGIN = 'GET_ORDERS_BEGIN';
const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
const GET_ORDERS_FAILURE = 'GET_ORDERS_FAILURE';

//GET ingredients
const GET_INGREDIENTS_BEGIN = 'GET_INGREDIENTS_BEGIN';
const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
const GET_INGREDIENTS_FAILURE = 'GET_INGREDIENTS_FAILURE';

//Send To Dispenser
const SEND_TO_DISPENSER_FAILURE = 'SEND_TO_DISPENSER_FAILURE';

//Mark as Duplicate
const MARK_AS_DUPLICATE_FAILURE = 'MARK_AS_DUPLICATE_FAILURE';

//GET order constants
const GET_ORDER_BEGIN = 'GET_ORDER_BEGIN';
const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
const GET_ORDER_FAILURE = 'GET_ORDER_FAILURE';
//POST order constants
const POST_ORDER_BEGIN = 'POST_ORDER_BEGIN';
const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
const POST_ORDER_FAILURE = 'POST_ORDER_FAILURE';
//PATCH order constants
const PATCH_ORDER_BEGIN = 'PATCH_ORDER_BEGIN';
const PATCH_ORDER_SUCCESS = 'PATCH_ORDER_SUCCESS';
const PATCH_ORDER_FAILURE = 'PATCH_ORDER_FAILURE';

// DELETE Order Ingredient constants
const DELETE_ORDER_INGREDIENT_BEGIN = 'DELETE_ORDER_INGREDIENT_BEGIN';
const DELETE_ORDER_INGREDIENT_SUCCESS = 'DELETE_ORDER_INGREDIENT_SUCCESS';
const DELETE_ORDER_INGREDIENT_FAILURE = 'DELETE_ORDER_INGREDIENT_FAILURE';

// POST Order Ingredient constants
const POST_ORDER_INGREDIENT_BEGIN = 'POST_ORDER_INGREDIENT_BEGIN';
const POST_ORDER_INGREDIENT_SUCCESS = 'POST_ORDER_INGREDIENT_SUCCESS';
const POST_ORDER_INGREDIENT_FAILURE = 'POST_ORDER_INGREDIENT_FAILURE';

//DELETE order constants
const DELETE_ORDER_BEGIN = 'DELETE_ORDER_BEGIN';
const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS';
const DELETE_ORDER_FAILURE = 'DELETE_ORDER_FAILURE';
//CHANGE order state
const CHANGE_ORDER_STATE_BEGIN = 'CHANGE_ORDER_STATE_BEGIN';
const CHANGE_ORDER_STATE_SUCCESS = 'CHANGE_ORDER_STATE_SUCCESS';
const CHANGE_ORDER_STATE_FAILURE = 'CHANGE_ORDER_STATE_FAILURE';

//POST shipstation bulk order create
const POST_SHIPSTATION_ORDER_BEGIN = 'POST_SHIPSTATION_ORDER_BEGIN';
const POST_SHIPSTATION_ORDER_SUCCESS = 'POST_SHIPSTATION_ORDER_SUCCESS';
const POST_SHIPSTATION_ORDER_FAILURE = 'POST_SHIPSTATION_ORDER_FAILURE';

// Product Label generation

const GENERATE_PRODUCT_LABEL_BEGIN = 'GENERATE_PRODUCT_LABEL_BEGIN';
const GENERATE_PRODUCT_LABEL_SUCCESS = 'GENERATE_PRODUCT_LABEL_SUCCESS';
const GENERATE_PRODUCT_LABEL_FAILURE = 'GENERATE_PRODUCT_LABEL_FAILURE';

//GET lab orders constants
const GET_LAB_ORDERS_BEGIN = 'GET_LAB_ORDERS_BEGIN';
const GET_LAB_ORDERS_SUCCESS = 'GET_LAB_ORDERS_SUCCESS';
const GET_LAB_ORDERS_FAILURE = 'GET_LAB_ORDERS_FAILURE';

//CHANGE lab order state
const CHANGE_LAB_ORDER_STATE_BEGIN = 'CHANGE_LAB_ORDER_STATE_BEGIN';
const CHANGE_LAB_ORDER_STATE_SUCCESS = 'CHANGE_LAB_ORDER_STATE_SUCCESS';
const CHANGE_LAB_ORDER_STATE_FAILURE = 'CHANGE_LAB_ORDER_STATE_FAILURE';

const CHANGE_FILTER = 'CHANGE_FILTER';
const CHANGE_ORDERS_FILTERS = 'CHANGE_ORDERS_FILTERS';
const CHANGE_GOOGLE_ACCESS_TOKEN = 'CHANGE_GOOGLE_ACCESS_TOKEN';

const initialState = {
  orders: [],
  labOrders: [],
  order: {},
  labOrder: {},
  totalRowCount: 30,
  totalRefillsCount: 0,
  totalNewOrdersCount: 0,
  ordersFilters: {
    refills: false,
    newOrders: true,
    all: false,
    first_name: '',
    last_name: '',
    platform_id: '',
    partner_name: '',
    start_date: '',
    end_date: '',
  },
  labOrdersFilters: {
    partner_name: '',
    start_date: '',
    end_date: '',
    first_name: '',
    last_name: '',
    lot_number: '',
    printed: false,
    print_check: false,
    sent_to_weighing: false,
  },
  message: null,
  loading: false,
  error: null,
  googleAccessToken: null,
  ingredients: [],
  partners: [],
};

const changeFilterAction = response => ({
  type: CHANGE_FILTER,
  payload: response,
});

const changeOrdersFiltersAction = response => ({
  type: CHANGE_ORDERS_FILTERS,
  payload: response,
});

const changeGoogleAccessTokenAction = response => ({
  type: CHANGE_GOOGLE_ACCESS_TOKEN,
  payload: response,
});

//GET orders list action creators

const loadOrdersBegin = () => ({
  type: GET_ORDERS_BEGIN,
});

const loadOrdersSuccess = response => ({
  type: GET_ORDERS_SUCCESS,
  payload: response,
});

const loadOrdersFailure = error => ({
  type: GET_ORDERS_FAILURE,
  payload: error,
});

//Send to dispenser action creators
const sendToDispenserFailure = error => ({
  type: SEND_TO_DISPENSER_FAILURE,
  payload: error,
});

//Send to dispenser action creators
const markAsDuplicateFailure = error => ({
  type: MARK_AS_DUPLICATE_FAILURE,
  payload: error,
});

//GET ingredient list action creators

const loadIngredientsBegin = () => ({
  type: GET_INGREDIENTS_BEGIN,
});

const loadIngredientsSuccess = response => ({
  type: GET_INGREDIENTS_SUCCESS,
  payload: response,
});

const loadIngredientsFailure = error => ({
  type: GET_INGREDIENTS_FAILURE,
  payload: error,
});

//GET order show action creators
const loadOrderBegin = () => ({
  type: GET_ORDER_BEGIN,
});

const loadOrderSuccess = response => ({
  type: GET_ORDER_SUCCESS,
  payload: response,
});

const loadOrderFailure = error => ({
  type: GET_ORDER_FAILURE,
  payload: error,
});

//POST order create action creators
const createOrderBegin = () => ({
  type: POST_ORDER_BEGIN,
});

const createOrderSuccess = response => ({
  type: POST_ORDER_SUCCESS,
  payload: response,
});

const createOrderFailure = error => ({
  type: POST_ORDER_FAILURE,
  payload: error,
});

//PATCH order action creators
const updateOrderBegin = () => ({
  type: PATCH_ORDER_BEGIN,
});

const updateOrderSuccess = response => ({
  type: PATCH_ORDER_SUCCESS,
  payload: response,
});

const updateOrderFailure = error => ({
  type: PATCH_ORDER_FAILURE,
  payload: error,
});

//DELETE order ingredient action creators
const deleteOrderIngredientBegin = () => ({
  type: DELETE_ORDER_INGREDIENT_BEGIN,
});

const deleteOrderIngredientSuccess = response => ({
  type: DELETE_ORDER_INGREDIENT_SUCCESS,
  payload: response,
});

const deleteOrderIngredientFailure = error => ({
  type: DELETE_ORDER_INGREDIENT_FAILURE,
  payload: error,
});

//POST order ingredient action creators
const postOrderIngredientBegin = () => ({
  type: POST_ORDER_INGREDIENT_BEGIN,
});

const postOrderIngredientSuccess = response => ({
  type: POST_ORDER_INGREDIENT_SUCCESS,
  payload: response,
});

const postOrderIngredientFailure = error => ({
  type: POST_ORDER_INGREDIENT_FAILURE,
  payload: error,
});

//PATCH orders state action creators
const updateOrdersBegin = _ => ({
  type: CHANGE_ORDER_STATE_BEGIN,
});
const updateOrdersSuccess = response => ({
  type: CHANGE_ORDER_STATE_SUCCESS,
  payload: response,
});

const updateOrdersFailure = error => ({
  type: CHANGE_ORDER_STATE_FAILURE,
  payload: error,
});

//PATCH order create action creators
const deleteOrderBegin = () => ({
  type: DELETE_ORDER_BEGIN,
});

const deleteOrderSuccess = response => ({
  type: DELETE_ORDER_SUCCESS,
  payload: response,
});

const deleteOrderFailure = error => ({
  type: DELETE_ORDER_FAILURE,
  payload: error,
});

//GET lab orders list action creators

const loadLabOrdersBegin = () => ({
  type: GET_LAB_ORDERS_BEGIN,
});

const loadLabOrdersSuccess = response => ({
  type: GET_LAB_ORDERS_SUCCESS,
  payload: response,
});

const loadLabOrdersFailure = error => ({
  type: GET_LAB_ORDERS_FAILURE,
  payload: error,
});

//PATCH lab orders list action creators
const updateLabOrdersBegin = _ => ({
  type: CHANGE_LAB_ORDER_STATE_BEGIN,
});
const updateLabOrdersSuccess = response => ({
  type: CHANGE_LAB_ORDER_STATE_SUCCESS,
  payload: response,
});

const updateLabOrdersFailure = error => ({
  type: CHANGE_LAB_ORDER_STATE_FAILURE,
  payload: error,
});

//POST shipstation order create/delete action creators
const ShipstationOrderBegin = () => ({
  type: POST_SHIPSTATION_ORDER_BEGIN,
});

const ShipstationOrderSuccess = () => ({
  type: POST_SHIPSTATION_ORDER_SUCCESS,
});

const ShipstationOrderFailure = error => ({
  type: POST_SHIPSTATION_ORDER_FAILURE,
  payload: error,
});

//POST product label action creators
const GenerateProductLabelBegin = () => ({
  type: GENERATE_PRODUCT_LABEL_BEGIN,
});

const GenerateProductLabelSuccess = () => ({
  type: GENERATE_PRODUCT_LABEL_SUCCESS,
});

const GenerateProductLabelFailure = error => ({
  type: GENERATE_PRODUCT_LABEL_FAILURE,
  payload: error,
});

export const changeFilter = filter => async dispatch => {
  dispatch(changeFilterAction(filter));
};

export const changeOrdersFilters = filters => async dispatch => {
  dispatch(changeOrdersFiltersAction(filters));
};

export const changeGoogleAccessToken = token => async dispatch => {
  dispatch(changeGoogleAccessTokenAction(token));
};

//POST shipstation bulk order create actions
export const ShipstationOrder = (params, bulk, action_type, id) => async dispatch => {
  dispatch(ShipstationOrderBegin());
  try {
    if (bulk) {
      const request = await hubApi(`/shipstation_bulk_order_create?${params}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data;',
          'cache-control': 'no-cache',
        },
      });
      if (request.error) {
        return dispatch(ShipstationOrderFailure(request));
      }
      dispatch(ShipstationOrderSuccess(request));
    } else {
      const request = await hubApi(`/orders/${id}/shipstation_orders?action_type=${action_type}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data;',
          'cache-control': 'no-cache',
        },
      });
      if (request.error) {
        return dispatch(ShipstationOrderFailure(request));
      }
      dispatch(ShipstationOrderSuccess(request));
    }
  } catch (e) {
    dispatch(ShipstationOrderFailure(e));
  }
};

//POST shipstation bulk order create actions
export const GenerateProductLabel = (ids, type = 'single') => async dispatch => {
  if (type === 'bulk') {
    dispatch(GenerateProductLabelBegin());
    try {
      const request = await hubApi(`/generate_product_labels`, {
        method: 'POST',
        body: {
          ids,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (request.error) {
        return dispatch(GenerateProductLabelFailure(request));
      }
      dispatch(GenerateProductLabelSuccess(request));
    } catch (e) {
      dispatch(GenerateProductLabelFailure(e));
    }
  } else {
    dispatch(GenerateProductLabelBegin());
    try {
      const request = await hubApi(`/orders/${ids}/generate_product_label`, {
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data;',
          'cache-control': 'no-cache',
        },
      });
      if (request.error) {
        return dispatch(GenerateProductLabelFailure(request));
      }
      dispatch(GenerateProductLabelSuccess(request));
    } catch (e) {
      dispatch(GenerateProductLabelFailure(e));
    }
  }
};

export const loadOrders = options => async dispatch => {
  try {
    dispatch(loadOrdersBegin());
    const request = await hubApi('/orders', options);
    if (request.error) {
      return dispatch(loadOrdersFailure(request));
    }
    dispatch(loadOrdersSuccess(request));
  } catch (e) {
    return loadOrdersFailure(e);
  }
};

// GET ingredients action

export const loadIngredients = () => async dispatch => {
  try {
    dispatch(loadIngredientsBegin());
    const request = await hubApi('/mil');
    if (request.error) {
      return dispatch(loadIngredientsFailure(request));
    }
    dispatch(loadIngredientsSuccess(request));
  } catch (e) {
    return loadIngredientsFailure(e);
  }
};

//GET order actions
export const loadOrder = id => async dispatch => {
  try {
    dispatch(loadOrderBegin());
    const request = await hubApi(`/orders/${id}`);
    if (request.error) {
      return dispatch(loadOrderFailure(request));
    }
    dispatch(loadOrderSuccess(request));
  } catch (e) {
    return loadOrderFailure(e);
  }
};

//POST order actions
export const createOrder = (data, partner_name) => async dispatch => {
  dispatch(createOrderBegin());
  try {
    const request = await hubApi(`/orders?partner_name=${partner_name}`, {
      method: 'POST',
      body: data,
      // headers: {
      //   'Content-Type': 'multipart/form-data;',
      //   'cache-control': 'no-cache',
      // },
    });
    request.error
      ? dispatch(createOrderFailure(request.error))
      : dispatch(createOrderSuccess(request));
  } catch (e) {
    dispatch(createOrderFailure(e));
  }
};

//PATCH order actions
export const updateOrder = (id, data) => async dispatch => {
  dispatch(updateOrderBegin());
  try {
    const request = await hubApi(`/orders/${id}`, {
      method: 'PATCH',
      body: {
        order: data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(updateOrderFailure(request.error))
      : dispatch(updateOrderSuccess(request));
  } catch (e) {
    dispatch(updateOrderFailure(e));
  }
};

export const updateOrderIngredients = (id, data) => async dispatch => {
  dispatch(updateOrderBegin());
  try {
    const request = await hubApi(`/orders/${id}`, {
      method: 'PATCH',
      body: {
        ingredient_update: true,
        order: {
          ingredients_attributes: [data],
        },
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(updateOrderFailure(request.error))
      : dispatch(updateOrderSuccess(request));
  } catch (e) {
    dispatch(updateOrderFailure(e));
  }
};

export const updateOrderState = (id, status) => async dispatch => {
  dispatch(updateOrderBegin());
  try {
    const request = await hubApi(`/orders/${id}`, {
      method: 'PATCH',
      body: {
        ingredient_update: true,
        order: {
          status,
        },
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(updateOrderFailure(request.error))
      : dispatch(updateOrderSuccess(request));
  } catch (e) {
    dispatch(updateOrderFailure(e));
  }
};

export const updateOrderStates = (ids, status) => async dispatch => {
  dispatch(updateOrdersBegin());
  try {
    if (!status) {
      const request = await hubApi(`/update_order_state`, {
        method: 'PATCH',
        body: {
          ids,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      request.error
        ? dispatch(updateOrdersFailure(request.error))
        : dispatch(updateOrdersSuccess(request));
    } else {
      const request = await hubApi(`/change_orders_status?status=${status}`, {
        method: 'PATCH',
        body: {
          ids,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
      request.error
        ? dispatch(updateOrdersFailure(request.error))
        : dispatch(updateOrdersSuccess(request));
    }
  } catch (e) {
    dispatch(updateOrderFailure(e));
  }
};

export const postOrderIngredient = (id, data) => async dispatch => {
  dispatch(postOrderIngredientBegin());
  try {
    const request = await hubApi(`/orders/${id}/ingredients`, {
      method: 'POST',
      body: { ingredient: data },
    });
    request.error
      ? dispatch(postOrderIngredientFailure(request.error))
      : dispatch(postOrderIngredientSuccess(request));
  } catch (e) {
    dispatch(postOrderIngredientFailure(e));
  }
};

export const deleteOrderIngredient = (orderId, ingredientId) => async dispatch => {
  try {
    dispatch(deleteOrderIngredientBegin());
    await hubApi(`/orders/${orderId}/ingredients/${ingredientId}`, {
      method: 'DELETE',
    });
    dispatch(deleteOrderIngredientSuccess());
  } catch (e) {
    return dispatch(deleteOrderIngredientFailure(e));
  }
};

//GET order actions
export const deleteOrder = id => async dispatch => {
  try {
    dispatch(deleteOrderBegin());
    await hubApi(`/orders/${id}`, {
      method: 'DELETE',
    });
    dispatch(deleteOrderSuccess(id));
    return id;
  } catch (e) {
    return deleteOrderFailure(e);
  }
};

export const generateDocuments = (id, type) => async dispatch => {
  try {
    dispatch(updateOrderBegin());
    const request = await hubApi(`/orders/${id}/generate_documents`, {
      method: 'POST',
      body: {
        id,
        type,
      },
    });
    dispatch(updateOrderSuccess(request));
  } catch (e) {
    return dispatch(updateOrderFailure(e));
  }
};

export const loadLabOrders = options => async dispatch => {
  try {
    dispatch(loadLabOrdersBegin());
    const request = await hubApi('/lab_orders', options);
    if (request.error) {
      return dispatch(loadLabOrdersFailure(request));
    }
    dispatch(loadLabOrdersSuccess(request));
  } catch (e) {
    return dispatch(loadLabOrdersFailure(e));
  }
};

export const updateLabOrderStates = (ids, status) => async dispatch => {
  dispatch(updateLabOrdersBegin());
  try {
    const request = await hubApi(`/transfer_orders_to_state?lab_state=${status}`, {
      method: 'PATCH',
      body: {
        ids,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(updateLabOrdersFailure(request.error))
      : dispatch(updateLabOrdersSuccess(request));
  } catch (e) {
    dispatch(updateLabOrdersFailure(e));
  }
};

export const updateDownloadedOrder = (ids, status) => async dispatch => {
  try {
    if (status !== null) {
      await hubApi(`/mark_as_printed`, {
        method: 'PATCH',
        body: {
          ids,
          print_check: status,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      await hubApi(`/update_downloaded_orders`, {
        method: 'PATCH',
        body: {
          ids,
        },
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
  } catch (e) {
    console.log(e);
  }
};

export const sendToDispensar = ids => async dispatch => {
  try {
    const request = await hubApi(`/bulk_send_to_dispenser`, {
      method: 'POST',
      body: {
        ids,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.error) {
      dispatch(sendToDispenserFailure(request.error));
    }
  } catch (e) {
    dispatch(sendToDispenserFailure(e));
  }
};

export const markAsDuplicate = ids => async dispatch => {
  try {
    const request = await hubApi(`/mark_as_duplicate`, {
      method: 'PATCH',
      body: {
        ids,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (request.error) {
      dispatch(markAsDuplicateFailure(request.error));
    }
  } catch (e) {
    dispatch(markAsDuplicateFailure(e));
  }
};

export const actions = {
  loadOrders,
  loadIngredients,
  loadOrder,
  createOrder,
  updateOrder,
  deleteOrder,
  deleteOrderIngredient,
  postOrderIngredient,
  updateOrderIngredients,
  updateOrderStates,
  updateOrderState,
  generateDocuments,
  loadLabOrders,
  updateLabOrderStates,
  changeFilter,
  changeOrdersFilters,
  changeGoogleAccessToken,
  updateDownloadedOrder,
  sendToDispensar,
  ShipstationOrder,
  GenerateProductLabel,
  markAsDuplicate,
};

//reducers
export default function orderReducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case GET_ORDERS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ORDERS_SUCCESS:
      return {
        ...state,
        orders: action.payload.orders,
        totalRowCount: action.payload.total_row_count,
        totalRefillsCount: action.payload.total_refills,
        totalNewOrdersCount: action.payload.new_orders,
        partners: action.payload.partners,
        loading: false,
      };
    case GET_ORDERS_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        totalRowCount: 0,
        totalRefillsCount: 0,
        totalNewOrdersCount: 0,
        orders: [],
        partners: [],
        loading: false,
      };

    case GET_INGREDIENTS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: action.payload,
        loading: false,
      };
    case GET_INGREDIENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        ingredients: [],
        loading: false,
      };

    case GET_ORDER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
      };
    case GET_ORDER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        order: {},
        loading: false,
      };
    case POST_ORDER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
      };
    case POST_ORDER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      console.error('phudiiiiiiiiiii', error);
      return {
        ...state,
        error: error,
        order: {},
        loading: false,
        errors: true,
      };
    case PATCH_ORDER_BEGIN:
      return {
        ...state,
        error: null,
      };
    case PATCH_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
      };
    case PATCH_ORDER_FAILURE:
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        order: {},
      };
    case CHANGE_ORDER_STATE_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case CHANGE_ORDER_STATE_SUCCESS:
      return {
        ...state,
        orders: action.payload,
        loading: false,
      };
    case CHANGE_ORDER_STATE_FAILURE:
      return {
        ...state,
        error: action.payload,
        order: [],
        loading: false,
      };
    case POST_ORDER_INGREDIENT_BEGIN:
      return {
        ...state,
        error: null,
      };
    case POST_ORDER_INGREDIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_ORDER_INGREDIENT_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
      };
    case DELETE_ORDER_INGREDIENT_BEGIN:
      return {
        ...state,
        error: null,
      };
    case DELETE_ORDER_INGREDIENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ORDER_INGREDIENT_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
      };
    case SEND_TO_DISPENSER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
      };
    case DELETE_ORDER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload.id,
        loading: false,
      };
    case DELETE_ORDER_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        order: {},
        loading: false,
      };
    case GET_LAB_ORDERS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_LAB_ORDERS_SUCCESS:
      return {
        ...state,
        labOrders: action.payload.orders,
        totalRowCount: action.payload.total_row_count,
        partners: action.payload.partners,
        loading: false,
      };
    case GET_LAB_ORDERS_FAILURE:
      error = action.payload.data || action.payload.message;
      return {
        ...state,
        error: error,
        labOrders: [],
        totalRowCount: 0,
        partners: [],
        loading: false,
      };

    case POST_SHIPSTATION_ORDER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_SHIPSTATION_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_SHIPSTATION_ORDER_FAILURE:
      error = action.payload.data || action.payload.message;
      return {
        ...state,
        error: error,
        loading: false,
      };
    case GENERATE_PRODUCT_LABEL_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GENERATE_PRODUCT_LABEL_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case GENERATE_PRODUCT_LABEL_FAILURE:
      error = action.payload.data || action.payload.message;
      return {
        ...state,
        error: error,
        loading: false,
      };
    case CHANGE_FILTER:
      return {
        ...state,
        labOrdersFilters: action.payload,
      };
    case CHANGE_ORDERS_FILTERS:
      return {
        ...state,
        ordersFilters: action.payload,
      };
    case CHANGE_GOOGLE_ACCESS_TOKEN:
      return {
        ...state,
        googleAccessToken: action.payload,
      };
    case MARK_AS_DUPLICATE_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
      };

    default:
      return state;
  }
}
