import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
// //Redux
import { actions } from './users.module';

// //Middlewares
import vali from '../../middleware/vali';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, TextField, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const schema = {
  email: {
    expression: vali.email().required(),
    message: 'Please enter a valid email address.',
  },
  password: {
    expression: vali.string().required(),
    message: 'Please enter a valid password.',
  },
};

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '500px',
  },
  quoteText: {
    color: theme.palette.white,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
});

class SignIn extends Component {
  state = {
    user: null,
    email: '',
    password: '',
    loading: false,
    errors: {},
  };

  componentDidMount() {
    this.setState({ user: this.props.user }, this.changePage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.state.user) {
      this.setState({ user: prevProps.user }, this.changePage);
    }
  }

  changePage = () => {
    if (this.state.user.status === 'authenticated') {
      this.props.history.push('/');
    }
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSubmit = event => {
    event.preventDefault();

    const { email, password, errors } = this.state;
    const error = vali.validate({ email, password }, schema);
    if (error) {
      errors[error.field] = error.message;
      this.setState({ errors });
      return;
    }

    this.setState({ loading: true }, () => {
      this.props.actions.signInUser({ email, password }).then(() => {
        if (this.props.user.status === 'authenticated') {
          this.setState({ loading: false }, () => {
            this.props.history.push('/');
          });
        } else {
          this.setState({ loading: false });
        }
      });
    });
  };
  handleInputChange = event => {
    const { value, name } = event.target;
    const { errors } = this.state;

    errors[name] = null;
    errors.page = null;

    this.setState({ [name]: value, errors });
  };
  render() {
    const { classes, user } = this.props;
    const { email, password, errors } = this.state;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h6">
                  Welcome to VLHub
                </Typography>
                <div className={classes.person}></div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Typography className={classes.title} variant="h2">
                    Sign in
                  </Typography>
                  {user.error !== null ? (
                    <div className="error">
                      <p>{user.error.message}</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <Grid className={classes.socialButtons} container spacing={2}></Grid>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email}
                    value={email}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    type="password"
                    name="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password}
                    value={password}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in now{' '}
                    {this.state.loading ? (
                      <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
                    ) : null}
                  </Button>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignIn));
