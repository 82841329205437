import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../orders.module';
import { Link } from 'react-router-dom';

import CreateManualOrder from './CreateManualOrder';
import EditOrderLink from './EditOrderLink';
import PdfViewer from './PdfViewer';

import { Paper, Button, CircularProgress } from '@material-ui/core';

import DescriptionIcon from '@material-ui/icons/Description';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import LabelIcon from '@material-ui/icons/Label';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';

import './filters.css';

import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  CustomPaging,
  DataTypeProvider,
  TableColumnResizing,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const conditionalStyle = value => {
  if (value === 'Production') {
    return '#ed884a';
  } else if (value === 'Completed Production') {
    return '#ff8cf4';
  } else if (value === 'Formula Shipped') {
    return '#4585a3';
  } else if (value === 'Delivered') {
    return '#22941c';
  } else if (value === 'On Hold') {
    return '#444';
  } else if (value === 'Cancelled') {
    return '#d4512c';
  }
};

const getColumns = status => {
  if (status === 'production') {
    return [
      {
        name: 'platform_id',
        title: 'Platform ID',
        getCellValue: row => (row.platform_id ? row.platform_id : row.partner_uuid),
      },
      {
        name: 'id',
        title: 'Order ID',
        getCellValue: row =>
          row.id ? (
            <Link className="btn" to={`/orders/${row.id}`}>
              {row.id}
            </Link>
          ) : (
            ''
          ),
      },
      { name: 'first_name', title: 'First Name', getCellValue: row => row.first_name },
      { name: 'last_name', title: 'Last Name', getCellValue: row => row.last_name },
      { name: 'email', title: 'Email', getCellValue: row => (row.email ? row.email : '') },
      {
        name: 'refill',
        title: 'Refill',
        getCellValue: row =>
          row.refill === 'Yes' ? (
            <i className="fa fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa fa-times" style={{ color: 'red' }}></i>
          ),
      },
      { name: 'partner', title: 'Partner', getCellValue: row => row.partner },
      { name: 'completed_at', title: 'Order Date', getCellValue: row => row.completed_at },
      {
        name: 'ship_by',
        title: 'Ship By',
        getCellValue: row => (row.ship_by_date ? row.ship_by_date : ''),
      },
      { name: 'folder_url', title: 'Folder/Label Link' },
      { name: 'order_link', title: 'Order Link', getCellValue: row => row.order_link },
      {
        name: 'lab_state',
        title: 'Lab State',
        getCellValue: row => row.lab_state.replace('_', ' '),
      },
      { name: 'status_string', title: 'Status', getCellValue: row => row.status_string },
      { name: 'create_label', title: 'Create Label', getCellValue: row => row.id },
      { name: 'actions', title: 'Actions', getCellValue: row => row.id },
    ];
  } else {
    return [
      {
        name: 'platform_id',
        title: 'Platform ID',
        getCellValue: row => (row.platform_id ? row.platform_id : row.partner_uuid),
      },
      {
        name: 'id',
        title: 'Order ID',
        getCellValue: row =>
          row.id ? (
            <Link className="btn" to={`/orders/${row.id}`}>
              {row.id}
            </Link>
          ) : (
            ''
          ),
      },
      { name: 'first_name', title: 'First Name', getCellValue: row => row.first_name },
      { name: 'last_name', title: 'Last Name', getCellValue: row => row.last_name },
      { name: 'email', title: 'Email', getCellValue: row => (row.email ? row.email : '') },
      {
        name: 'refill',
        title: 'Refill',
        getCellValue: row =>
          row.refill === 'Yes' ? (
            <i className="fa fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa fa-times" style={{ color: 'red' }}></i>
          ),
      },
      { name: 'partner', title: 'Partner', getCellValue: row => row.partner },
      { name: 'completed_at', title: 'Order Date', getCellValue: row => row.completed_at },
      {
        name: 'ship_by',
        title: 'Ship By',
        getCellValue: row => (row.ship_by_date ? row.ship_by_date : ''),
      },
      { name: 'folder_url', title: 'Folder/Label Link' },
      { name: 'order_link', title: 'Order Link', getCellValue: row => row.order_link },
      { name: 'status_string', title: 'Status', getCellValue: row => row.status_string },
      { name: 'create_label', title: 'Create Label', getCellValue: row => row.id },
      { name: 'actions', title: 'Actions', getCellValue: row => row.id },
    ];
  }
};

class Orders extends React.Component {
  state = {
    orders: [],
    columns: getColumns(this.props.options.query.status),
    selection: [],
    rowCount: 0,
    ordersFilters: {},
    anchorElActions: null,
    shipstationSuccessMessage: null,
    shipstationLoading: false,
    duplicateSuccessMessage: null,
    duplicateLoading: false,
    lastQuery: null,
    pageSize: 50,
    currentPage: 0,
    filterView: true,
    partnerName: null,
    createManualOrder: false,
    editOrderLinkView: false,
    editOrderId: null,
    partners: null,
    loading: false,
    generateBulkProductLabelsLoading: false,
    defaultColumnWidths: [
      { columnName: 'platform_id', width: 100 },
      { columnName: 'id', width: 70 },
      { columnName: 'first_name', width: 90 },
      { columnName: 'last_name', width: 90 },
      { columnName: 'email', width: 180 },
      { columnName: 'refill', width: 50 },
      { columnName: 'partner', width: 100 },
      { columnName: 'completed_at', width: 90 },
      { columnName: 'ship_by', width: 90 },
      { columnName: 'folder_url', width: 125 },
      { columnName: 'label_url', width: 90 },
      { columnName: 'order_link', width: 90 },
      { columnName: 'lab_state', width: 100 },
      { columnName: 'status_string', width: 148 },
      { columnName: 'create_label', width: 100 },
      { columnName: 'actions', width: 100 },
    ],
    generateProductLabelMessage: false,
    generateProductLabelError: false,
    pdfView: false,
    pdfViewUrl: null,
    sortingStateColumnExtensions: [
      { columnName: 'platform_id', sortingEnabled: false },
      { columnName: 'id', sortingEnabled: false },
      { columnName: 'first_name', sortingEnabled: false },
      { columnName: 'last_name', sortingEnabled: false },
      { columnName: 'email', sortingEnabled: false },
      { columnName: 'refill', sortingEnabled: false },
      { columnName: 'partner', sortingEnabled: false },
      { columnName: 'completed_at', sortingEnabled: true },
      { columnName: 'ship_by', sortingEnabled: true },
      { columnName: 'folder_url', sortingEnabled: false },
      { columnName: 'label_url', sortingEnabled: false },
      { columnName: 'order_link', sortingEnabled: false },
      { columnName: 'lab_state', sortingEnabled: false },
      { columnName: 'status_string', sortingEnabled: false },
      { columnName: 'create_label', sortingEnabled: false },
      { columnName: 'actions', sortingEnabled: false },
    ],
  };

  componentDidMount() {
    this.setState({ ordersFilters: this.props.ordersFilters }, () => {
      this.loadData();
    });
  }

  fetchOrders = options => {
    this.props.actions.loadOrders(options).then(() => {
      console.log(this.props.totalRowCount);
      this.setState(
        {
          orders: this.props.orders,
          partners: this.props.partners,
          loading: false,
        },
        this.setOrdersWithFilters,
      );
    });
  };

  loadData = (force = false) => {
    let options = this.props.options;
    options.query.take = this.state.pageSize;
    options.query.skip = this.state.pageSize * this.state.currentPage;
    options.query.first_name = this.state.ordersFilters.first_name;
    options.query.last_name = this.state.ordersFilters.last_name;
    options.query.platform_id = this.state.ordersFilters.platform_id;
    options.query.partner_name = this.state.ordersFilters.partner_name;
    options.query.start_date = this.state.ordersFilters.start_date;
    options.query.end_date = this.state.ordersFilters.end_date;

    if (this.state.ordersFilters.refills === true) {
      options.query.refill = true;
    }
    if (this.state.ordersFilters.newOrders === true) {
      options.query.refill = false;
    }
    if (this.state.ordersFilters.all === true) {
      options.query.refill = null;
    }

    const query = this.buildQueryString(
      options.query.status,
      options.query.skip,
      options.query.take,
      options.query.first_name,
      options.query.last_name,
      options.query.platform_id,
      options.query.partner_name,
      options.query.start_date,
      options.query.end_date,
      options.query.refill,
    );

    if ((query !== this.state.lastQuery && !this.state.loading) || force) {
      this.setState({ loading: true }, () => {
        this.fetchOrders(options);
      });
    }
    this.setState({ lastQuery: query });
  };

  getRowId = row => row.id;
  buildQueryString = (
    status,
    skip,
    take,
    first_name,
    last_name,
    platform_id,
    partner_name,
    start_date,
    end_date,
    refill,
  ) =>
    `status=${status}&skip=${skip}&take=${take}&first_name=${first_name}&last_name=${last_name}&platform_id=${platform_id}&partner_name=${partner_name}&start_date=${start_date}&end_date=${end_date}&refill=${refill}`;

  StatusFormatter = ({ value }) => (
    <span
      style={{
        padding: '8px 12px',
        width: '180px',
        fontSize: '12px',
        fontWeight: 'bold',
        borderRadius: '4px',
        color: '#fff',
        backgroundColor: value === 'Pending Approval' ? '#ff9100' : conditionalStyle(value),
      }}
    >
      {value}
    </span>
  );

  LinkFormatter = props =>
    props.value ? (
      <a href={props.value} target="_blank" style={{ color: 'grey' }} rel="noreferrer">
        <DescriptionIcon />
      </a>
    ) : null;

  FolderLinkFormatter = props => {
    return (
      <span>
        {props.value ? (
          <a href={props.value} target="_blank" style={{ color: 'grey' }} rel="noreferrer">
            <FolderOpenIcon />
          </a>
        ) : null}
        &nbsp;
        {props.row.label_url ? (
          <span
            onClick={() => this.setState({ pdfView: true, pdfViewUrl: props.row.label_url })}
            style={{ color: 'grey', cursor: 'pointer' }}
          >
            <LabelIcon />
          </span>
        ) : null}
      </span>
    );
  };

  ActionsFormatter = props =>
    props.value ? (
      <Button
        color="primary"
        variant="contained"
        disabled={
          this.props.current_user &&
          this.props.current_user.user &&
          (this.props.current_user.user.role === 'admin' ||
            this.props.current_user.user.role === 'lab')
            ? false
            : true
        }
        size="small"
        onClick={() =>
          this.setState({
            editOrderId: props.value,
            editOrderLinkView: !this.state.editOrderLinkView,
          })
        }
      >
        Edit
      </Button>
    ) : null;

  CreateLabelFormatter = props => {
    if (props.value) {
      return (
        <Button
          variant="contained"
          color="secondary"
          size="small"
          disabled={props.row.order_link ? false : true}
          onClick={() => this.generateProductLabel(props.value)}
        >
          &nbsp; <i className="fa fa-plus"></i>
        </Button>
      );
    }
  };

  StatusProvider = props => (
    <DataTypeProvider formatterComponent={this.StatusFormatter} {...props} />
  );

  LinkProvider = props => (
    <DataTypeProvider formatterComponent={this.LinkFormatter} {...props} props={props} />
  );

  FolderLinkProvider = props => (
    <DataTypeProvider formatterComponent={this.FolderLinkFormatter} {...props} props={props} />
  );

  ActionsProvider = props => (
    <DataTypeProvider formatterComponent={this.ActionsFormatter} {...props} />
  );

  CreateLabelProvider = props => (
    <DataTypeProvider formatterComponent={this.CreateLabelFormatter} {...props} />
  );

  handleChange = async event => {
    let obj = {
      refills: false,
      newOrders: false,
      all: false,
      first_name: this.state.ordersFilters.first_name,
      last_name: this.state.ordersFilters.last_name,
      platform_id: this.state.ordersFilters.platform_id,
      partner_name: this.state.partnerName,
      start_date: this.state.ordersFilters.start_date,
      end_date: this.state.ordersFilters.end_date,
    };
    obj[event.target.name] = true;
    this.setState({ ordersFilters: obj });
  };

  handleInputChange = event => {
    let obj = {
      refills: this.state.ordersFilters.refills,
      newOrders: this.state.ordersFilters.newOrders,
      all: this.state.ordersFilters.all,
      first_name: this.state.ordersFilters.first_name,
      last_name: this.state.ordersFilters.last_name,
      platform_id: this.state.ordersFilters.platform_id,
      partner_name: this.state.partnerName,
      start_date: this.state.ordersFilters.start_date,
      end_date: this.state.ordersFilters.end_date,
    };
    obj[event.target.name] = event.target.value;
    this.setState({ ordersFilters: obj });
  };

  handlePartnerNameChange = value => {
    let obj = {
      refills: this.state.ordersFilters.refills,
      newOrders: this.state.ordersFilters.newOrders,
      all: this.state.ordersFilters.all,
      first_name: this.state.ordersFilters.first_name,
      last_name: this.state.ordersFilters.last_name,
      platform_id: this.state.ordersFilters.platform_id,
      partner_name: value,
      start_date: this.state.ordersFilters.start_date,
      end_date: this.state.ordersFilters.end_date,
    };
    this.setState({ partnerName: value, ordersFilters: obj });
  };

  handleSubmitFilters = async event => {
    event.preventDefault();
    await this.props.actions.changeOrdersFilters(this.state.ordersFilters);
    this.loadData(true);
  };

  handleClickActions = event => {
    this.setState({ anchorElActions: event.currentTarget });
  };

  handleCloseActions = () => {
    this.setState({ anchorElActions: null });
  };

  setOrdersWithFilters = async () => {
    if (this.state.ordersFilters.refills) {
      await this.setState({ rowCount: this.props.totalRefillsCount });
    } else if (this.state.ordersFilters.newOrders) {
      await this.setState({ rowCount: this.props.totalNewOrdersCount });
    } else {
      await this.setState({ rowCount: this.props.totalRowCount });
    }
  };

  createShipstationOrders = async () => {
    var params = '';
    for (let i = 0; i < this.state.selection.length; i++) {
      if (params === '') {
        params += 'ids[]=' + this.state.selection[i];
      } else {
        params += '&ids[]=' + this.state.selection[i];
      }
    }

    await this.setState({ shipstationLoading: true, shipstationSuccessMessage: null });
    await this.props.actions.ShipstationOrder(params, true, null, null);
    this.setState({ selection: [], shipstationLoading: false, shipstationSuccessMessage: true });
    setTimeout(() => {
      this.setState({ shipstationSuccessMessage: null });
    }, 3000);
  };

  markAsDuplicate = async () => {
    await this.setState({ duplicateLoading: true, duplicateSuccessMessage: null });
    await this.props.actions.markAsDuplicate(this.state.selection);

    this.setState({ selection: [], duplicateLoading: false, duplicateSuccessMessage: true });
    setTimeout(() => {
      this.setState({ duplicateSuccessMessage: null }, () => {
        if (!this.props.error) {
          this.loadData(true);
        }
      });
    }, 3000);
  };

  updateOrderStates = async status => {
    await this.props.actions.updateOrderStates(this.state.selection, status);
    this.setState({ anchorElActions: null, selection: [] }, this.loadData(true));
  };

  renderMenuItems = () => {
    return this.props.tabs.map(tab => {
      if (tab.title !== this.props.tab) {
        return (
          <MenuItem onClick={() => this.updateOrderStates(tab.query.status)} key={tab.title}>
            Transfer {this.state.selection.length} orders to {tab.title}
          </MenuItem>
        );
      }
      return null;
    });
  };

  handleCreateOrderClose = () => {
    this.setState({ createManualOrder: false });
  };

  handleEditOrderLinkClose = () => {
    this.setState({ editOrderLinkView: false });
  };

  handlePdfViewClose = () => {
    this.setState({ pdfView: false });
  };

  handleSelectionChange = ids => {
    this.setState({ selection: ids });
  };

  generateProductLabel = async id => {
    let request = true;
    if (id) {
      await this.props.actions.GenerateProductLabel(id, 'single');
    } else {
      await this.setState({ generateBulkProductLabelsLoading: true });
      this.state.orders.forEach(order => {
        if (this.state.selection.includes(order.id) && !order.order_link) {
          request = false;
        }
      });

      if (request) {
        await this.props.actions.GenerateProductLabel(this.state.selection, 'bulk');
      } else {
        this.setState({ generateProductLabelError: true });
        setTimeout(() => {
          this.setState({ generateProductLabelError: false });
        }, 35000);
      }
    }

    if (!this.props.error && request) {
      this.setState({ generateProductLabelMessage: true });
      setTimeout(() => {
        this.setState({ generateProductLabelMessage: false });
      }, 5000);
    }

    await this.setState({ generateBulkProductLabelsLoading: false });
  };

  setCurrentPage = page => {
    console.log(page);
    this.setState({ currentPage: page }, this.loadData);
  };

  setSorting = value => {
    value.forEach(sort_object => {
      if (sort_object.columnName === 'ship_by') {
        let sorted = this.state.orders.sort((a, b) => {
          a = moment(a.ship_by_date, 'YYYY-MM-DD');
          b = moment(b.ship_by_date, 'YYYY-MM-DD');
          let days = a.diff(b, 'days'); // 1

          if (sort_object.direction === 'desc') {
            return -days;
          } else {
            return days;
          }
        });
        this.setState({ sorting: true, orders: [] }, () => {
          this.setState({ orders: sorted });
        });
      } else {
        let sorted = this.state.orders.sort((a, b) => {
          a = moment(a.completed_at, 'YYYY-MM-DD');
          b = moment(b.completed_at, 'YYYY-MM-DD');
          let days = a.diff(b, 'days'); // 1

          if (sort_object.direction === 'desc') {
            return -days;
          } else {
            return days;
          }
        });
        this.setState({ sorting: true, orders: [] }, () => {
          this.setState({ orders: sorted });
        });
      }
    });
  };

  render() {
    if (this.state.loading || Object.keys(this.state.ordersFilters).length === 0)
      return (
        <div>
          <CircularProgress />
        </div>
      );

    return (
      <div>
        <div className="alert-container">
          {this.props.error ? <Alert severity="error"> Error! {this.props.error} </Alert> : null}
          {this.state.generateProductLabelError ? (
            <Alert severity="error">
              {' '}
              Error! Can't generate Labels for orders with no V6 link{' '}
            </Alert>
          ) : null}
          {this.state.shipstationSuccessMessage && !this.props.error ? (
            <Alert severity="success"> Shipping Orders Created Successfully </Alert>
          ) : null}
          {this.state.duplicateSuccessMessage && !this.props.error ? (
            <Alert severity="success"> Succefully, Marked as Duplicate Orders</Alert>
          ) : null}
          {this.state.generateProductLabelMessage && !this.props.error ? (
            <Alert severity="success">
              {' '}
              Labels are being generated, please wait 6-8 seconds for each label. If they do not
              show up, please contact the tech team.{' '}
            </Alert>
          ) : null}
        </div>

        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '5px',
              marginTop: '5px',
            }}
          >
            <Button
              style={{ margin: 3 }}
              onClick={this.handleClickActions}
              variant="contained"
              color="primary"
              disabled={this.state.selection.length === 0 ? true : false}
            >
              State Actions &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorElActions}
              keepMounted
              open={Boolean(this.state.anchorElActions)}
              onClose={this.handleCloseActions}
            >
              {this.renderMenuItems()}
            </Menu>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.createShipstationOrders()}
              variant="contained"
              color="secondary"
              disabled={this.state.selection.length === 0 ? true : false}
            >
              Create Shipping Orders &nbsp;{' '}
              {this.state.shipstationLoading ? <i class="fa fa-spin fa-spinner"></i> : null}
            </Button>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.generateProductLabel(false)}
              variant="contained"
              color="secondary"
              disabled={this.state.selection.length === 0 ? true : false}
            >
              Generate Product Label &nbsp;{' '}
              {this.state.generateBulkProductLabelsLoading ? (
                <i class="fa fa-spin fa-spinner"></i>
              ) : null}
            </Button>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.markAsDuplicate()}
              variant="contained"
              color="primary"
              disabled={this.state.selection.length === 0 ? true : false}
            >
              Mark as Duplicate &nbsp;{' '}
              {this.state.duplicateLoading ? <i class="fa fa-spin fa-spinner"></i> : null}
            </Button>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.setState({ createManualOrder: true })}
              variant="contained"
              color="primary"
            >
              Create Manual Order
            </Button>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.setState({ filterView: !this.state.filterView })}
              variant="contained"
              color="primary"
            >
              Filters &nbsp;{' '}
              {this.state.filterView ? (
                <i className="fa fa-caret-up"></i>
              ) : (
                <i className="fa fa-caret-down"></i>
              )}
            </Button>
          </div>

          {this.state.filterView ? (
            <form className="filter-form" onSubmit={this.handleSubmitFilters}>
              <div className="filter">
                <div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ordersFilters.refills}
                          onChange={this.handleChange}
                          name="refills"
                          color="primary"
                        />
                      }
                      label="Refills"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ordersFilters.newOrders}
                          onChange={this.handleChange}
                          name="newOrders"
                          color="primary"
                        />
                      }
                      label="New Orders"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.ordersFilters.all}
                          onChange={this.handleChange}
                          name="all"
                          color="primary"
                        />
                      }
                      label="All"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <TextField
                      name="platform_id"
                      label="Platform ID"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.ordersFilters.platform_id}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Autocomplete
                      options={this.state.partners}
                      getOptionLabel={option => option}
                      id="controlled-demo"
                      value={this.state.partnerName}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          this.handlePartnerNameChange(newValue);
                        } else {
                          this.handlePartnerNameChange('');
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Partner Name" variant="outlined" />
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <TextField
                      name="first_name"
                      label="First Name"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.ordersFilters.first_name}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <TextField
                      name="last_name"
                      label="Last Name"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.ordersFilters.last_name}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <TextField
                      label="Order Date Start Date"
                      type="date"
                      onChange={this.handleInputChange}
                      defaultValue={this.state.ordersFilters.start_date}
                      name="start_date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Order Date End Date"
                      type="date"
                      defaultValue={this.state.ordersFilters.end_date}
                      name="end_date"
                      onChange={this.handleInputChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button variant="contained" color="primary" type="submit">
                  Apply
                </Button>
              </div>
            </form>
          ) : null}

          <CreateManualOrder
            open={this.state.createManualOrder}
            handleClose={this.handleCreateOrderClose}
            actions={this.props.actions}
            loadData={this.loadData}
            ingredients={this.props.ingredients}
            partners={this.state.partners}
          />

          <EditOrderLink
            open={this.state.editOrderLinkView}
            handleClose={this.handleEditOrderLinkClose}
            actions={this.props.actions}
            loadData={this.loadData}
            orders={this.state.orders}
            orderId={this.state.editOrderId}
          />

          <PdfViewer
            open={this.state.pdfView}
            handleClose={this.handlePdfViewClose}
            url={this.state.pdfViewUrl}
          />
        </Paper>

        <Paper>
          <Grid rows={this.state.orders} columns={this.state.columns} getRowId={this.getRowId}>
            <this.StatusProvider for={['status_string']} />
            <this.LinkProvider for={['order_link']} />
            <this.FolderLinkProvider for={['folder_url']} />
            <this.ActionsProvider for={['actions']} />
            <this.CreateLabelProvider for={['create_label']} />

            <SelectionState
              selection={this.state.selection}
              onSelectionChange={this.handleSelectionChange}
            />
            <PagingState
              currentPage={this.state.currentPage}
              onCurrentPageChange={this.setCurrentPage}
              pageSize={this.state.pageSize}
            />
            <CustomPaging totalCount={this.state.rowCount} />
            <SortingState
              // sorting = {[{ columnName: 'ship_by_date'}]}
              onSortingChange={this.setSorting}
              columnExtensions={this.state.sortingStateColumnExtensions}
              // defaultSorting={[{ columnName: 'ship_by_date', direction: 'desc' }]}
            />

            <IntegratedSelection />
            <Table />
            <TableColumnResizing defaultColumnWidths={this.state.defaultColumnWidths} />
            <TableHeaderRow showSortingControls />

            <TableSelection highlightRow showSelectAll />
            <PagingPanel />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    totalRowCount: state.orders.totalRowCount,
    loading: state.orders.loading,
    error: state.orders.error,
    ordersFilters: state.orders.ordersFilters,
    totalRefillsCount: state.orders.totalRefillsCount,
    totalNewOrdersCount: state.orders.totalNewOrdersCount,
    ingredients: state.orders.ingredients,
    partners: state.orders.partners,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
