import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ShippingOrders from './ShippingOrders';

const SHIPPING_TABS = [
  {
    title: 'Finished Production',
    query: { status: 'completed' },
    nextStatus: 'Shipped',
    action: 'Advance',
  },
  {
    title: 'Shipped',
    query: { status: 'shipped' },
    nextStatus: 'Delivered',
    action: 'Advance',
  },
  {
    title: 'Delivered',
    query: { status: 'delivered' },
    nextStatus: 'Pending Approval',
    action: 'Reset',
  },
  {
    title: 'On Hold',
    query: { status: 'on_hold' },
    nextStatus: 'Pending Approval',
    action: 'Reset',
  },
  // {
  //   title: 'Cancelled',
  //   query: { status: 'cancelled' },
  //   nextStatus: 'Pending Approval',
  //   action: 'Reset',
  // },
  // {
  //   title: 'All',
  //   query: {},
  //   nextStatus: 'Next State',
  //   action: 'Advance',
  // },
];

class Index extends React.Component {
  render() {
    return (
      <div>
        <h1>Shipping Orders</h1>
        <Tabs>
          <TabList>
            {SHIPPING_TABS.map(t => (
              <Tab key={t.title} default={t.default}>
                {t.title}
              </Tab>
            ))}
          </TabList>
          {SHIPPING_TABS.map(t => (
            <TabPanel key={t.title}>
              <ShippingOrders
                nextStatus={t.nextStatus}
                options={{ query: t.query }}
                title={t.title}
                action={t.action}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default Index;
