let backendHost;
let frontendHost;

const hostname = window && window.location && window.location.hostname;

if (
  hostname === 'vlhub-client-staging.herokuapp.com' ||
  hostname === 'app.staging.getvitaminlab.com'
) {
  backendHost = 'https://vlhub-staging.herokuapp.com/v1';
  frontendHost = 'https://vlhub-client-staging.herokuapp.com';
} else if (
  hostname === 'vlhub-client-production.herokuapp.com' ||
  hostname === 'app.client.getvitaminlab.com'
) {
  backendHost = 'https://hub.getvitaminlab.com/v1';
  frontendHost = 'https://vlhub-client-production.herokuapp.com';
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'http://localhost:5000/v1';
  frontendHost = process.env.REACT_APP_FRONTEND_HOST || 'http://localhost:8080';
}

export const API_ROOT = `${backendHost}`;
export const CLIENT_ROOT = `${frontendHost}`;
export const ACCESS_TOKEN = 'auth_token';

export const STRIPE_API_KEY =
  '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';
