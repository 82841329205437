import React from 'react';
import { Provider } from 'react-redux';

import AppRouter from './routers/AppRouter';
import { authenticate } from './views/Users/users.module';
import store from './store';
import { GoogleApiProvider } from 'react-gapi';

import './App.css';

store.dispatch(authenticate());

function App() {
  return (
    <GoogleApiProvider clientId={process.env.REACT_APP_GUC_CLIENT_ID}>
      <Provider store={store}>
        <AppRouter />
      </Provider>
    </GoogleApiProvider>
  );
}

export default App;
