import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './accounts.module';

class Contractors extends Component {
  componentDidMount() {
    this.props.actions.loadContractors();
  }
  render() {
    const { contractors, loading, error } = this.props;
    if (error) return <div>Error! {error.message}</div>;
    if (loading) return <div>Loading..</div>;
    return (
      <div>
        <div>
          {contractors.map(contractor => (
            <div key={contractor._id}>
              <div>{contractor._id}</div>
              <div>{contractor.contractorName} </div>
              <div>{contractor.address}</div>
              <div></div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractors: state.contractors.contractors,
    loading: state.contractors.loading,
    error: state.contractors.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractors);
