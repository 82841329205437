const ORDER_TABS = [
  {
    title: 'Awaiting Production',
    query: { status: 'pending_approval' },
    nextStatus: 'production',
    action: 'Advance',
  },
  {
    title: 'Production',
    query: { status: 'production' },
    nextStatus: 'Completed Production',
    action: 'Advance',
  },
  {
    title: 'Finished Production',
    query: { status: 'completed' },
    nextStatus: 'Shipped',
    action: 'Advance',
  },
  {
    title: 'Shipped',
    query: { status: 'shipped' },
    nextStatus: 'Delivered',
    action: 'Advance',
  },
  {
    title: 'Delivered',
    query: { status: 'delivered' },
    nextStatus: 'Pending Approval',
    action: 'Reset',
  },
  {
    title: 'On Hold',
    query: { status: 'on_hold' },
    nextStatus: 'Pending Approval',
    action: 'Reset',
  },
  {
    title: 'Cancelled',
    query: { status: 'cancelled' },
    nextStatus: 'Pending Approval',
    action: 'Reset',
  },
  // {
  //   title: 'All',
  //   query: {},
  //   nextStatus: 'Next State',
  //   action: 'Advance',
  // },
];

export default ORDER_TABS;
