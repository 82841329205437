import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlask, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import HeaderBar from './HeaderBar';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 700,
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
}));

export default function EventLog(props) {
  const classes = useStyles();
  let events = props.events;
  return (
    <List className={classes.root} subheader={<li />}>
      <HeaderBar title={'Order Event Log'} />
      {events.map(orderEvent => (
        <div key={orderEvent.id}>
          <ListItem key={orderEvent.description}>
            {orderEvent.eventable_type === 'Order' ? (
              <FontAwesomeIcon icon={faExchangeAlt} fixedWidth size="2x" />
            ) : (
              <FontAwesomeIcon icon={faFlask} fixedWidth size="2x" />
            )}
            <ListItemText
              primary={orderEvent.description}
              secondary={`${orderEvent.user.email} - ${moment(orderEvent.created_at).fromNow()}`}
            />
          </ListItem>
          <Divider />
        </div>
      ))}
    </List>
  );
}
