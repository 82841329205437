import { hubApi } from '../../../middleware/api';
import { reset } from 'redux-form';

//GET orderComments constants
const GET_ORDER_COMMENTS_BEGIN = 'GET_ORDER_COMMENTS_BEGIN';
const GET_ORDER_COMMENTS_SUCCESS = 'GET_ORDER_COMMENTS_SUCCESS';
const GET_ORDER_COMMENTS_FAILURE = 'GET_ORDER_COMMENTS_FAILURE';

//POST orderComment constants
const POST_ORDER_COMMENT_BEGIN = 'POST_ORDER_COMMENT_BEGIN';
const POST_ORDER_COMMENT_SUCCESS = 'POST_ORDER_COMMENT_SUCCESS';
const POST_ORDER_COMMENT_FAILURE = 'POST_ORDER_COMMENT_FAILURE';
//PATCH orderComment constants
const PATCH_ORDER_COMMENT_BEGIN = 'PATCH_ORDER_COMMENT_BEGIN';
const PATCH_ORDER_COMMENT_SUCCESS = 'PATCH_ORDER_COMMENT_SUCCESS';
const PATCH_ORDER_COMMENT_FAILURE = 'PATCH_ORDER_COMMENT_FAILURE';
//DELETE orderComment constants
const DELETE_ORDER_COMMENT_BEGIN = 'DELETE_ORDER_COMMENT_BEGIN';
const DELETE_ORDER_COMMENT_SUCCESS = 'DELETE_ORDER_COMMENT_SUCCESS';
const DELETE_ORDER_COMMENT_FAILURE = 'DELETE_ORDER_COMMENT_FAILURE';

const initialState = {
  orderComments: [],
  orderComment: {},
  loading: false,
  error: null,
};

//GET order comments action creators
const loadOrderCommentsBegin = () => ({
  type: GET_ORDER_COMMENTS_BEGIN,
  loading: true,
});

const loadOrderCommentsSuccess = orderComments => ({
  type: GET_ORDER_COMMENTS_SUCCESS,
  payload: orderComments,
  loading: false,
});

const loadOrderCommentsFailure = error => ({
  type: GET_ORDER_COMMENTS_FAILURE,
  error,
  loading: false,
});

//POST orderComment create action creators
const createOrderCommentBegin = () => ({
  type: POST_ORDER_COMMENT_BEGIN,
  loading: true,
});

const createOrderCommentSuccess = orderComments => ({
  type: POST_ORDER_COMMENT_SUCCESS,
  payload: orderComments,
  loading: false,
});

const createOrderCommentFailure = error => ({
  type: POST_ORDER_COMMENT_FAILURE,
  payload: error,
  loading: false,
});

//PATCH orderComment create action creators
const updateOrderCommentBegin = () => ({
  type: PATCH_ORDER_COMMENT_BEGIN,
});

const updateOrderCommentSuccess = orderComments => ({
  type: PATCH_ORDER_COMMENT_SUCCESS,
  payload: orderComments,
});

const updateOrderCommentFailure = error => ({
  type: PATCH_ORDER_COMMENT_FAILURE,
  payload: error,
});

//PATCH orderComment create action creators
const deleteOrderCommentBegin = () => ({
  type: DELETE_ORDER_COMMENT_BEGIN,
});

const deleteOrderCommentSuccess = orderComments => ({
  type: DELETE_ORDER_COMMENT_SUCCESS,
  payload: orderComments,
});

const deleteOrderCommentFailure = error => ({
  type: DELETE_ORDER_COMMENT_FAILURE,
  payload: error,
});
//GET orderComments actions

export const loadOrderComments = (orderId, options) => async dispatch => {
  try {
    dispatch(loadOrderCommentsBegin());
    const request = await hubApi(`/orders/${orderId}/order_comments`, options);
    if (request.error) {
      return dispatch(loadOrderCommentsFailure(request));
    }
    dispatch(loadOrderCommentsSuccess(request));
  } catch (e) {
    return loadOrderCommentsFailure(e);
  }
};

//POST orderComment actions
export const createOrderComment = data => async dispatch => {
  dispatch(createOrderCommentBegin());
  try {
    const request = await hubApi(`orders/${data.id}/order_comments`, {
      method: 'POST',
      body: {
        comment: data.comment,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(createOrderCommentFailure(request.error))
      : dispatch(createOrderCommentSuccess(request));
    dispatch(reset('orderCommentsForm'));
  } catch (e) {
    dispatch(createOrderCommentFailure(e));
  }
};

//PATCH orderComment actions
export const updateOrderComment = (id, data) => async dispatch => {
  dispatch(updateOrderCommentBegin());
  try {
    const orderComment = await hubApi(`/orderComments/${id}`, {
      method: 'PATCH',
      body: {
        ...data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    orderComment.error
      ? dispatch(updateOrderCommentFailure(orderComment.error))
      : dispatch(updateOrderCommentSuccess(orderComment));
  } catch (e) {
    dispatch(updateOrderCommentFailure(e));
  }
};

//GET orderComment actions
export const deleteOrderComment = id => async dispatch => {
  try {
    dispatch(deleteOrderCommentBegin());
    await hubApi(`/orderComments/${id}`, {
      method: 'DELETE',
    });
    dispatch(deleteOrderCommentSuccess(id));
    return id;
  } catch (e) {
    return deleteOrderCommentFailure(e);
  }
};

export const actions = {
  loadOrderComments,
  createOrderComment,
  updateOrderComment,
  deleteOrderComment,
};

//reducers
export default function observationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORDER_COMMENTS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_ORDER_COMMENTS_SUCCESS:
      return {
        ...state,
        orderComments: action.payload,
        loading: false,
      };
    case GET_ORDER_COMMENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        orderComments: [],
        loading: false,
      };
    case POST_ORDER_COMMENT_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        orderComments: action.payload,
        loading: false,
      };
    case POST_ORDER_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        payload: {},
        loading: false,
      };
    case PATCH_ORDER_COMMENT_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PATCH_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        orderComments: action.payload.orderComments,
        loading: false,
      };
    case PATCH_ORDER_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        payload: {},
        loading: false,
      };
    case DELETE_ORDER_COMMENT_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_ORDER_COMMENT_SUCCESS:
      return {
        ...state,
        payload: action.payload.orderComments,
        loading: false,
      };
    case DELETE_ORDER_COMMENT_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        payload: {},
        loading: false,
      };
    default:
      return state;
  }
}
