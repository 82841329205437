const ORDER_STATES = [
  {
    title: 'Awaiting Production',
    status: 'pending_approval',
  },
  {
    title: 'Production',
    status: 'production',
  },
  {
    title: 'Finished Production',
    status: 'completed',
  },
  {
    title: 'Shipped',
    status: 'shipped',
  },
  {
    title: 'Delivered',
    status: 'delivered',
  },
  {
    title: 'On Hold',
    status: 'on_hold',
  },
  {
    title: 'Cancelled',
    status: 'cancelled',
  },
];

export default ORDER_STATES;
