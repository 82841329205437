import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import LabOrders from './LabOrders';

const LAB_TABS = [
  {
    title: 'Awaiting Action',
    query: { lab_state: 'awaiting_action' },
  },
  {
    title: 'Weighing',
    query: { lab_state: 'weighing' },
  },
  {
    title: 'Weighing Complete',
    query: { lab_state: 'weighing_complete' },
  },
  {
    title: 'Encapsulation',
    query: { lab_state: 'encapsulation' },
  },
  {
    title: 'QA Hold',
    query: { lab_state: 'qa_hold' },
  },
  // {
  //   title: 'Shipping',
  //   query: { lab_state: 'shipping' },
  //   nextStatus: 'Awaiting Action',
  //   action: 'Reset',
  // },
  {
    title: 'Waiting For Stock',
    query: { lab_state: 'waiting_for_stock' },
  },
  // {
  //   title: 'On Hold',
  //   query: { lab_state: 'on_hold' },
  // },
  // {
  //   title: 'Cancelled',
  //   query: { lab_state: 'cancelled' },
  // },
];

class Index extends React.Component {
  render() {
    return (
      <div>
        <h1>Lab Orders</h1>
        <Tabs>
          <TabList>
            {LAB_TABS.map(t => (
              <Tab key={t.title} default={t.default}>
                {t.title}
              </Tab>
            ))}
          </TabList>
          {LAB_TABS.map(t => (
            <TabPanel key={t.title}>
              <LabOrders
                nextStatus={t.nextStatus}
                options={{ query: t.query }}
                title={t.title}
                action={t.action}
                tabs={LAB_TABS}
              />
            </TabPanel>
          ))}
        </Tabs>
      </div>
    );
  }
}

export default Index;
