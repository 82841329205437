import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import ORDER_STATES from '../constants/orderStates';
import { TextField, Select, Button, MenuItem, InputLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const renderTextField = ({ label, input }) => (
  <TextField
    style={{ marginBottom: 10, width: '100%' }}
    label={label}
    placeholder={label}
    {...input}
  />
);

const renderDateField = ({ label, input }) => (
  <TextField
    type="date"
    style={{ marginBottom: 10, width: '100%' }}
    label={label}
    placeholder={label}
    {...input}
    InputLabelProps={{
      shrink: true,
    }}
  />
);

const renderSelectStateField = ({ label, input }) => (
  <div>
    <InputLabel>{label}</InputLabel>
    <Select
      style={{ marginBottom: 10, width: '100%' }}
      label={label}
      placeholder={label}
      {...input}
    >
      {ORDER_STATES.map(state => (
        <MenuItem key={state.title} value={state.status}>
          {state.title}
        </MenuItem>
      ))}
    </Select>
  </div>
);

const renderSelectRefillField = ({ label, input }) => (
  <div>
    <InputLabel>{label}</InputLabel>
    <Select
      style={{ marginBottom: 10, width: '100%' }}
      label={label}
      placeholder={label}
      {...input}
    >
      <MenuItem value="true">true</MenuItem>
      <MenuItem value="false">false</MenuItem>
    </Select>
  </div>
);

let EditOrderForm = props => {
  const { handleSubmit } = props;
  const [partner, setPartner] = useState(props.partner);

  const renderAutoSelectField = ({ label, partnersList, input }) => (
    <div>
      <Autocomplete
        options={partnersList}
        getOptionLabel={option => option}
        value={partner}
        onChange={(event, newValue) => {
          if (newValue !== null) {
            setPartner(newValue);
            props.handlePartnerChange(newValue);
          } else {
            setPartner('');
            props.handlePartnerChange('');
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            style={{ width: '100%', marginBottom: 10 }}
            {...input}
          />
        )}
      />
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      <Field component={renderTextField} name="first_name" label="First Name" />
      <Field component={renderTextField} name="last_name" label="Last Name" />
      <Field
        component={renderAutoSelectField}
        name="partner_name"
        partnersList={props.partnersList}
        label="Order Partner"
      />

      <Field component={renderTextField} name="address" label="Address 1" />
      <Field component={renderTextField} name="address2" label="Address 2" />
      <Field component={renderTextField} name="city" label="City" />
      <Field component={renderTextField} name="zip" label="Zip Code/Postal Code" />
      <Field component={renderTextField} name="country" label="Country" />
      <Field component={renderDateField} name="order_date" label="Order Date" />
      <Field component={renderTextField} name="order_link" label="V6 Order Link" />
      <Field
        component={renderSelectRefillField}
        name="refill"
        label="Is this order a refill (true = yes, false = no)"
      />
      <Field component={renderSelectStateField} name="status" label="Status" />
      <Button variant="contained" color="primary" type="submit">
        Update Order Info
      </Button>
    </form>
  );
};

EditOrderForm = reduxForm({
  form: 'editOrderForm',
})(EditOrderForm);

export default EditOrderForm;
