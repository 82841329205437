const ROLES = {
  employee: 'Employee',
  contractor: 'Contractor',
  supervisor: 'Supervisor',
  admin: 'Admin',
  manager: 'Manager',
  superUser: 'Super User',
};

export default ROLES;
