import { hubApi } from '../../middleware/api';
//GET partners constants
const GET_PARTNERS_BEGIN = 'GET_PARTNERS_BEGIN';
const GET_PARTNERS_SUCCESS = 'GET_PARTNERS_SUCCESS';
const GET_PARTNERS_FAILURE = 'GET_PARTNERS_FAILURE';

//GET partner constants
const GET_PARTNER_BEGIN = 'GET_PARTNER_BEGIN';
const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS';
const GET_PARTNER_FAILURE = 'GET_PARTNER_FAILURE';

//POST Base Order constants
const POST_BASE_ORDER_BEGIN = 'POST_BASE_ORDER_BEGIN';
const POST_BASE_ORDER_SUCCESS = 'POST_BASE_ORDER_SUCCESS';
const POST_BASE_ORDER_FAILURE = 'POST_BASE_ORDER_FAILURE';

//POST partner constants
const POST_PARTNER_BEGIN = 'POST_PARTNER_BEGIN';
const POST_PARTNER_SUCCESS = 'POST_PARTNER_SUCCESS';
const POST_PARTNER_FAILURE = 'POST_PARTNER_FAILURE';

//PATCH partner constants
const PATCH_PARTNER_BEGIN = 'PATCH_PARTNER_BEGIN';
const PATCH_PARTNER_SUCCESS = 'PATCH_PARTNER_SUCCESS';
const PATCH_PARTNER_FAILURE = 'PATCH_PARTNER_FAILURE';

//DELETE partner constants
// const DELETE_PARTNER_BEGIN = 'DELETE_PARTNER_BEGIN';
// const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS';
// const DELETE_PARTNER_FAILURE = 'DELETE_PARTNER_FAILURE';

const initialState = {
  partners: [],
  partner: {},
  base_order: {},
  loading: false,
  error: null,
};

//GET partners list action creators
const loadPartnersBegin = () => ({
  type: GET_PARTNERS_BEGIN,
});
const loadPartnersSuccess = partners => ({
  type: GET_PARTNERS_SUCCESS,
  payload: partners,
});

const loadPartnersFailure = error => ({
  type: GET_PARTNERS_FAILURE,
  payload: error,
});

//GET partner list action creators
const loadPartnerBegin = () => ({
  type: GET_PARTNER_BEGIN,
});
const loadPartnerSuccess = partner => ({
  type: GET_PARTNER_SUCCESS,
  payload: partner,
});

const loadPartnerFailure = error => ({
  type: GET_PARTNER_FAILURE,
  payload: error,
});

//POST base order create action creators
const createBaseOrderBegin = () => ({
  type: POST_BASE_ORDER_BEGIN,
});

const createBaseOrderSuccess = order => ({
  type: POST_BASE_ORDER_SUCCESS,
  payload: order,
});

const createBaseOrderFailure = error => ({
  type: POST_BASE_ORDER_FAILURE,
  payload: error,
});

//POST partner create action creators
const createPartnerBegin = () => ({
  type: POST_PARTNER_BEGIN,
});

const createPartnerSuccess = partner => ({
  type: POST_PARTNER_SUCCESS,
  payload: partner,
});

const createPartnerFailure = error => ({
  type: POST_PARTNER_FAILURE,
  payload: error,
});

//PATCH partner create action creators
const updatePartnerBegin = () => ({
  type: PATCH_PARTNER_BEGIN,
});

const updatePartnerSuccess = facility => ({
  type: PATCH_PARTNER_SUCCESS,
  payload: facility,
});

const updatePartnerFailure = error => ({
  type: PATCH_PARTNER_FAILURE,
  payload: error,
});

//DELETE facility action creators
// const deletePartnerBegin = () => ({
//   type: DELETE_PARTNER_BEGIN,
// });

// const deletePartnerSuccess = partners => ({
//   type: DELETE_PARTNER_SUCCESS,
//   payload: partners,
// });

// const deletePartnerFailure = error => ({
//   type: DELETE_PARTNER_FAILURE,
//   payload: error,
// });

//GET facilities actions
export const loadPartners = () => async dispatch => {
  try {
    dispatch(loadPartnersBegin());
    const request = await hubApi(`/partners`);
    if (request.error) {
      return dispatch(loadPartnersFailure(request));
    }
    dispatch(loadPartnersSuccess(request));
  } catch (e) {
    return dispatch(loadPartnersFailure(e));
  }
};

//GET Partner actions
export const loadPartner = id => async dispatch => {
  try {
    dispatch(loadPartnerBegin());
    const request = await hubApi(`/partners/${id}`);
    if (request.error) {
      return dispatch(loadPartnerFailure(request));
    }
    dispatch(loadPartnerSuccess(request));
  } catch (e) {
    return dispatch(loadPartnerFailure(e));
  }
};

//POST Base Order actions
export const createBaseOrder = data => async dispatch => {
  dispatch(createBaseOrderBegin());
  try {
    const request = await hubApi('/base_orders', {
      method: 'POST',
      body: data,
    });
    request.error
      ? dispatch(createBaseOrderFailure(request.error))
      : dispatch(createBaseOrderSuccess(request));
  } catch (e) {
    dispatch(createBaseOrderFailure(e));
  }
};

//POST partner actions
export const createPartner = data => async dispatch => {
  dispatch(createPartnerBegin());
  try {
    const request = await hubApi('/partners', {
      method: 'POST',
      body: { partner: data },
    });
    request.error
      ? dispatch(createPartnerFailure(request.error))
      : dispatch(createPartnerSuccess(request));
  } catch (e) {
    dispatch(createPartnerFailure(e));
  }
};
//PATCH partner actions
export const updatePartner = (id, data) => async dispatch => {
  dispatch(updatePartnerBegin());
  try {
    const request = await hubApi(`/partners/${id}`, {
      method: 'PATCH',
      body: {
        partner: data,
      },
    });
    request.error
      ? dispatch(updatePartnerFailure(request.error))
      : dispatch(updatePartnerSuccess(request));
  } catch (e) {
    dispatch(updatePartnerFailure(e));
  }
};

//DELETE partner actions
// export const deletePartner = id => async dispatch => {
//   try {
//     dispatch(deletePartnerBegin());
//     let partners = await hubApi(`/partners/${id}`, {
//       method: 'DELETE',
//     });
//     dispatch(deletePartnerSuccess(partners));
//     return id;
//   } catch (e) {
//     return deletePartnerFailure(e);
//   }
// };

export const actions = {
  loadPartners,
  loadPartner,
  updatePartner,
  createPartner,
  createBaseOrder,
};

//reducers
export default function partnerReducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case GET_PARTNERS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_PARTNERS_SUCCESS:
      return {
        ...state,
        partners: action.payload,
        loading: false,
      };
    case GET_PARTNERS_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        partners: [],
        loading: false,
      };
    case GET_PARTNER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_PARTNER_SUCCESS:
      return {
        ...state,
        partner: action.payload,
        loading: false,
      };
    case GET_PARTNER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        partner: {},
        loading: false,
      };

    case POST_BASE_ORDER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_BASE_ORDER_SUCCESS:
      return {
        ...state,
        base_order: action.payload,
        loading: false,
      };
    case POST_BASE_ORDER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        base_order: {},
        loading: false,
      };

    case POST_PARTNER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_PARTNER_SUCCESS:
      return {
        ...state,
        partner: action.payload,
        loading: false,
      };
    case POST_PARTNER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        partner: {},
        loading: false,
      };
    case PATCH_PARTNER_BEGIN:
      return {
        ...state,
        error: null,
      };
    case PATCH_PARTNER_SUCCESS:
      return {
        ...state,
        partner: action.payload,
        error: null,
      };
    case PATCH_PARTNER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        partner: {},
      };
    // case DELETE_PARTNER_BEGIN:
    //   return {
    //     ...state,
    //     error: null,
    //     loading: true,
    //   };
    // case DELETE_PARTNER_SUCCESS:
    //   return {
    //     ...state,
    //     partners: action.payload.facilities,
    //     loading: false,
    //   };
    // case DELETE_PARTNER_FAILURE:
    //   error = action.payload.data || action.payload.message;
    //   return {
    //     ...state,
    //     error: error,
    //     partners: [],
    //     loading: false,
    //   };
    default:
      return state;
  }
}
