import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Container from '@material-ui/core/Container';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    width: '25%',
  },
});

export default function DashboardTable(props) {
  const classes = useStyles();
  const rows = props.orders;
  return (
    <div component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell} align="center">
              Platform Id
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              First Name
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Last Name
            </TableCell>
            <TableCell className={classes.tableCell} align="center">
              Email
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(row => (
            <TableRow key={row.id}>
              <TableCell className={classes.tableCell} align="center">
                {row.platform_id}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {row.first_name}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {row.last_name}
              </TableCell>
              <TableCell className={classes.tableCell} align="center">
                {row.email}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
