import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as ordersActions } from './../Orders/orders.module';
import PdfViewer from './../Orders/components/PdfViewer';
// import { actions as usersManagementActions } from './../UsersManagement/usersmanagement.module';
// import { styles } from '../Orders/styles/orderStyles';
import DownloadExcel from './downloadExcel';
import BulkDownloadExcel from './bulkDownloadExcel';

// import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import moment from 'moment';

import {
  SelectionState,
  IntegratedSelection,
  PagingState,
  CustomPaging,
  DataTypeProvider,
  SortingState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  PagingPanel,
} from '@devexpress/dx-react-grid-material-ui';

import MuiAlert from '@material-ui/lab/Alert';

import { CircularProgress, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

import DescriptionIcon from '@material-ui/icons/Description';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import LabelIcon from '@material-ui/icons/Label';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class LabOrders extends React.Component {
  state = {
    labOrders: [],
    selection: [],
    users: [],
    labState: null,
    columns: [
      {
        name: 'lot_number',
        title: 'Lot Number',
      },
      { name: 'name', title: 'Name', getCellValue: row => row.first_name + ' ' + row.last_name },
      { name: 'partner', title: 'Partner' },
      { name: 'completed_at', title: 'Completed At' },
      { name: 'ship_by_date', title: 'Ship By' },
      { name: 'order_link', title: 'Order Link' },
      { name: 'folder_url', title: 'Folder/Label Link' },
      {
        name: 'printed',
        title: 'Downloaded',
        getCellValue: row =>
          row.printed ? (
            <i className="fa fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa fa-times" style={{ color: 'red' }}></i>
          ),
      },
      {
        name: 'print_check',
        title: 'Printed',
        getCellValue: row =>
          row.print_check ? (
            <i className="fa fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa fa-times" style={{ color: 'red' }}></i>
          ),
      },
      {
        name: 'sent_to_weighing',
        title: 'Sent To Dispenser',
        getCellValue: row =>
          row.sent_to_weighing ? (
            <i className="fa fa-check" style={{ color: 'green' }}></i>
          ) : (
            <i className="fa fa-times" style={{ color: 'red' }}></i>
          ),
      },
      {
        name: 'actions',
        title: 'Actions',
        getCellValue: row =>
          row.id ? (
            <Link to={`/orders/${row.id}`}>
              <Button variant="outlined" size="small" color="primary">
                view
              </Button>
            </Link>
          ) : (
            ''
          ),
      },
    ],
    anchorEl: null,
    printAnchorEl: null,
    labOrdersFilters: {},
    openDialog: false,
    actionId: null,
    dispenserMessage: null,
    dispenserSuccessMessage: null,
    dispenserLoading: false,
    totalCount: 0,
    pageSize: 50,
    currentPage: 0,
    lastQuery: null,
    loading: false,
    filterView: true,
    partners: [],
    downloadErrorMessage: null,
    pdfView: false,
    pdfViewUrl: null,
    sortingStateColumnExtensions: [
      { columnName: 'lot_number', sortingEnabled: false },
      { columnName: 'name', sortingEnabled: false },
      { columnName: 'partner', sortingEnabled: false },
      { columnName: 'completed_at', sortingEnabled: true },
      { columnName: 'ship_by_date', sortingEnabled: true },
      { columnName: 'order_link', sortingEnabled: false },
      { columnName: 'folder_url', sortingEnabled: false },
      { columnName: 'print_check', sortingEnabled: false },
      { columnName: 'sent_to_weighing', sortingEnabled: false },
      { columnName: 'actions', sortingEnabled: false },
    ],
    sorting: false,
  };

  componentDidMount() {
    this.setState(
      {
        labOrdersFilters: this.props.labOrdersFilters,
        labState: this.props.options.query.lab_state,
      },
      () => {
        // this.fetchUsers();
        this.loadData();
      },
    );
  }

  componentDidUpdate() {
    if (this.props.labOrders !== this.state.labOrders && !this.state.sorting) {
      this.setState({ labOrders: this.props.labOrders }, this.handleCloseAnchor);
    }
  }

  LinkFormatter = props => (
    <span>
      <a
        href={props.value}
        target="_blank"
        rel="noreferrer"
        style={{ color: 'grey', fontSize: 16 }}
      >
        {props.value ? <DescriptionIcon /> : null}
      </a>
      &nbsp;&nbsp;
      <span style={{ color: 'grey', cursor: 'pointer', fontSize: 16 }}>
        {props.value ? (
          <DownloadExcel
            link={props.value}
            id={props.row.id}
            options={this.props.options}
            name={props.row.first_name + ' ' + props.row.last_name}
            partner={props.row.partner}
            date={props.row.completed_at}
            setGoogleErrorMessage={this.setGoogleErrorMessage}
          />
        ) : null}
      </span>
    </span>
  );

  LinkProvider = props =>
    props.value !== null ? (
      <DataTypeProvider formatterComponent={this.LinkFormatter} {...props} props={props} />
    ) : null;

  // FolderLinkFormatter = props => (
  //   <span>
  //     <a
  //       href={props.value}
  //       target="_blank"
  //       rel="noreferrer"
  //       style={{ color: 'grey', fontSize: 16 }}
  //     >
  //       {props.value ? <FolderOpenIcon /> : null}
  //     </a>
  //   </span>
  // );

  FolderLinkFormatter = props => {
    return (
      <span>
        {props.value ? (
          <a href={props.value} target="_blank" style={{ color: 'grey' }} rel="noreferrer">
            <FolderOpenIcon />
          </a>
        ) : null}
        &nbsp;
        {props.row.label_url ? (
          <span
            onClick={() => this.setState({ pdfView: true, pdfViewUrl: props.row.label_url })}
            style={{ color: 'grey', cursor: 'pointer' }}
          >
            <LabelIcon />
          </span>
        ) : null}
      </span>
    );
  };

  FolderLinkProvider = props =>
    props.value !== null ? (
      <DataTypeProvider formatterComponent={this.FolderLinkFormatter} {...props} props={props} />
    ) : null;

  setGoogleErrorMessage = message => {
    if (!this.state.downloadErrorMessage) {
      this.setState({ downloadErrorMessage: message });
    } else {
      this.setState({ downloadErrorMessage: this.state.downloadErrorMessage + '<br/> ' + message });
    }

    setTimeout(() => {
      this.setState({ downloadErrorMessage: null });
    }, 15000);
  };

  handleChange = async (event, type) => {
    let obj = {
      printed: this.state.labOrdersFilters.printed,
      print_check: this.state.labOrdersFilters.print_check,
      sent_to_weighing: this.state.labOrdersFilters.sent_to_weighing,
      partner_name: this.state.labOrdersFilters.partner_name,
      start_date: this.state.labOrdersFilters.start_date,
      end_date: this.state.labOrdersFilters.end_date,
      first_name: this.state.labOrdersFilters.first_name,
      last_name: this.state.labOrdersFilters.last_name,
      lot_number: this.state.labOrdersFilters.lot_number,
    };
    if (type === 'switch') {
      obj[event.target.name] = event.target.checked;
    } else {
      obj[event.target.name] = event.target.value;
    }
    await this.props.actions.changeFilter(obj);
    this.setState({ labOrdersFilters: obj });
  };

  handlePartnerNameChange = async value => {
    let obj = {
      printed: this.state.labOrdersFilters.printed,
      print_check: this.state.labOrdersFilters.print_check,
      sent_to_weighing: this.state.labOrdersFilters.sent_to_weighing,
      partner_name: value,
      start_date: this.state.labOrdersFilters.start_date,
      end_date: this.state.labOrdersFilters.end_date,
      first_name: this.state.labOrdersFilters.first_name,
      last_name: this.state.labOrdersFilters.last_name,
      lot_number: this.state.labOrdersFilters.lot_number,
    };
    await this.props.actions.changeFilter(obj);
    this.setState({ labOrdersFilters: obj });
  };

  getQueryString = () => {
    let query = `take=${this.state.pageSize}&skip=${this.state.pageSize * this.state.currentPage}&`;
    query += `printed=${this.state.labOrdersFilters.printed}&print_check=${this.state.labOrdersFilters.print_check}&sent_to_weighing=${this.state.labOrdersFilters.sent_to_weighing}&`;
    query += `partner_name=${this.state.labOrdersFilters.partner_name}&start_date=${this.state.labOrdersFilters.start_date}&end_date=${this.state.labOrdersFilters.end_date}&`;
    query += `first_name=${this.state.labOrdersFilters.first_name}&last_name=${this.state.labOrdersFilters.last_name}&lot_number=${this.state.labOrdersFilters.lot_number}`;
    return query;
  };

  loadData = () => {
    this.setState({ sorting: false });
    const queryString = this.getQueryString();
    if (queryString !== this.state.lastQuery && !this.state.loading) {
      this.setState({ loading: true }, () => {
        this.fetchLabOrders();
      });
      this.setState({ lastQuery: queryString });
    }
  };

  // fetchUsers = () => {
  //   this.props.actions.loadUsers().then(() => {
  //     this.setState({ users: this.props.users }, this.fetchLabOrders);
  //   });
  // };

  fetchLabOrders = () => {
    let options = this.props.options;
    options.query.skip = this.state.pageSize * this.state.currentPage;
    options.query.take = this.state.pageSize;
    options.query.printed = this.state.labOrdersFilters.printed;
    options.query.print_check = this.state.labOrdersFilters.print_check;
    options.query.sent_to_weighing = this.state.labOrdersFilters.sent_to_weighing;
    options.query.partner_name = this.state.labOrdersFilters.partner_name;
    options.query.start_date = this.state.labOrdersFilters.start_date;
    options.query.end_date = this.state.labOrdersFilters.end_date;
    options.query.first_name = this.state.labOrdersFilters.first_name;
    options.query.last_name = this.state.labOrdersFilters.last_name;
    options.query.lot_number = this.state.labOrdersFilters.lot_number;
    this.props.actions.loadLabOrders(options).then(() => {
      this.setState({
        labOrders: this.props.labOrders,
        totalCount: this.props.totalRowCount,
        partners: this.props.partners,
        loading: false,
      });
    });
  };

  handleSelectionChange = ids => {
    this.setState({ selection: ids });
  };

  updateLabOrderStates = () => {
    this.props.actions.updateLabOrderStates(this.state.selection, this.state.status).then(() => {
      this.setState({ selection: [] });
      this.fetchLabOrders();
    });
  };

  updateOrderStates = () => {
    this.props.actions.updateOrderStates(this.state.selection, this.state.status).then(() => {
      this.setState({ selection: [] });
      this.fetchLabOrders();
    });
  };

  getRowId = row => row.id;

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseAnchor = () => {
    this.setState({ anchorEl: null });
  };

  handlePrintClick = event => {
    this.setState({ printAnchorEl: event.currentTarget });
  };

  handleClosePrintAnchor = () => {
    this.setState({ printAnchorEl: null });
  };

  changeFilters = filter => {
    this.props.actions.changeFilter(filter);
  };

  setCurrentPage = page => {
    this.setState({ currentPage: page }, this.loadData);
  };

  handleClickOpen(status) {
    this.setState({ openDialog: true, status: status }, this.handleCloseAnchor);
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  agree = () => {
    if (
      this.state.status === 'cancelled' ||
      this.state.status === 'on_hold' ||
      this.state.status === 'completed'
    ) {
      this.setState({ openDialog: false }, this.updateOrderStates);
    } else {
      this.setState({ openDialog: false }, this.updateLabOrderStates);
    }
  };

  disagree = () => {
    this.setState({ openDialog: false, actionId: null });
  };

  // googleAuth = () => {
  //   if (!this.props.access_token) {
  //     var pHeader = { algorithm: 'RS256' };

  //     var pClaim = {};
  //     pClaim.aud = 'https://www.googleapis.com/oauth2/v3/token';
  //     pClaim.scope = 'https://www.googleapis.com/auth/analytics.readonly';
  //     pClaim.iss = "vlhub-413@vlhub-278518.iam.gserviceaccount.com";

  //     var dt = new Date().getTime() / 1000;
  //     dt = Math.round(dt);
  //     pClaim.exp = dt + 3600;
  //     pClaim.iat = dt;

  //     const GOOGLE_PRIVATE_KEY = "-----BEGIN PRIVATE KEY-----\nMIIEvAIBADANBgkqhkiG9w0BAQEFAASCBKYwggSiAgEAAoIBAQCySNghiEh1i1+j\nZ3VJ68k90MnK7j8iMIuQwrb+aYxew8nS86lpHCBPilYxommMkFBXgj8ZxpPi5aT/\naN9ridRGnNbIIDI0risxherOpjt0M/Sed1R0tE29Qsj18MNOtsnHOCACo+/TG+Jo\nTx0K4vd1JNyZwJYwNSNDQa/1i8+EiT2McVkjt8aFADoMKAJyCzatfXOFDBWXzxND\n19pl5uzKm6RnKHxJSE8VgMtdd4ZprImuVuB8obeGH+yvOIOTZKxgI8bqXPqZSkV8\nYNLcTHW5yIkUFPLBSUhww/Q0sM7OPiJXOntg3kyGul0rbiXWXZ5Zn7I8bs2EbzUf\nuOz/3aCvAgMBAAECggEAK1hNf72QDE/x1Rr6p5m1SBuia/2HiOrvi6bjcbA8qe+E\n+o42AnGNo3j5IsEfU0bk2Zm3eK9QVipJ9rTLGOyeGPrZtzcorakopDYwU8STkNlu\n4DYX6PXLLrf1fuHlwhXrsc1B7xJnZNaTMZQGdhafXjIzlgf+G8NXiusF+jdfIRQJ\ni9w5GE+xMHwJOE/guddxZH1CVovZ5BVXYE98/VexwouhrY44l2mJL92FbSL/aJF+\n7D2g0y5Hq4VA2q6nRQirCTQAgW5AplWIRWW/fK6Y/DZlocbcPnzRy8Mkd5MpYg2M\nU/ayU+2GEjEF8k0HI4ltnjeSoZ4kAQQTijWV3L67zQKBgQD7REMBSJNPu7bOhr2M\n0RAFGxa7MjLM0AFy6y9uOZls1wmA24hBVndR5QLoraCFF9usvyoq8ToYLr4dizq1\n6+QabZYIpGTI/ne4ijseu3HjfDODJDJn8QFnJ4oKNvm6w5hCHwHd7aEjRlYIsCEI\nZHTtscfBNVAf6JTrfxREJ9YSQwKBgQC1pJ/79idz0bGyuTi7cYiHgZGhDdS0zneb\nAG3rKNhtdnf4c4kPQsM56ldBm1sfHUdvnLP7em/DPNtnjyHyQmtJ3a6tQnqXJ0iJ\nBtdKpJ23NInVzmTT0MD0Z3kRn9OthimTv+WLjL1A6AoIUeXGQ/0wU/Sv+niNww3o\nxbs5ZC+/JQKBgFKGsA863A9jEv6wKI+Yls9mf0fKi/7TDrbE4hYs2uRxmFB3SkBT\neust8Y+kf0VXebI6M0pZYoBQ0eZ4NTdNG+VGOvHptKSDV6UH2bUVUamqwGyBNgEH\nmjNcPC1wswMRfO+yqXVPuA4S6uz9lEviWrk7G+Wscf2Y8ViLq4dbIidrAoGAWq0P\n4GwFTeAEYT/tfkUy7tJTrJ6G7KuVMZ+JP7doM1zMjw6+AQxHAQZnAK1ZU7L+Ek2b\n25JHcKNUs+HUBGf7vygXJw7ku8g0gZMUroJ26RolJMKfus2fRWLSIWYz+Y/b+ZH2\nOS/M2jX7rCOWHN+b6SBAKglTXRFeRDFRQVkuLLECgYAQOc9OKz0R/31/qx8nxFFo\nZWSBCcfHumMV0vt0jZ7L34kRZE2peZch2Qo7Ij1n4ZFWojlOYrSLh+7xIJmzo+pB\nZUNzs+DPBzwB0bjmYdx3dV1KqpTnILco9AAj5ZN2/5UkxmRNnvyaXL2tKneTnhA1\nG+r5W22g5Pz7sZbDzS1gRQ==\n-----END PRIVATE KEY-----\n";

  //     var sJWS = jwt.sign(pClaim, GOOGLE_PRIVATE_KEY, pHeader);

  //     var XHR = new XMLHttpRequest();
  //     var urlEncodedData = '';
  //     var urlEncodedDataPairs = [];

  //     urlEncodedDataPairs.push(
  //       encodeURIComponent('grant_type') +
  //         '=' +
  //         encodeURIComponent('urn:ietf:params:oauth:grant-type:jwt-bearer'),
  //     );
  //     urlEncodedDataPairs.push(encodeURIComponent('assertion') + '=' + encodeURIComponent(sJWS));
  //     urlEncodedData = urlEncodedDataPairs.join('&').replace(/%20/g, '+');

  //     // We define what will happen if the data are successfully sent
  //     XHR.addEventListener('load', () => {
  //       var response = JSON.parse(XHR.responseText);
  //       var token = response['access_token'];
  //       this.props.actions.changeGoogleAccessToken(token);
  //     });

  //     // We define what will happen in case of error
  //     XHR.addEventListener('error', function(event) {
  //       console.log('Oops! Something went wrong.');
  //     });

  //     XHR.open('POST', 'https://www.googleapis.com/oauth2/v3/token');
  //     XHR.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
  //     XHR.send(urlEncodedData);
  //   }
  // };

  renderTabs() {
    return this.props.tabs.map(tab => {
      if (tab.title !== this.props.title) {
        return (
          <MenuItem key={tab.title} onClick={() => this.handleClickOpen(tab.query.lab_state)}>
            {' '}
            Transfer {this.state.selection.length} orders to {tab.title}
          </MenuItem>
        );
      }
      return null;
    });
  }

  updatePrintStatus = async status => {
    let ids = this.state.selection;
    await this.props.actions.updateDownloadedOrder(ids, status);
    await this.fetchLabOrders();
    await this.setState({ selection: [] });
    this.handleClosePrintAnchor();
  };

  sendToDispensar = async () => {
    this.setState({ dispenserMessage: null });
    let dispenserMessage = false;
    let ids = this.state.selection;
    this.state.labOrders.every(order => {
      if (ids.includes(order.id) && (!order.dispenser_available || order.sent_to_weighing)) {
        this.setState({
          dispenserMessage:
            'The order you are trying to send to the machine has either already been sent to dispenser or the partner is not yet eligible for dispensing. If you have any questions please contact your dev team',
        });
        setTimeout(() => {
          this.setState({ dispenserMessage: null });
        }, 5000);
        dispenserMessage = true;
        return false;
      }
      return true;
    });

    if (!dispenserMessage) {
      await this.setState({ dispenserLoading: true });
      await this.props.actions.sendToDispensar(ids);
      if (!this.props.error) {
        await this.fetchLabOrders();
        await this.setState({
          selection: [],
          dispenserSuccessMessage: `Orders successfully sent to dispenser`,
        });
        setTimeout(() => {
          this.setState({ dispenserSuccessMessage: null });
        }, 5000);
      }
      this.setState({ dispenserLoading: false });
    }
  };

  handleSubmitFilters = async event => {
    event.preventDefault();
    await this.props.actions.changeOrdersFilters(this.state.labOrdersFilters);
    await this.setState({ labOrders: [], currentPage: 0 });
    this.loadData();
  };

  handlePdfViewClose = () => {
    this.setState({ pdfView: false });
  };

  setSorting = value => {
    value.forEach(sort_object => {
      if (sort_object.columnName === 'ship_by_date') {
        let sorted = this.state.labOrders.sort((a, b) => {
          a = moment(a.ship_by_date, 'YYYY-MM-DD');
          b = moment(b.ship_by_date, 'YYYY-MM-DD');
          let days = a.diff(b, 'days'); // 1

          if (sort_object.direction === 'desc') {
            return -days;
          } else {
            return days;
          }
        });
        this.setState({ sorting: true, labOrders: [] }, () => {
          this.setState({ labOrders: sorted });
        });
      } else {
        let sorted = this.state.labOrders.sort((a, b) => {
          a = moment(a.completed_at, 'YYYY-MM-DD');
          b = moment(b.completed_at, 'YYYY-MM-DD');
          let days = a.diff(b, 'days'); // 1

          if (sort_object.direction === 'desc') {
            return -days;
          } else {
            return days;
          }
        });
        this.setState({ sorting: true, labOrders: [] }, () => {
          this.setState({ labOrders: sorted });
        });
      }
    });
  };

  render() {
    const { loading, error } = this.props;
    if (loading || Object.keys(this.state.labOrdersFilters).length === 0)
      return (
        <div>
          <CircularProgress />
        </div>
      );

    return (
      <div>
        <Dialog
          open={this.state.openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to carry out this action.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.disagree} color="primary">
              Disagree
            </Button>
            <Button onClick={this.agree} color="secondary">
              Agree
            </Button>
          </DialogActions>
        </Dialog>

        <div className="alert-container">
          {error ? <Alert severity="error"> Error! {error} </Alert> : null}
          {this.state.dispenserMessage ? (
            <Alert severity="error"> {this.state.dispenserMessage} </Alert>
          ) : null}
          {this.state.dispenserSuccessMessage ? (
            <Alert severity="success"> {this.state.dispenserSuccessMessage} </Alert>
          ) : null}
          {this.state.downloadErrorMessage ? (
            <Alert severity="error">
              <div dangerouslySetInnerHTML={{ __html: this.state.downloadErrorMessage }} />
            </Alert>
          ) : null}
        </div>

        <Paper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '5px',
              marginTop: '5px',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 3 }}
              disabled={!this.state.selection.length}
              onClick={this.handleClick}
              aria-controls="simple-menu"
              aria-haspopup="true"
            >
              State Actions &nbsp; <i className="fa fa-caret-down"></i>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleCloseAnchor}
            >
              {this.renderTabs()}
              <MenuItem onClick={() => this.handleClickOpen('completed')}>
                Transfer {this.state.selection.length} orders to Shipping
              </MenuItem>
              <MenuItem onClick={() => this.handleClickOpen('on_hold')}>
                Transfer {this.state.selection.length} orders to On Hold
              </MenuItem>
              <MenuItem onClick={() => this.handleClickOpen('cancelled')}>
                Transfer {this.state.selection.length} orders to Cancelled
              </MenuItem>
            </Menu>

            <Button
              variant="contained"
              color="secondary"
              disabled={!this.state.selection.length}
              style={{ margin: 3 }}
            >
              <BulkDownloadExcel
                selection={this.state.selection}
                labOrders={this.state.labOrders}
                options={this.props.options}
                setGoogleErrorMessage={this.setGoogleErrorMessage}
              />
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={this.handlePrintClick}
              disabled={!this.state.selection.length}
              style={{ margin: 3 }}
            >
              Print Options &nbsp; <i className="fa fa-caret-down"></i>
            </Button>
            <Menu
              id="simple-menu-print"
              anchorEl={this.state.printAnchorEl}
              keepMounted
              open={Boolean(this.state.printAnchorEl)}
              onClose={this.handleClosePrintAnchor}
            >
              <MenuItem onClick={() => this.updatePrintStatus(true)}>
                Mark {this.state.selection.length} Orders Printed
              </MenuItem>
              <MenuItem onClick={() => this.updatePrintStatus(false)}>
                Unmark {this.state.selection.length} Orders Printed
              </MenuItem>
            </Menu>

            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.selection.length || this.state.dispenserLoading}
              onClick={this.sendToDispensar}
              style={{ margin: 3 }}
            >
              &nbsp;{' '}
              {this.state.dispenserLoading
                ? `Sending ${this.state.selection.length} orders to Dispenser`
                : `Send to Dispenser`}
            </Button>

            <Button
              style={{ margin: 3 }}
              onClick={() => this.setState({ filterView: !this.state.filterView })}
              variant="contained"
              color="primary"
            >
              Filters &nbsp;{' '}
              {this.state.filterView ? (
                <i className="fa fa-caret-up"></i>
              ) : (
                <i className="fa fa-caret-down"></i>
              )}
            </Button>

            <PdfViewer
              open={this.state.pdfView}
              handleClose={this.handlePdfViewClose}
              url={this.state.pdfViewUrl}
            />
          </div>

          {this.state.filterView ? (
            <form className="filter-form" onSubmit={this.handleSubmitFilters}>
              <div className="filter">
                <div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.labOrdersFilters.printed}
                          onChange={e => this.handleChange(e, 'switch')}
                          name="printed"
                          color="primary"
                        />
                      }
                      label="Downloaded"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.labOrdersFilters.print_check}
                          onChange={e => this.handleChange(e, 'switch')}
                          name="print_check"
                          color="primary"
                        />
                      }
                      label="Printed"
                    />
                  </div>
                  <div>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.labOrdersFilters.sent_to_weighing}
                          onChange={e => this.handleChange(e, 'switch')}
                          name="sent_to_weighing"
                          color="primary"
                        />
                      }
                      label="Sent To Dispenser"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <TextField
                      name="lot_number"
                      label="Lot Number"
                      onChange={e => this.handleChange(e, 'input')}
                      defaultValue={this.state.labOrdersFilters.lot_number}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <Autocomplete
                      options={this.state.partners}
                      getOptionLabel={option => option}
                      id="controlled-demo"
                      value={this.state.labOrdersFilters.partner_name}
                      onChange={(event, newValue) => {
                        if (newValue !== null) {
                          this.handlePartnerNameChange(newValue);
                        } else {
                          this.handlePartnerNameChange('');
                        }
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Partner Name" variant="outlined" />
                      )}
                    />
                  </div>
                </div>

                <div>
                  <div>
                    <TextField
                      name="first_name"
                      label="First Name"
                      onChange={e => this.handleChange(e, 'input')}
                      defaultValue={this.state.labOrdersFilters.first_name}
                      variant="outlined"
                    />
                  </div>
                  <div>
                    <TextField
                      name="last_name"
                      label="Last Name"
                      onChange={e => this.handleChange(e, 'input')}
                      defaultValue={this.state.labOrdersFilters.last_name}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <TextField
                      label="Order Date Start Date"
                      type="date"
                      onChange={e => this.handleChange(e, 'input')}
                      defaultValue={this.state.labOrdersFilters.start_date}
                      name="start_date"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Order Date End Date"
                      type="date"
                      defaultValue={this.state.labOrdersFilters.end_date}
                      name="end_date"
                      onChange={e => this.handleChange(e, 'input')}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div>
                <Button variant="contained" color="primary" type="submit">
                  Apply
                </Button>
              </div>
            </form>
          ) : null}

          <Grid rows={this.state.labOrders} columns={this.state.columns} getRowId={this.getRowId}>
            <this.LinkProvider for={['order_link']} />
            <this.FolderLinkProvider for={['folder_url']} />
            <SelectionState
              selection={this.state.selection}
              onSelectionChange={this.handleSelectionChange}
            />
            <PagingState
              currentPage={this.state.currentPage}
              onCurrentPageChange={this.setCurrentPage}
              pageSize={this.state.pageSize}
            />
            <CustomPaging totalCount={this.state.totalCount} />
            <SortingState
              // sorting = {[{ columnName: 'ship_by_date'}]}
              onSortingChange={this.setSorting}
              columnExtensions={this.state.sortingStateColumnExtensions}
              // defaultSorting={[{ columnName: 'ship_by_date', direction: 'desc' }]}
            />
            {/* <FilteringState
              onFiltersChange={this.changeFilters}
              defaultFilters={this.state.defaultFilters}
              columnExtensions={this.state.filteringStateColumnExtensions}
            /> */}

            {/* <IntegratedSorting />
            <IntegratedFiltering /> */}
            <IntegratedSelection />
            <Table />
            {/* <TableFilterRow showFilterSelector /> */}

            <TableHeaderRow showSortingControls />
            <TableSelection highlightRow showSelectAll />
            <PagingPanel />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    labOrdersFilters: state.orders.labOrdersFilters,
    access_token: state.orders.googleAccessToken,
    labOrders: state.orders.labOrders,
    totalRowCount: state.orders.totalRowCount,
    loading: state.orders.loading,
    error: state.orders.error,
    partners: state.orders.partners,
    // users: state.users.users,s
    // userLoading: state.users.loading,
    // userError: state.users.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Object.assign({}, ordersActions), dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LabOrders);
