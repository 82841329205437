import fetch from 'isomorphic-fetch';
import qs from 'query-string';
import urljoin from 'url-join';
import { API_ROOT, ACCESS_TOKEN } from '../api-config';
import { getWithExpiry } from '../apiModules/getWithTtl';

const api = async (uri, options = {}) => {
  const { method = 'GET', body, headers = {}, query } = options;
  const url = query ? `${uri}?${qs.stringify(query)}` : uri;
  const isFormData = body instanceof FormData;

  const mergedHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: await getWithExpiry(ACCESS_TOKEN),
    ...headers,
  };
  if (isFormData) {
    delete mergedHeaders['Content-Type'];
  }

  const response = await fetch(url, {
    headers: mergedHeaders,
    method,
    body: isFormData ? body : JSON.stringify(body),
  });

  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  return response.json();
};
export const hubApi = (endpoint, options = {}) => {
  const url = API_ROOT;
  const joinedUrl = urljoin(url, endpoint);

  return api(joinedUrl, options);
};
