import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './accounts.module';

class Contractor extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.actions.loadContractor(id);
  }
  render() {
    const { contractor, error } = this.props;
    if (error) return <div>Error! {error.message}</div>;
    if (!contractor) return <div>Loading...</div>;
    return (
      <div>
        {contractor.contractorName}
        {contractor.address}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contractor: state.contractors.contractor,
    loading: state.contractors.loading,
    error: state.contractors.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Contractor);
