import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as usersManagementActions } from '../UsersManagement/usersmanagement.module';
import { actions as usersActions } from '../Users/users.module';

import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import { Button, TextField, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import vali from '../../middleware/vali';
import userValidationSchema from '../../lib/validationSchemas/userValidationSchema';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '40px',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
});

class Index extends React.Component {
  state = {
    id: null,
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    availableRoles: ['Developer', 'Lab', 'HR', 'Manager', 'Order Processing'],
    errors: {},
    loading: false,
    message: null,
  };

  componentDidMount() {
    this.fetchUser();
  }

  fetchUser = () => {
    this.props.actions.meFromToken(this.state.id).then(() => {
      this.setState({
        id: this.props.user.id,
        email: this.props.user.email,
        firstName: this.props.user.first_name,
        lastName: this.props.user.last_name,
        role: this.props.user.department,
      });
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    const { firstName, lastName, email, role, errors } = this.state;
    let error = vali.validate(
      {
        firstName,
        lastName,
        email,
        role,
      },
      userValidationSchema,
    );
    if (error) {
      errors[error.field] = error.message;
      this.setState({ errors });
      return;
    }

    this.setState({ loading: true }, () => {
      const payload = {
        first_name: firstName,
        email: email,
        last_name: lastName,
        department: role,
      };
      this.props.actions.updateUser(this.state.id, payload).then(() => {
        this.setState({ loading: false }, () => {
          if (!this.props.error) {
            this.setState({ message: 'Profile was successfully updated' }, () => {
              setTimeout(() => {
                this.setState({ message: null });
              }, 5000);
              this.fetchUser();
            });
          }
        });
      });
    });
  }

  handleInputChange = event => {
    const { value, name } = event.target;
    const { errors } = this.state;

    errors[name] = null;
    errors.page = null;

    this.setState({ [name]: value, errors });
  };

  render() {
    const { classes, error } = this.props;
    const { firstName, lastName, email, role, availableRoles, errors, message } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item md={12}>
          {error ? <Alert severity="error"> Error! {error} </Alert> : null}
          {message ? <Alert severity="success"> {message} </Alert> : null}
        </Grid>
        <Grid item md={6}>
          <div className={classes.contentBody}>
            <form className={classes.form} onSubmit={event => this.handleSubmit(event)}>
              <Typography className={classes.title} variant="h2">
                Edit Profile
              </Typography>

              <TextField
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                name="firstName"
                label="First Name"
                error={!!errors.firstName}
                helperText={errors.firstName}
                value={firstName}
                onChange={this.handleInputChange}
                variant="outlined"
              />

              <TextField
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                name="lastName"
                label="Last Name"
                error={!!errors.lastName}
                helperText={errors.lastName}
                value={lastName}
                onChange={this.handleInputChange}
                variant="outlined"
              />

              <TextField
                className={classes.textField}
                fullWidth
                style={{ marginBottom: 15 }}
                type="text"
                name="email"
                label="Email"
                error={!!errors.email}
                helperText={errors.email}
                value={email}
                onChange={this.handleInputChange}
                variant="outlined"
              />
              <TextField
                className={classes.textField}
                fullWidth
                select
                name="role"
                label="Select Role"
                value={role}
                onChange={this.handleInputChange}
                helperText={errors.role}
                variant="outlined"
              >
                {availableRoles.map(option => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
              <Button
                className={classes.signInButton}
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Edit Profile{' '}
                {this.state.loading ? (
                  <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
                ) : null}
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user.user,
    loading: state.user.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(Object.assign({}, usersManagementActions, usersActions), dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index));
