import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './usersmanagement.module';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import MuiAlert from '@material-ui/lab/Alert';

import { CircularProgress, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  header: {
    float: 'right',
    marginBottom: '15px',
  },

  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  initials: {
    width: '40px',
    height: '40px',
    lineHeight: '40px',
    background: '#eee',
    borderRadius: '40px',
    display: 'inline-block',
    textAlign: 'center',
  },
});

class Index extends React.Component {
  state = {
    users: [],
    openDialog: false,
    actionId: null,
  };

  handleClickOpen(id) {
    this.setState({ openDialog: true, actionId: id });
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  agree = () => {
    this.setState({ openDialog: false }, this.deleteTeamMember);
  };

  disagree = () => {
    this.setState({ openDialog: false, actionId: null });
  };

  componentDidMount() {
    this.fetchUsers();
  }

  deleteTeamMember = () => {
    this.props.actions.deleteUser(this.state.actionId).then(this.fetchUsers);
  };

  fetchUsers = () => {
    this.props.actions.loadUsers().then(() => {
      this.setState({ users: this.props.users });
    });
  };

  render() {
    if (this.props.current_user && this.props.current_user.user) {
      if (this.props.current_user.user.role === 'admin') {
        const { classes, loading, error } = this.props;
        if (error) return <Alert severity="error"> Error! {error} </Alert>;
        if (loading)
          return (
            <div className={classes.loading}>
              <CircularProgress />
            </div>
          );
        return (
          <div>
            <Dialog
              open={this.state.openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to carry out this action.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.disagree} color="primary">
                  Disagree
                </Button>
                <Button onClick={this.agree} color="secondary">
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
            <Link to={`/teams/create`} className={classes.header}>
              <Button variant="contained" color="primary">
                Create &nbsp; <i className="fas fa-edit"></i>
              </Button>
            </Link>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell>
                      <b>First Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Last Name</b>
                    </TableCell>
                    <TableCell>
                      <b>Email</b>
                    </TableCell>
                    <TableCell>
                      <b>Department</b>
                    </TableCell>
                    <TableCell>
                      <b>Role</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.users.map(user => (
                    <TableRow key={user.id}>
                      <TableCell>
                        {' '}
                        <span className={classes.initials}>{user.initials}</span>
                      </TableCell>
                      <TableCell>{user.first_name}</TableCell>
                      <TableCell>{user.last_name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.department}</TableCell>
                      <TableCell>{user.role}</TableCell>
                      <TableCell>
                        <Link to={`/teams/edit/${user.id}`}>
                          <Button variant="contained" size="small" color="default">
                            Edit &nbsp; <i className="fas fa-edit"></i>
                          </Button>
                        </Link>

                        <Button
                          variant="contained"
                          color="secondary"
                          size="small"
                          style={{ marginLeft: '8px' }}
                          onClick={() => this.handleClickOpen(user.id)}
                        >
                          Delete &nbsp; <i className="fa fa-trash"></i>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        );
      }
    }

    return (
      <div>
        <h1 style={{ fontSize: 48, fontWeight: 'bold', marginBottom: 0 }}>405 Not Allowed</h1>
        <p style={{ fontSize: 24, marginTop: 0 }}>You are not allowed to view this page.</p>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    users: state.users.users,
    loading: state.users.loading,
    error: state.users.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index));
