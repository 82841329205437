import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ORDER_TABS from './constants/orderTabs';
import Orders from './components/Orders';

class Index extends React.Component {
  render() {
    return (
      <Tabs>
        <TabList>
          <Tab>All Orders</Tab>
        </TabList>

        <TabPanel>
          <Tabs>
            <TabList>
              {ORDER_TABS.map(t => (
                <Tab key={t.title} default={t.default}>
                  {t.title}
                </Tab>
              ))}
            </TabList>
            {ORDER_TABS.map(t => (
              <TabPanel key={t.title}>
                <Orders
                  nextStatus={t.nextStatus}
                  options={{ query: t.query }}
                  action={t.action}
                  tab={t.title}
                  tabs={ORDER_TABS}
                  current_user={this.props.current_user}
                />
              </TabPanel>
            ))}
          </Tabs>
        </TabPanel>
      </Tabs>
    );
  }
}

export default Index;
