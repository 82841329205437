import { hubApi } from '../../middleware/api';
import { isLoggedIn } from '../../middleware/auth';
import { setWithExpiry } from '../../apiModules/setWithTtl';
import { getWithExpiry } from '../../apiModules/getWithTtl';
//Get company users
const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

//Get current user(me) from token in localStorage
const ME_FROM_TOKEN = 'ME_FROM_TOKEN';
const ME_FROM_TOKEN_SUCCESS = 'ME_FROM_TOKEN_SUCCESS';
const ME_FROM_TOKEN_FAILURE = 'ME_FROM_TOKEN_FAILURE';
const RESET_TOKEN = 'RESET_TOKEN';
const ACCESS_TOKEN = 'auth_token';
const CHECK_START = 'CHECK_START';
const CHECK_END = 'CHECK_END';

//Sign Up User
const SIGNUP_USER = 'SIGNUP_USER';
const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
const SIGNUP_USER_FAILURE = 'SIGNUP_USER_FAILURE';
const RESET_USER = 'RESET_USER';

//Sign In User
const SIGNIN_USER = 'SIGNIN_USER';
const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';

//User company invites
const GET_USER_INVITES_BEGIN = 'GET_USER_INVITES_BEGIN';
const GET_USER_INVITES_SUCCESS = 'GET_USER_INVITES_SUCCESS';
const GET_USER_INVITES_FAILURE = 'GET_USER_INVITES_FAILURE';

//validate email, if success, then load user and login
const VALIDATE_EMAIL = 'VALIDATE_EMAIL';
const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';

//called when email is updated in profile to update main user's email state
const UPDATE_USER_EMAIL = 'UPDATE_USER_EMAIL';

//log out user
const LOGOUT_USER = 'LOGOUT_USER';

const initialState = {
  user: null,
  status: null,
  error: null,
  loading: false,
  errors: { page: false },
};

//Action creators
const meFromTokenSuccess = currentUser => {
  return {
    type: ME_FROM_TOKEN_SUCCESS,
    payload: currentUser,
  };
};

const meFromTokenFailure = error => {
  return {
    type: ME_FROM_TOKEN_FAILURE,
    payload: error,
  };
};

const resetToken = _ => {
  localStorage.removeItem(ACCESS_TOKEN);
  return {
    type: RESET_TOKEN,
  };
};

// const signInUserSuccess = user => {
//   return {
//     type: SIGNIN_USER_SUCCESS,
//     payload: user,
//   };
// };

const signInUserFailure = error => {
  return {
    type: SIGNIN_USER_FAILURE,
    payload: error,
    error: error,
    errors: true,
    loading: false,
  };
};

const signUpUserSuccess = user => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: user,
  };
};

const signUpUserFailure = error => {
  return {
    type: SIGNUP_USER_FAILURE,
    payload: error,
    error: error,
    errors: true,
  };
};

const loadUsersBegin = () => ({
  type: GET_USERS_BEGIN,
});

const loadUsersSuccess = users => ({
  type: GET_USERS_SUCCESS,
  payload: users,
});

const loadUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

const logout = _ => {
  return {
    type: LOGOUT_USER,
  };
};

const resetUser = _ => {
  return {
    type: RESET_USER,
  };
};

const updateUserEmail = email => {
  return {
    type: UPDATE_USER_EMAIL,
    payload: email,
  };
};

export const checkStart = () => ({
  type: CHECK_START,
});

export const checkEnd = error => ({
  type: CHECK_END,
  payload: error,
});

//Actions
export const meFromToken = _ => async dispatch => {
  //check if the token is still valid, if so, get me from the server
  try {
    const userId = getWithExpiry('userId');

    const user = await hubApi(`/users/${userId}`);
    dispatch(meFromTokenSuccess(user));
    return {
      type: ME_FROM_TOKEN,
      payload: user,
    };
  } catch (e) {
    dispatch(meFromTokenFailure(e));
  }
};

export const loadUsers = _ => async dispatch => {
  try {
    dispatch(loadUsersBegin());
    const request = await hubApi('/users');
    dispatch(loadUsersSuccess(request));
    return request;
  } catch (e) {
    dispatch(loadUsersFailure(e));
  }
};

export const signInUser = data => async dispatch => {
  localStorage.removeItem(ACCESS_TOKEN);
  try {
    const request = await hubApi('/authenticate', {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setWithExpiry(ACCESS_TOKEN, request['auth_token'], 86400000);
    setWithExpiry('userId', request['user_id'], 86400000);
    setWithExpiry('authenticated', true, 86400000);
    if (request.error) {
      return dispatch(signInUserFailure(request));
    }
    dispatch(meFromTokenSuccess(request.user));
  } catch (e) {
    dispatch(signInUserFailure(e));
  }
};

const signUpUser = data => async dispatch => {
  localStorage.removeItem(ACCESS_TOKEN);
  try {
    const request = await hubApi('/signup', {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    setWithExpiry(ACCESS_TOKEN, request['auth_token'], 86400000);
    if (request.error) {
      return dispatch(signUpUserFailure(request));
    }
    dispatch(signUpUserSuccess(request));
    return {
      type: SIGNUP_USER,
      payload: request,
    };
  } catch (e) {
    dispatch(signUpUserFailure(e));
  }
};

export const logoutUser = () => async dispatch => {
  await dispatch(logout());
  localStorage.removeItem(ACCESS_TOKEN);
};

export const authenticate = _ => async dispatch => {
  if (isLoggedIn()) {
    dispatch(checkStart());
    try {
      const me = await dispatch(meFromToken());
      return me.payload.user;
    } catch (err) {
      dispatch(checkEnd(err));
    }
  }
};

//Action export
export const actions = {
  loadUsers,
  signUpUser,
  signInUser,
  resetUser,
  updateUserEmail,
  meFromToken,
  resetToken,
  logoutUser,
  authenticate,
};

export default function reducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case VALIDATE_EMAIL: //check email verification token
      return {
        ...state,
        user: null,
        status: 'validate_email',
        error: null,
        loading: true,
      };
    case VALIDATE_EMAIL_SUCCESS:
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
      }; //<-- authenticated & email verified
    case VALIDATE_EMAIL_FAILURE:
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'validate_email',
        error: error,
        loading: false,
      }; //<-- authenticated

    case ME_FROM_TOKEN: // loading currentUser("me") from jwttoken in local/session storage storage,
      return {
        ...state,
        user: null,
        status: 'storage',
        error: null,
        loading: true,
      };
    case CHECK_START: // loading currentUser("me") from jwttoken in local/session storage storage,
      return {
        ...state,
        user: null,
        status: 'signin',
        error: null,
        loading: true,
      };
    case CHECK_END: // loading currentUser("me") from jwttoken in local/session storage storage,
      return {
        ...state,
        user: null,
        status: 'signout',
        error: null,
        loading: false,
      };
    case ME_FROM_TOKEN_SUCCESS: //return user, status = authenticated and make loading = false
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
      }; //<-- authenticated
    case ME_FROM_TOKEN_FAILURE: // return error and make loading = false
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        user: null,
        status: 'storage',
        error: error,
        loading: false,
      };
    case RESET_TOKEN: // remove token from storage make loading = false
      return {
        ...state,
        user: null,
        status: 'storage',
        error: null,
        loading: false,
      };

    case SIGNUP_USER: // sign up user, set loading = true and status = signup
      return {
        user: null,
        status: 'signup',
        error: null,
        loading: true,
      };
    case SIGNUP_USER_SUCCESS: //return user, status = authenticated and make loading = false
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
      }; //<-- authenticated
    case SIGNUP_USER_FAILURE: // return error and make loading = false
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        user: null,
        status: 'signup',
        error: error,
        loading: false,
      };

    case SIGNIN_USER: // sign in user,  set loading = true and status = signin
      return {
        user: null,
        status: 'signin',
        error: null,
        loading: true,
      };
    case SIGNIN_USER_SUCCESS: //return authenticated user,  make loading = false and status = authenticated
      return {
        ...state,
        user: action.payload,
        status: 'authenticated',
        error: null,
        loading: false,
      }; //<-- authenticated
    case SIGNIN_USER_FAILURE: // return error and make loading = false
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        user: null,
        status: 'signInFail',
        error: error,
        loading: false,
      };
    case GET_USERS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        loading: false,
      };
    case GET_USERS_FAILURE: {
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        error,
        users: {},
        loading: false,
      };
    }
    case GET_USER_INVITES_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_USER_INVITES_SUCCESS:
      return {
        ...state,
        invitations: action.payload.invitations,
        loading: false,
      };
    case GET_USER_INVITES_FAILURE: {
      error = action.payload.data || { message: action.payload.message }; //2nd one is network or server down errors
      return {
        ...state,
        error,
        invitations: {},
        loading: false,
      };
    }
    case UPDATE_USER_EMAIL:
      return { ...state, user: { ...state.user, email: action.payload.email } };

    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        status: 'logout',
        error: null,
        loading: false,
      };

    case RESET_USER: // reset authenticated user to initial state
      return { ...state, user: null, status: null, error: null, loading: false };

    default:
      return state;
  }
}
