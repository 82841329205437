import { hubApi } from '../../middleware/api';
//GET contractors constants
const GET_CONTRACTORS_BEGIN = 'GET_CONTRACTORS_BEGIN';
const GET_CONTRACTORS_SUCCESS = 'GET_CONTRACTORS_SUCCESS';
const GET_CONTRACTORS_FAILURE = 'GET_CONTRACTORS_FAILURE';
//GET contractor constants
const GET_CONTRACTOR_BEGIN = 'GET_CONTRACTOR_BEGIN';
const GET_CONTRACTOR_SUCCESS = 'GET_CONTRACTOR_SUCCESS';
const GET_CONTRACTOR_FAILURE = 'GET_CONTRACTOR_FAILURE';
//POST contractor constants
const POST_CONTRACTOR_BEGIN = 'POST_CONTRACTOR_BEGIN';
const POST_CONTRACTOR_SUCCESS = 'POST_CONTRACTOR_SUCCESS';
const POST_CONTRACTOR_FAILURE = 'POST_CONTRACTOR_FAILURE';
//PATCH contractor constants
const PATCH_CONTRACTOR_BEGIN = 'PATCH_CONTRACTOR_BEGIN';
const PATCH_CONTRACTOR_SUCCESS = 'PATCH_CONTRACTOR_SUCCESS';
const PATCH_CONTRACTOR_FAILURE = 'PATCH_CONTRACTOR_FAILURE';
//DELETE contractor constants
const DELETE_CONTRACTOR_BEGIN = 'DELETE_CONTRACTOR_BEGIN';
const DELETE_CONTRACTOR_SUCCESS = 'DELETE_CONTRACTOR_SUCCESS';
const DELETE_CONTRACTOR_FAILURE = 'DELETE_CONTRACTOR_FAILURE';

const initialState = {
  contractors: [],
  contractor: {},
  loading: false,
  error: null,
};

//GET contractors list action creators

// const loadContractorsBegin = () => ({
//   type: GET_CONTRACTORS_BEGIN,
// });
const loadContractorsSuccess = contractors => ({
  type: GET_CONTRACTORS_SUCCESS,
  payload: contractors,
});

const loadContractorsFailure = error => ({
  type: GET_CONTRACTORS_FAILURE,
  payload: error,
});

//GET contractor show action creators
const loadContractorBegin = () => ({
  type: GET_CONTRACTOR_BEGIN,
});

const loadContractorSuccess = contractor => ({
  type: GET_CONTRACTOR_SUCCESS,
  payload: contractor,
});

const loadContractorFailure = error => ({
  type: GET_CONTRACTOR_FAILURE,
  payload: error,
});

//POST contractor create action creators
const createContractorBegin = () => ({
  type: POST_CONTRACTOR_BEGIN,
});

const createContractorSuccess = contractor => ({
  type: POST_CONTRACTOR_SUCCESS,
  payload: contractor,
});

const createContractorFailure = error => ({
  type: POST_CONTRACTOR_FAILURE,
  payload: error,
});

//PATCH contractor create action creators
const updateContractorBegin = () => ({
  type: PATCH_CONTRACTOR_BEGIN,
});

const updateContractorSuccess = contractor => ({
  type: PATCH_CONTRACTOR_SUCCESS,
  payload: contractor,
});

const updateContractorFailure = error => ({
  type: PATCH_CONTRACTOR_FAILURE,
  payload: error,
});

//PATCH contractor create action creators
const deleteContractorBegin = () => ({
  type: DELETE_CONTRACTOR_BEGIN,
});

const deleteContractorSuccess = id => ({
  type: DELETE_CONTRACTOR_SUCCESS,
  payload: id,
});

const deleteContractorFailure = error => ({
  type: DELETE_CONTRACTOR_FAILURE,
  payload: error,
});

//GET contractors actions
export const loadContractors = () => async dispatch => {
  try {
    const request = await hubApi('/contractors');
    if (request.error) {
      return dispatch(loadContractorsFailure(request));
    }
    dispatch(loadContractorsSuccess(request));
    return {
      type: GET_CONTRACTORS_BEGIN,
      payload: request,
    };
  } catch (e) {
    return loadContractorsFailure(e);
  }
};

//GET contractor actions
export const loadContractor = id => async dispatch => {
  try {
    dispatch(loadContractorBegin());
    const request = await hubApi(`/contractors/${id}`);
    if (request.error) {
      return dispatch(loadContractorFailure(request));
    }
    dispatch(loadContractorSuccess(request));
    return {
      type: GET_CONTRACTOR_BEGIN,
      payload: request,
    };
  } catch (e) {
    return loadContractorFailure(e);
  }
};

//POST contractor actions
export const createContractor = data => async dispatch => {
  dispatch(createContractorBegin());
  try {
    const request = await hubApi('/contractors', {
      method: 'POST',
      body: {
        ...data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(createContractorFailure(request.error))
      : dispatch(createContractorSuccess(request));
  } catch (e) {
    dispatch(createContractorFailure(e));
  }
};

//PATCH contractor actions
export const updateContractor = (id, data) => async dispatch => {
  dispatch(updateContractorBegin());
  try {
    const request = await hubApi(`/contractors/${id}`, {
      method: 'PATCH',
      body: {
        ...data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    request.error
      ? dispatch(updateContractorFailure(request.error))
      : dispatch(updateContractorSuccess(request));
  } catch (e) {
    dispatch(updateContractorFailure(e));
  }
};

//GET contractor actions
export const deleteContractor = id => async dispatch => {
  try {
    dispatch(deleteContractorBegin());
    await hubApi(`/contractors/${id}`, {
      method: 'DELETE',
    });
    dispatch(deleteContractorSuccess(id));
    return id;
  } catch (e) {
    return deleteContractorFailure(e);
  }
};

export const actions = {
  loadContractors,
  loadContractor,
  createContractor,
  updateContractor,
  deleteContractor,
};

//reducers
export default function contractorReducer(state = initialState, action) {
  switch (action.type) {
    case GET_CONTRACTORS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_CONTRACTORS_SUCCESS:
      return {
        ...state,
        contractors: action.payload.contractors,
        loading: false,
      };
    case GET_CONTRACTORS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        contractors: [],
        loading: false,
      };
    case GET_CONTRACTOR_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractor: action.payload.contractor,
        loading: false,
      };
    case GET_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        contractor: {},
        loading: false,
      };
    case POST_CONTRACTOR_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractor: action.payload.contractor,
        loading: false,
      };
    case POST_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        contractor: {},
        loading: false,
      };
    case PATCH_CONTRACTOR_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PATCH_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractor: action.payload.contractor,
        loading: false,
      };
    case PATCH_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        contractor: {},
        loading: false,
      };
    case DELETE_CONTRACTOR_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_CONTRACTOR_SUCCESS:
      return {
        ...state,
        contractor: action.payload.id,
        loading: false,
      };
    case DELETE_CONTRACTOR_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        contractor: {},
        loading: false,
      };
    default:
      return state;
  }
}
