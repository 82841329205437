import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './mil.module';

import { withStyles } from '@material-ui/core/styles';

import MuiAlert from '@material-ui/lab/Alert';
import { Button, TextField, Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  form: {
    // flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
    fontSize: '32px',
  },

  textField: {
    marginTop: theme.spacing(3),
  },
  signInButton: {
    margin: theme.spacing(3, 0),
  },
});

class EditMIL extends React.Component {
  state = {
    id: null,
    label_name: '',
    ingredient_name: '',
    percent_active: 0,
    item_code: '',
    unit: '',
    rdv: 0,
    packed_density: '',
    product_lot_number: '',
    availableUnits: ['mg', 'billion', 'mcg', 'MCU', 'IU', 'g'],
    successMessage: null,
    loading: false,
  };

  componentDidMount() {
    this.setState({ id: this.props.id }, this.fetchMI);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.id !== this.props.id) {
      this.setState({ id: this.props.id }, this.fetchMI);
    }
  }

  fetchMI = () => {
    this.props.actions.loadMI(this.state.id).then(() => {
      this.setState({
        label_name: this.props.mi.label_name,
        ingredient_name: this.props.mi.ingredient_name,
        percent_active: this.props.mi.percent_active,
        item_code: this.props.mi.item_code,
        unit: this.props.mi.unit,
        rdv: this.props.mi.rdv,
        packed_density: this.props.mi.packed_density,
        product_lot_number: this.props.mi.product_lot_number,
      });
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    let data = {
      label_name: this.state.label_name,
      ingredient_name: this.state.ingredient_name,
      percent_active: this.state.percent_active,
      item_code: this.state.item_code,
      unit: this.state.unit,
      rdv: this.state.rdv,
      packed_density: this.state.packed_density,
      product_lot_number: this.state.product_lot_number,
    };
    await this.setState({ loading: true });
    await this.props.actions.updateMI(this.state.id, data);
    if (!this.props.error) {
      await this.setState({
        successMessage: 'Master Ingredient updated successfully!',
        loading: false,
      });
      setTimeout(() => this.setState({ successMessage: null }), 5000);
    }
  };

  handleInputChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    this.setState({ [name]: value });
  };

  render() {
    const { classes, error } = this.props;
    const {
      label_name,
      ingredient_name,
      percent_active,
      item_code,
      unit,
      rdv,
      packed_density,
      product_lot_number,
      availableUnits,
      successMessage,
    } = this.state;

    return (
      <form className={classes.form} onSubmit={this.handleSubmit}>
        <Typography className={classes.title} variant="h2">
          Edit MIL
        </Typography>
        {error ? <Alert severity="error"> Error! {error} </Alert> : null}
        {successMessage ? <Alert severity="success"> {successMessage} </Alert> : null}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              fullWidth
              type="text"
              name="label_name"
              label="Label Name"
              value={label_name}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="text"
              name="ingredient_name"
              label="Ingredient Name"
              value={ingredient_name}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="percent_active"
              label="Percent Active"
              value={percent_active}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              name="item_code"
              label="Item Code"
              value={item_code}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              className={classes.textField}
              fullWidth
              select
              name="unit"
              label="Unit"
              value={unit}
              onChange={this.handleInputChange}
              variant="outlined"
            >
              {availableUnits.map(option => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              className={classes.textField}
              fullWidth
              type="number"
              name="rdv"
              label="RDV"
              value={rdv}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <TextField
              className={classes.textField}
              fullWidth
              name="packed_density"
              label="Packed Density"
              value={packed_density}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />
            <TextField
              className={classes.textField}
              fullWidth
              name="product_lot_number"
              label="Product Lot Number"
              value={product_lot_number}
              onChange={this.handleInputChange}
              variant="outlined"
              required
            />

            <Button
              className={classes.signInButton}
              color="primary"
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Edit MIL{' '}
              {this.state.loading ? (
                <i className="fa fa-spinner fa-spin" style={{ marginLeft: '10px' }} />
              ) : null}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = state => {
  return {
    mi: state.mil.mi,
    loading: state.mil.loading,
    error: state.mil.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EditMIL));
