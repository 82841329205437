import React from 'react';
import { Field, reduxForm } from 'redux-form';

import { TextareaAutosize, Button } from '@material-ui/core';

const renderTextField = ({ label, input }) => (
  <TextareaAutosize
    style={{ marginBottom: 10, width: '100%', padding: 5 }}
    label={label}
    placeholder={label}
    {...input}
    rows={4}
  />
);

let ContactForm = props => {
  const { handleSubmit } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Field component={renderTextField} name="body" label="Add a comment" />
      <Button variant="contained" color="primary" type="submit">
        Post
      </Button>
    </form>
  );
};

ContactForm = reduxForm({
  form: 'orderCommentsForm',
})(ContactForm);

export default ContactForm;
