import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  Divider,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  fonts: {
    fontWeight: 'bold',
  },
  inline: {
    display: 'inline',
  },
}));

const CommentsCard = props => {
  const { comment } = props;
  const classes = useStyles();
  return (
    <List className={classes.root}>
      <React.Fragment key={comment.id}>
        <ListItem key={comment.id} alignItems="flex-start">
          <ListItemAvatar>
            <Avatar>{comment.user.initials}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<Typography className={classes.fonts}>{comment.body}</Typography>}
            secondary={
              <span>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {comment.user.first_name} {comment.user.last_name}
                </Typography>
                {` - ${moment(comment.created_at).fromNow()}`}
              </span>
            }
          />
        </ListItem>
        <Divider />
      </React.Fragment>
    </List>
  );
};

export default CommentsCard;
