import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './accounts.module';
import vali from '../../middleware/vali';

const schema = {
  name: {
    expression: vali.string().required(),
    message: 'Facility name is required.',
  },
  street: {
    expression: vali.string().required(),
    message: 'Facility street is required.',
  },
  postCode: {
    expression: vali.string().required(),
    message: 'Facility postCode is required.',
  },
  facilitySupervisor: {
    expression: vali.string().required(),
    message: 'Facility Supervisor must be appointed name is required.',
  },
};

class CreateFacility extends Component {
  state = {
    name: '',
    street: '',
    postCode: '',
    sublocations: [],
    facilitySupervisor: '5dd1d5d684e31f470be9374e',
    errors: {},
  };
  handleSubmit = event => {
    event.preventDefault();
    const { name, street, postCode, sublocations, facilitySupervisor, errors } = this.state;
    const error = vali.validate({ name, street, postCode, facilitySupervisor }, schema);
    let address = { street, postCode };
    if (error) {
      errors[error.field] = error.message;
      this.setState({ errors });
      return;
    }
    this.props.actions.createFacility({
      name,
      address,
      sublocations,
      facilitySupervisor,
    });
    this.setState({ errors: '' });
    this.props.history.push('/facilities');
  };

  handleInputChange = event => {
    const { value, name } = event.target;

    this.setState({ [name]: value });
  };

  render() {
    const { name, street, postCode, facilitySupervisor, errors } = this.state;
    const { error, loading } = this.props;
    if (error) return <div>Error! {error.message}</div>;
    if (loading) return <div>Loading..</div>;
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div>
            <input
              type="textfield"
              name="name"
              value={name}
              onChange={this.handleInputChange}
              error={errors.name}
            />
          </div>
          <div>
            <input
              type="textfield"
              name="street"
              value={street}
              onChange={this.handleInputChange}
              error={errors.street}
            />
          </div>
          <div>
            <input
              type="textfield"
              name="postCode"
              value={postCode}
              onChange={this.handleInputChange}
              error={errors.postCode}
            />
          </div>
          <div>
            <input
              type="textfield"
              name="facilitySupervisor"
              value={facilitySupervisor}
              onChange={this.handleInputChange}
              error={errors.facilitySupervisor}
            />
          </div>
          <div>
            <button type="submit">Create</button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.facilities.loading,
    error: state.facilities.error,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateFacility);
