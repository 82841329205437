import React, { Component } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AddBoxIcon from '@material-ui/icons/AddBox';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import InputLabel from '@material-ui/core/InputLabel';
// import FormControl from '@material-ui/core/FormControl';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class IngredientsTable extends Component {
  state = {
    ingredients: [],
    master_ingredient_list: [],
    open: false,
    inputName: '',
    changeName: '',
    name: '',
    dosage: '',
    comments: '',
    unit: '',
    id: '',
    update: false,
    options: [],
    deleteIngredientConsent: false,
    deleteIngredientId: null,
    deleteIngredientName: null,
  };

  componentDidMount() {
    this.setState(
      {
        ingredients: this.props.ingredients,
        master_ingredient_list: this.props.master_ingredient_list,
      },
      () => {
        let options = [];
        this.state.master_ingredient_list.forEach(ingredient => {
          options.push(ingredient.label_name);
        });
        this.setState({ options: options });
      },
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.ingredients !== prevProps.ingredients) {
      this.setState({
        ingredients: this.props.ingredients,
      });
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  updateRow = id => {
    this.state.ingredients.forEach(ingredient => {
      if (ingredient.id === id) {
        let comments = ingredient.comments;
        if (!ingredient.comments) {
          comments = '';
        }
        let ingredient_name = ingredient.name;
        if (!ingredient.name) {
          ingredient_name = '';
        }
        let dosage = ingredient.dosage;
        if (!ingredient.dosage) {
          dosage = '';
        }
        this.setState({
          name: ingredient_name,
          inputName: ingredient_name,
          changeName: ingredient_name,
          comments: comments,
          dosage: dosage,
          unit: ingredient.unit,
          id: ingredient.id,
        });
      }
    });
    this.setState({ open: true, update: true, deleteIngredientConsent: false });
  };

  addRow = () => {
    this.setState({
      open: true,
      deleteIngredientConsent: false,
      update: false,
      name: '',
      inputName: '',
      changeName: '',
      comments: '',
      dosage: '',
      unit: '',
      id: '',
    });
  };

  handleIngredientUpdate = async event => {
    event.preventDefault();
    if (this.state.update) {
      let record = {
        id: this.state.id,
        name: this.state.name,
        unit: this.state.unit,
        comments: this.state.comments,
        dosage: this.state.dosage,
      };
      await this.props.actions.updateOrderIngredients(this.props.orderId, record);
      this.handleClose();
    } else {
      let record = {
        name: this.state.name,
        unit: this.state.unit,
        comments: this.state.comments,
        dosage: this.state.dosage,
      };
      await this.props.actions.postOrderIngredient(this.props.orderId, record);
      await this.props.actions.loadOrder(this.props.orderId);
      this.handleClose();
    }
  };

  deleteIngredient = async id => {
    await this.props.actions.deleteOrderIngredient(this.props.orderId, id);
    await this.props.actions.loadOrder(this.props.orderId);
  };

  renderMenuItems() {
    return this.state.master_ingredient_list.map(ingredient => {
      return (
        <MenuItem value={ingredient.label_name} data-unit={ingredient.unit} key={ingredient.id}>
          {ingredient.label_name}
        </MenuItem>
      );
    });
  }

  setUnit(ingredientName) {
    this.state.master_ingredient_list.forEach(ingredient => {
      if (ingredient.label_name === ingredientName) {
        this.setState({ unit: ingredient.unit });
      }
    });
  }

  render() {
    return (
      <div style={{ paddingRight: 10, paddingLeft: 10, paddingTop: 15 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
          <b style={{ fontSize: 18 }}>Order Ingredients</b>
          <AddBoxIcon style={{ fontSize: 32, cursor: 'pointer' }} onClick={this.addRow} />
        </div>

        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {!this.state.deleteIngredientConsent ? (
            <form onSubmit={event => this.handleIngredientUpdate(event)}>
              <DialogTitle id="alert-dialog-slide-title">
                <p>Note: The changes made here will not affect the price on original platform</p>
                <div style={{ marginBottom: 10 }}>
                  <Autocomplete
                    value={this.state.changeName}
                    inputValue={this.state.inputName}
                    onChange={(event, newValue) => {
                      if (newValue === null) {
                        newValue = '';
                      }
                      this.setState(
                        {
                          name: newValue,
                          changeName: newValue,
                        },
                        () => this.setUnit(newValue),
                      );
                    }}
                    onInputChange={(event, newInputValue) => {
                      if (newInputValue === null) {
                        newInputValue = '';
                      }
                      this.setState(
                        {
                          name: newInputValue,
                          inputName: newInputValue,
                        },
                        () => this.setUnit(newInputValue),
                      );
                    }}
                    options={this.state.options}
                    renderInput={params => (
                      <TextField {...params} label="Name" style={{ width: 320 }} />
                    )}
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <TextField
                    style={{ width: 320 }}
                    label="Dose"
                    value={this.state.dosage}
                    type="number"
                    onChange={event => this.setState({ dosage: event.target.value })}
                    required
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <TextField
                    style={{ width: 320 }}
                    label="Comments"
                    value={this.state.comments}
                    onChange={event => this.setState({ comments: event.target.value })}
                    required
                  />
                </div>
                <div style={{ marginBottom: 10 }}>
                  <TextField
                    style={{ width: 320 }}
                    label="Unit"
                    value={this.state.unit}
                    disabled
                    required
                  />
                </div>
              </DialogTitle>

              <DialogActions>
                <Button color="secondary" type="button" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button color="primary" type="submit">
                  {this.state.update ? 'Update' : 'Add'}
                </Button>
              </DialogActions>
            </form>
          ) : (
            <div>
              <DialogTitle id="alert-dialog-slide-title">
                <p>
                  Are you sure you want to delete{' '}
                  <b style={{ color: 'red' }}>{this.state.deleteIngredientName}</b> from Ingredient
                  List?
                </p>
              </DialogTitle>
              <DialogActions>
                <Button color="primary" type="button" onClick={this.handleClose}>
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => this.deleteIngredient(this.state.deleteIngredientId)}
                >
                  Delete
                </Button>
              </DialogActions>
            </div>
          )}
        </Dialog>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Action Items</b>
                </TableCell>
                <TableCell>
                  <b>Name</b>
                </TableCell>
                <TableCell>
                  <b>Dose</b>
                </TableCell>
                <TableCell>
                  <b>Unit</b>
                </TableCell>
                <TableCell>
                  <b>Comments</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.ingredients.map(row => (
                <TableRow key={row.name}>
                  <TableCell>
                    <CreateRoundedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.updateRow(row.id)}
                    />
                    <DeleteOutlineRoundedIcon
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        this.setState({
                          deleteIngredientConsent: true,
                          open: true,
                          deleteIngredientId: row.id,
                          deleteIngredientName: row.name,
                        })
                      }
                    />
                  </TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.dosage}</TableCell>
                  <TableCell>{row.unit}</TableCell>
                  <TableCell>{row.comments}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
}

export default IngredientsTable;
