import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './mil.module';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import MuiAlert from '@material-ui/lab/Alert';

import { CircularProgress, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';

import Edit from './edit';

import { IntegratedFiltering, DataTypeProvider } from '@devexpress/dx-react-grid';

import { Grid, Table, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
});

class Index extends React.Component {
  state = {
    mil: [],
    openDialog: false,
    dialogView: 'delete',
    actionId: null,
    actionName: null,
    deleteMessage: null,
    searchValue: '',
    open: false,
    selectedSearchOption: '',
    searchInput: '',
    searchOptions: [
      { name: 'Label Name', col: 'label_name' },
      { name: 'Item Code', col: 'item_code' },
    ],
    columns: [
      { name: 'label_name', title: 'Label Name' },
      { name: 'ingredient_name', title: 'Ingredient Name' },
      { name: 'percent_active', title: 'Pecent Active' },
      { name: 'item_code', title: 'Item Code' },
      { name: 'unit', title: 'Unit' },
      { name: 'rdv', title: 'RDV' },
      { name: 'packed_density', title: 'Packed Density' },
      { name: 'product_lot_number', title: 'Lot Number' },
      { name: 'actions', title: 'Actions', getCellValue: row => row.id },
    ],
  };

  getRowId = row => row.id;

  handleClickOpen(view, id, name) {
    console.log(view);
    this.setState({ dialogView: view, openDialog: true, actionId: id, actionName: name });
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  handleSelectClose = () => {
    this.setState({ open: false });
  };

  handleSelectOpen = () => {
    this.setState({ open: true });
  };

  handleSelectChange = event => {
    this.setState({ selectedSearchOption: event.target.value });
  };

  agree = () => {
    this.setState({ openDialog: false }, this.deleteMI);
  };

  disagree = () => {
    this.setState({ openDialog: false, actionId: null });
  };

  componentDidMount() {
    this.fetchMIL();
  }

  deleteMI = () => {
    this.props.actions.deleteMI(this.state.actionId).then(() => {
      if (!this.props.error) {
        this.setState({ deleteMessage: `Ingredient was successfully deleted!` });
        setTimeout(() => {
          this.setState({ deleteMessage: null }, this.fetchMIL);
        }, 5000);
      }
    });
  };

  fetchMIL = () => {
    this.props.actions.loadMIL().then(() => {
      this.setState({ mil: this.props.mil });
    });
  };

  ActionsFormatter = props =>
    props.value ? (
      <span>
        <Button
          color="primary"
          variant="contained"
          size="small"
          disabled={
            this.props.current_user &&
            this.props.current_user.user &&
            (this.props.current_user.user.role === 'admin' ||
              this.props.current_user.user.role === 'lab')
              ? false
              : true
          }
          onClick={() => this.handleClickOpen('edit', props.value, props.row.ingredient_name)}
        >
          Edit
        </Button>

        <Button
          color="secondary"
          variant="contained"
          size="small"
          disabled={
            this.props.current_user &&
            this.props.current_user.user &&
            (this.props.current_user.user.role === 'admin' ||
              this.props.current_user.user.role === 'lab')
              ? false
              : true
          }
          style={{ marginLeft: '8px' }}
          onClick={() => this.handleClickOpen('delete', props.value, props.row.ingredient_name)}
        >
          Delete
        </Button>
      </span>
    ) : null;

  ActionsProvider = props => (
    <DataTypeProvider formatterComponent={this.ActionsFormatter} {...props} />
  );

  filter = () => {
    let filtered_mil = [];

    this.props.mil.forEach(ingredient => {
      if (ingredient[this.state.selectedSearchOption]) {
        if (
          ingredient[this.state.selectedSearchOption]
            .toLowerCase()
            .includes(this.state.searchValue.toLowerCase())
        ) {
          filtered_mil.push(ingredient);
        }
      }
    });

    this.setState({ mil: filtered_mil });
  };

  handleSearchValueChange = e => {
    this.setState({ searchValue: e.target.value });
    let input = document.getElementById('search-value-input');

    let timeout = null;
    // Listen for keystroke events
    input.addEventListener('keyup', e => {
      clearTimeout(timeout);
      timeout = setTimeout(this.filter, 1000);
    });
  };

  render() {
    const { classes, loading, error } = this.props;

    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    return (
      <div>
        {error ? (
          <div style={{ marginBottom: 10 }}>
            <Alert severity="error"> Error! {error} </Alert>
          </div>
        ) : null}
        {this.state.deleteMessage ? (
          <div style={{ marginBottom: 10 }}>
            <Alert severity="success"> {this.state.deleteMessage} </Alert>
          </div>
        ) : null}

        <Dialog
          open={this.state.openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {this.state.dialogView === 'delete' ? (
            <div>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete <b>{this.state.actionName}</b> from Master
                  Ingredient List.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.disagree} color="primary">
                  Disagree
                </Button>
                <Button onClick={this.agree} color="secondary">
                  Agree
                </Button>
              </DialogActions>
            </div>
          ) : null}

          {this.state.dialogView === 'edit' ? (
            <DialogContent>
              <Edit id={this.state.actionId} />
            </DialogContent>
          ) : null}
        </Dialog>

        <div style={{ marginBottom: 10 }}>
          <Link to={`/mil/create`}>
            <Button variant="contained" color="primary">
              ADD MIL &nbsp; <i className="fas fa-edit"></i>
            </Button>
          </Link>
        </div>

        <Paper>
          <FormControl
            variant="outlined"
            style={{ marginLeft: 20, marginBottom: 10, marginTop: 20, width: 320 }}
          >
            <InputLabel id="demo-controlled-open-select-label">Filter By</InputLabel>
            <Select
              labelId="demo-controlled-open-select-label"
              id="demo-controlled-open-select"
              open={this.state.open}
              onClose={this.handleSelectClose}
              onOpen={this.handleSelectOpen}
              value={this.state.selectedSearchOption}
              onChange={this.handleSelectChange}
            >
              {this.state.searchOptions.map(option => {
                return (
                  <MenuItem value={option.col} key={option.col}>
                    {option.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl style={{ marginLeft: 20, marginBottom: 10, marginTop: 20, width: 320 }}>
            <TextField
              id="search-value-input"
              label="Search"
              value={this.state.searchValue}
              onChange={this.handleSearchValueChange}
              disabled={this.state.selectedSearchOption === '' ? true : false}
              variant="outlined"
            />
          </FormControl>

          <Grid rows={this.state.mil} columns={this.state.columns} getRowId={this.getRowId}>
            <this.ActionsProvider for={['actions']} />

            <IntegratedFiltering />
            <Table />
            <TableHeaderRow />
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    mil: state.mil.mil,
    loading: state.mil.loading,
    error: state.mil.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index));
