import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from './partners.module';

import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import MuiAlert from '@material-ui/lab/Alert';

import { CircularProgress, Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },

  card: {
    padding: 15,
    border: '1px solid #ddd',
    background: '#f7f7f7',
    borderRadius: '7px',
  },
});

class Index extends React.Component {
  state = {
    partners: [],
    openDialog: false,
    dialogView: 'delete',
    actionId: null,
    actionName: null,
    deleteMessage: null,
    searchValue: '',
    searchInput: '',
  };

  handleClickOpen(view, id, name) {
    this.setState({ dialogView: view, openDialog: true, actionId: id, actionName: name });
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  agree = () => {
    this.setState({ openDialog: false }, this.deleteMI);
  };

  disagree = () => {
    this.setState({ openDialog: false, actionId: null });
  };

  componentDidMount() {
    this.fetchPartners();
  }

  // deleteMI = () => {
  //   this.props.actions.deletePartner(this.state.actionId).then(() => {
  //     if (!this.props.error) {
  //       this.setState({ deleteMessage: `Partner was successfully deleted!` });
  //       setTimeout(() => {
  //         this.setState({ deleteMessage: null }, this.fetchPartners);
  //       }, 5000);
  //     }
  //   });
  // };

  fetchPartners = async () => {
    await this.props.actions.loadPartners();
    if (!this.props.error) {
      this.setState({ partners: this.props.partners });
    }
  };

  filter = () => {
    let filtered_partners = [];

    this.props.partners.forEach(partner => {
      if (partner.name.toLowerCase().includes(this.state.searchValue.toLowerCase())) {
        filtered_partners.push(partner);
      }
    });

    this.setState({ partners: filtered_partners });
  };

  handleSearchValueChange = e => {
    this.setState({ searchValue: e.target.value });
    let input = document.getElementById('search-value-input');

    let timeout = null;
    // Listen for keystroke events
    input.addEventListener('keyup', e => {
      clearTimeout(timeout);
      timeout = setTimeout(this.filter, 1000);
    });
  };

  renderPartners = classes => {
    return this.state.partners.map(partner => {
      return (
        <Grid item xs={3} key={partner.id}>
          <div className={classes.card}>
            <div style={{ fontSize: 28, marginBottom: 15 }}>{partner.name}</div>
            <Link to={`/partners/edit/${partner.id}`}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={
                  this.props.current_user &&
                  this.props.current_user.user &&
                  (this.props.current_user.user.role === 'admin' ||
                    this.props.current_user.user.role === 'lab')
                    ? false
                    : true
                }
              >
                Edit
              </Button>
            </Link>

            <Button
              color="secondary"
              variant="contained"
              size="small"
              disabled={true}
              style={{ marginLeft: '8px' }}
              onClick={() => this.handleClickOpen('delete', partner.id, partner.name)}
            >
              Delete
            </Button>
          </div>
        </Grid>
      );
    });
  };

  render() {
    const { classes, loading, error } = this.props;

    if (loading)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    return (
      <div>
        {error ? (
          <div style={{ marginBottom: 10 }}>
            <Alert severity="error"> Error! {error} </Alert>
          </div>
        ) : null}
        {this.state.deleteMessage ? (
          <div style={{ marginBottom: 10 }}>
            <Alert severity="success"> {this.state.deleteMessage} </Alert>
          </div>
        ) : null}

        <Dialog
          open={this.state.openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          {this.state.dialogView === 'delete' ? (
            <div>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete <b>{this.state.actionName}</b> from Partners List.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.disagree} color="primary">
                  Disagree
                </Button>
                <Button onClick={this.agree} color="secondary">
                  Agree
                </Button>
              </DialogActions>
            </div>
          ) : null}
        </Dialog>

        <div style={{ marginBottom: 10 }}>
          <Link to={`/partners/create`}>
            <Button variant="contained" color="primary">
              ADD Partner &nbsp; <i className="fas fa-edit"></i>
            </Button>
          </Link>
        </div>

        <Paper style={{ padding: 20 }}>
          <FormControl style={{ width: 320, marginBottom: 20 }}>
            <TextField
              id="search-value-input"
              label="Search"
              value={this.state.searchValue}
              onChange={this.handleSearchValueChange}
              disabled={this.state.selectedSearchOption === '' ? true : false}
              variant="outlined"
            />
          </FormControl>

          <Grid container spacing={2}>
            {this.renderPartners(classes)}
          </Grid>
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    partners: state.partners.partners,
    loading: state.partners.loading,
    error: state.partners.error,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Index));
