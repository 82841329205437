import React, { Component } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { actions } from './orders.module';
import { actions as comment_actions } from './OrderComments/orderComments.module';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { withStyles } from '@material-ui/core/styles';
import OrderComments from './OrderComments/index';
import { Paper } from '@material-ui/core';
import './orders.css';
import { CircularProgress, Button } from '@material-ui/core';

import IngredientsTable from './components/IngredientsTable';

import { ClientInfo, EventLog, EditOrderModal } from './components';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: 0,
    backgroundColor: 'rgba(112, 249, 200, 0.6)',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
  },
  issueContentPaper: {
    width: '100%',
  },
  topDate: {
    marginRight: '20px',
  },
  details: {
    minHeight: '40px',
    backgroundColor: 'white',
    margin: '10px',
    paddingLeft: '10px',
  },
  image: {
    maxHeight: 350,
    padding: 15,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 100,
    minHeight: 100,
    height: 800,
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
  orderShowInfo: {
    width: '100% !important',
  },
  orderLogs: {
    width: '35% !important',
  },
});

class Order extends Component {
  state = {
    anchorEl: null,
    shipstationSuccessMessage: null,
    generateProductLabelMessage: null,
    dispenserSuccessMessage: null,
    dispenserLoading: null,
  };
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.actions.loadOrder(id);
  }

  generateRefill = async _ => {
    const { id } = await this.props.match.params;
    this.props.actions.generateDocuments(id, 'refill');
    this.handleClose();
  };

  generateV6Base = async _ => {
    const { id } = await this.props.match.params;
    this.props.actions.generateDocuments(id, 'new');
    this.handleClose();
  };

  shipstationOrders = async action_type => {
    const { id } = await this.props.match.params;
    await this.props.actions.ShipstationOrder(null, false, action_type, id);
    if (!this.props.error) {
      if (action_type === 'create') {
        this.setState({ shipstationSuccessMessage: 'Shipstation order created successfully' });
      } else {
        this.setState({ shipstationSuccessMessage: 'Shipstation order deleted successfully' });
      }
      setTimeout(() => {
        this.setState({ shipstationSuccessMessage: null });
      }, 5000);
    }
    this.handleClose();
  };

  generateProductLabel = async () => {
    const { id } = await this.props.match.params;
    await this.props.actions.GenerateProductLabel(id);
    if (!this.props.error) {
      this.setState({ generateProductLabelMessage: 'Product label generated successfully' });
      setTimeout(() => {
        this.setState({ generateProductLabelMessage: null });
      }, 5000);
    }
  };

  sendToDispensar = async () => {
    const { id } = await this.props.match.params;
    let ids = [id];
    await this.setState({ dispenserLoading: true });
    await this.props.actions.sendToDispensar(ids);
    if (!this.props.error) {
      await this.props.actions.loadOrder(id);
      await this.setState({ dispenserSuccessMessage: 'Order successfully sent to dispnser.' });
      setTimeout(() => {
        this.setState({ dispenserSuccessMessage: null });
      }, 5000);
    }
    this.setState({ dispenserLoading: false });
  };

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    console.log(this.props.current_user);
    const { classes, error, loading, order } = this.props;

    if (loading || !order.ingredients)
      return (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      );
    if (order !== null) {
      return (
        <div>
          {error ? <Alert severity="error"> Error! {this.props.error} </Alert> : null}

          {this.state.generateProductLabelMessage ? (
            <Alert severity="success"> {this.state.generateProductLabelMessage} </Alert>
          ) : null}

          {this.state.shipstationSuccessMessage ? (
            <Alert severity="success"> {this.state.shipstationSuccessMessage} </Alert>
          ) : null}

          {this.state.dispenserSuccessMessage ? (
            <Alert severity="success"> {this.state.dispenserSuccessMessage} </Alert>
          ) : null}

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
              marginTop: 15,
            }}
          >
            <EditOrderModal order={this.props.order} current_user={this.props.current_user} />

            <Button
              style={{ margin: 3 }}
              variant="contained"
              color="secondary"
              onClick={this.generateProductLabel}
            >
              Generate Product Label
            </Button>

            {order.dispenser_available && !order.sent_to_weighing ? (
              <Button
                style={{ margin: 3 }}
                variant="contained"
                color="primary"
                onClick={this.sendToDispensar}
                disabled={this.state.dispenserLoading}
              >
                {!this.state.dispenserLoading ? 'Send To Dispenser' : 'Sending To Dispenser'}
              </Button>
            ) : null}

            <Button
              style={{ margin: 3 }}
              onClick={this.handleClick}
              variant="contained"
              color="primary"
            >
              Order Actions &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i>
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              keepMounted
              open={Boolean(this.state.anchorEl)}
              onClose={this.handleClose}
            >
              {order.refill_gen ? (
                <MenuItem onClick={this.generateRefill}>Generate Order Refill </MenuItem>
              ) : null}
              {order.docs_gen_base ? (
                <MenuItem onClick={this.generateV6Base}>Re-Generate Order Sheet</MenuItem>
              ) : (
                <MenuItem>Order Document Not Available Yet</MenuItem>
              )}
              <MenuItem onClick={() => this.shipstationOrders('create')}>
                {' '}
                Create Shipping Order{' '}
              </MenuItem>
              {order.shipstation_id ? (
                <MenuItem onClick={() => this.shipstationOrders('delete')}>
                  {' '}
                  Delete Shipping Order{' '}
                </MenuItem>
              ) : null}
            </Menu>
          </div>
          <Paper style={{ marginBottom: '15px' }}>
            <ClientInfo order={order} />
          </Paper>

          <Paper style={{ marginBottom: '15px' }}>
            <div style={{ width: '100% !important', display: 'flex' }}>
              <div className={classes.orderShowInfo} id="capture">
                {order.ingredients ? (
                  <IngredientsTable
                    ingredients={order.ingredients}
                    actions={this.props.actions}
                    orderId={this.props.match.params.id}
                    master_ingredient_list={order.master_ingredient_list}
                  />
                ) : (
                  <div>No Ingredients Listed</div>
                )}
              </div>
              <div className={classes.orderLogs}>
                {order.order_events ? (
                  <EventLog events={order.order_events} />
                ) : (
                  <div>No Events Found</div>
                )}
              </div>
            </div>
          </Paper>
          <Paper style={{ marginBottom: '15px', padding: '5px 15px 0' }}>
            <div className={classes.orderShowInfo}>
              <h1>Comments</h1>
              {/* <HeaderBar title={'Comments'} /> */}
              <OrderComments orderId={this.props.match.params.id} />
            </div>
          </Paper>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  return {
    order: state.orders.order,
    error: state.orders.error,
    loading: state.orders.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
    comment_actions: bindActionCreators(comment_actions, dispatch),
  };
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Order));
