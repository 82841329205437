import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../Orders/orders.module';
import { Link } from 'react-router-dom';

import { Paper, Button } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

import {
  VirtualTableState,
  SelectionState,
  SortingState,
  FilteringState,
  DataTypeProvider,
  SearchState,
} from '@devexpress/dx-react-grid';
import {
  Grid,
  VirtualTable,
  TableHeaderRow,
  TableFilterRow,
  TableSelection,
  SearchPanel,
  Toolbar,
} from '@devexpress/dx-react-grid-material-ui';

import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const getRowId = row => row.id;

const conditionalStyle = value => {
  if (value === 'Production') {
    return '#fbff00';
  } else if (value === 'Completed Production') {
    return '#ff8cf4';
  } else if (value === 'Formula Shipped') {
    return 'lightblue';
  } else if (value === 'Delivered') {
    return 'lightgreen';
  } else if (value === 'On Hold') {
    return 'grey';
  } else if (value === 'Cancelled') {
    return '#ff5f33';
  }
};

const StatusFormatter = ({ value }) => (
  <span
    style={{
      padding: '17px',
      width: '100%',
      backgroundColor: value === 'Pending Approval' ? 'orange' : conditionalStyle(value),
    }}
  >
    {value}
  </span>
);

const LinkFormatter = props => (
  <a href={props.value} target="_blank" style={{ color: 'grey' }} rel="noreferrer">
    {props.value ? <DescriptionIcon /> : null}
  </a>
);

const StatusProvider = props => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
);

const LinkProvider = props =>
  props.value !== null ? (
    <DataTypeProvider formatterComponent={LinkFormatter} {...props} props={props} />
  ) : null;

const VIRTUAL_PAGE_SIZE = 100;

const Orders = props => {
  const {
    orders,
    loading,
    totalRowCount,
    ordersFilters,
    totalRefillsCount,
    totalNewOrdersCount,
    error,
  } = props;

  const [_orders, setOrders] = useState(orders);
  const [requestedSkip, setRequestedSkip] = useState(0);
  const [take, setTake] = useState(VIRTUAL_PAGE_SIZE * 2);
  // const [lastQuery, setLastQuery] = useState('');
  const [selection, setSelection] = useState([]);
  const [rowCount, setRowCount] = useState(totalRowCount);
  const [sorting, setSorting] = useState([]);
  const [filters, setFilters] = useState([]);
  const [reload, setReload] = useState(true);
  const [searchValue, setSearchState] = useState('');
  const [_ordersFilters, setOrdersFilters] = useState(ordersFilters);
  const [anchorEl, setAnchorEl] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(0);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getColumns = () => {
    if (props.options.query.status === 'production') {
      return [
        {
          name: 'platform_id',
          title: 'Platform ID',
          getCellValue: row => (row.platform_id ? row.platform_id : row.partner_uuid),
        },
        {
          name: 'id',
          title: 'Order ID',
          getCellValue: row =>
            row.id ? (
              <Link className="btn" to={`/orders/${row.id}`}>
                {row.id}
              </Link>
            ) : (
              ''
            ),
        },
        { name: 'first_name', title: 'First Name', getCellValue: row => row.first_name },
        { name: 'last_name', title: 'Last Name', getCellValue: row => row.last_name },
        { name: 'email', title: 'Email', getCellValue: row => (row.email ? row.email : '') },
        {
          name: 'refill',
          title: 'Refill',
          getCellValue: row =>
            row.refill === 'Yes' ? (
              <i className="fa fa-check" style={{ color: 'green' }}></i>
            ) : (
              <i className="fa fa-times" style={{ color: 'red' }}></i>
            ),
        },
        { name: 'partner', title: 'Partner', getCellValue: row => row.partner },
        { name: 'completed_at', title: 'Completed At', getCellValue: row => row.completed_at },
        {
          name: 'ship_by',
          title: 'Ship By',
          getCellValue: row => (row.ship_by_date ? row.ship_by_date : ''),
        },
        { name: 'order_link', title: 'Order Link', getCellValue: row => row.order_link },
        {
          name: 'lab_state',
          title: 'Lab State',
          getCellValue: row => row.lab_state.replace('_', ' '),
        },
        { name: 'status_string', title: 'Status', getCellValue: row => row.status_string },
      ];
    } else {
      return [
        {
          name: 'platform_id',
          title: 'Platform ID',
          getCellValue: row => (row.platform_id ? row.platform_id : row.partner_uuid),
        },
        {
          name: 'id',
          title: 'Order ID',
          getCellValue: row =>
            row.id ? (
              <Link className="btn" to={`/orders/${row.id}`}>
                {row.id}
              </Link>
            ) : (
              ''
            ),
        },
        { name: 'first_name', title: 'First Name', getCellValue: row => row.first_name },
        { name: 'last_name', title: 'Last Name', getCellValue: row => row.last_name },
        { name: 'email', title: 'Email', getCellValue: row => (row.email ? row.email : '') },
        {
          name: 'refill',
          title: 'Refill',
          getCellValue: row =>
            row.refill === 'Yes' ? (
              <i className="fa fa-check" style={{ color: 'green' }}></i>
            ) : (
              <i className="fa fa-times" style={{ color: 'red' }}></i>
            ),
        },
        { name: 'partner', title: 'Partner', getCellValue: row => row.partner },
        { name: 'completed_at', title: 'Completed At', getCellValue: row => row.completed_at },
        {
          name: 'ship_by',
          title: 'Ship By',
          getCellValue: row => (row.ship_by_date ? row.ship_by_date : ''),
        },
        { name: 'order_link', title: 'Order Link', getCellValue: row => row.order_link },
        { name: 'status_string', title: 'Status', getCellValue: row => row.status_string },
      ];
    }
  };

  const [columns] = useState(getColumns());

  const getRemoteRows = (requestedSkip, take) => {
    setRequestedSkip(requestedSkip);
    setTake(take);
  };

  const changeFilters = value => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setFilters(value);
    setTypingTimeout(
      setTimeout(function() {
        setOrders([]);
        setRowCount(0);
        setReload(true);
        setRequestedSkip(0);
      }, 3000),
    );
  };

  const changeSorting = value => {
    setOrders([]);
    setSorting(value);
    setReload(true);
  };

  const changeSearch = value => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setSearchState(value);
    setTypingTimeout(
      setTimeout(function() {
        setOrders([]);
        setRowCount(0);
        setReload(true);
      }, 3000),
    );
  };

  const setOrdersWithFilters = orders => {
    if (_ordersFilters.refills) {
      setRowCount(totalRefillsCount);
    } else if (_ordersFilters.newOrders) {
      setRowCount(totalNewOrdersCount);
    } else {
      setRowCount(totalRowCount);
    }
    setOrders(orders);
  };

  const loadData = () => {
    // const filterStr = filters
    //   .map(({ columnName, value, operation }) => `["${columnName}","${operation}","${value}"]`)
    //   .join(',"",');

    // const sortingConfig = sorting.map(({ columnName, direction }) => ({
    //   selector: columnName,
    //   desc: direction === 'desc',
    // }));

    // const sortingStr = JSON.stringify(sortingConfig);
    // const filterQuery = filterStr ? `&filter=[${escape(filterStr)}]` : '';
    // const sortQuery = sortingStr ? `&sort=${escape(`${sortingStr}`)}` : '';

    const options = props.options;
    options.query.take = take;
    options.query.skip = requestedSkip;
    options.query.filters = JSON.stringify(filters);
    // options.query.filter = escape(filterStr);
    // options.query.sort = escape(`${sortingStr}`);
    options.query.sort = JSON.stringify(sorting);
    options.query.search = searchValue;

    if (_ordersFilters.refills) {
      options.query.refill = true;
    } else if (_ordersFilters.refills === false) {
      options.query.refill = false;
    } else {
      options.query.refill = null;
    }

    // const query = buildQueryString(
    //   options.query.status,
    //   requestedSkip,
    //   take,
    //   filters,
    //   sorting,
    // );
    if (reload && !loading) {
      props.actions.loadOrders(options).then(() => {
        // setLastQuery(query);
        setOrdersWithFilters(orders);
        setReload(false);
      });
    }
  };

  const applyFilters = filters => {
    props.actions.changeOrdersFilters(filters);
    setOrdersFilters(filters);
    setAnchorEl(null);
    setOrders([]);
    setReload(true);
    loadData();
  };

  useEffect(() => loadData());

  const updateOrderStates = async () => {
    await props.actions.updateOrderStates(selection);
    await setSelection([]);
    await setReload(true);
    loadData();
  };

  const renderFilterName = () => {
    if (_ordersFilters.refills) {
      return 'Refills';
    } else if (_ordersFilters.newOrders) {
      return 'New Orders';
    } else {
      return 'All';
    }
  };

  if (error) return <Alert severity="error"> Error! {error.message} </Alert>;

  return (
    <Paper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button style={{ margin: 3 }} onClick={handleClick} variant="contained" color="secondary">
          <i className="fa fa-filter" aria-hidden="true"></i> &nbsp; <b>Filter: </b> &nbsp;{' '}
          {renderFilterName()} &nbsp; <i className="fa fa-caret-down" aria-hidden="true"></i>
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => applyFilters({ refills: true, newOrders: false, all: false })}>
            Refills ({totalRefillsCount}) &nbsp;{' '}
            {_ordersFilters.refills ? (
              <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
            ) : null}
          </MenuItem>
          <MenuItem onClick={() => applyFilters({ refills: false, newOrders: true, all: false })}>
            New Orders ({totalNewOrdersCount}) &nbsp;{' '}
            {_ordersFilters.newOrders ? (
              <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
            ) : null}
          </MenuItem>
          <MenuItem onClick={() => applyFilters({ refills: null, newOrders: false, all: true })}>
            All ({totalRowCount}) &nbsp;{' '}
            {_ordersFilters.all ? (
              <i className="fa fa-check-circle" style={{ color: 'green' }}></i>
            ) : null}
          </MenuItem>
        </Menu>

        <Button
          style={{ margin: 3 }}
          variant="contained"
          color="primary"
          disabled={selection.length === 0 ? true : false}
          onClick={() => updateOrderStates()}
        >
          {props.action} {selection.length} orders to {props.nextStatus}
        </Button>
      </div>

      <Grid rows={_orders} columns={columns} getRowId={getRowId}>
        <StatusProvider for={['status_string']} />
        <LinkProvider for={['order_link']} />
        <SelectionState selection={selection} onSelectionChange={setSelection} />
        <SearchState value={searchValue} onValueChange={changeSearch} />
        <VirtualTableState
          loading={loading}
          totalRowCount={rowCount}
          pageSize={VIRTUAL_PAGE_SIZE}
          skip={requestedSkip}
          getRows={getRemoteRows}
        />
        <SortingState sorting={sorting} onSortingChange={changeSorting} />
        <FilteringState filters={filters} onFiltersChange={changeFilters} />

        <VirtualTable height={650} />
        <TableHeaderRow showSortingControls />
        <TableFilterRow showFilterSelector />
        <TableSelection selectByRowClick highlightRow />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    orders: state.orders.orders,
    totalRowCount: state.orders.totalRowCount,
    loading: state.orders.loading,
    error: state.orders.error,
    ordersFilters: state.orders.ordersFilters,
    totalRefillsCount: state.orders.totalRefillsCount,
    totalNewOrdersCount: state.orders.totalNewOrdersCount,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

export default withStyles({})(connect(mapStateToProps, mapDispatchToProps)(Orders));
