import { hubApi } from '../../middleware/api';

//GET users constants
const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
//GET user constants
const GET_USER_BEGIN = 'GET_USER_BEGIN';
const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
const GET_USER_FAILURE = 'GET_USER_FAILURE';
//POST user constants
const POST_USER_BEGIN = 'POST_USER_BEGIN';
const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
const POST_USER_FAILURE = 'POST_USER_FAILURE';
//PATCH user constants
const PATCH_USER_BEGIN = 'PATCH_USER_BEGIN';
const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
const PATCH_USER_FAILURE = 'PATCH_USER_FAILURE';
//DELETE user constants
const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

const initialState = {
  users: [],
  user: {},
  message: null,
  loading: false,
  error: null,
};

//GET users list action creators
const loadUsersBegin = () => ({
  type: GET_USERS_BEGIN,
});

const loadUsersSuccess = response => ({
  type: GET_USERS_SUCCESS,
  payload: response,
});

const loadUsersFailure = error => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

//GET user show action creators
const loadUserBegin = () => ({
  type: GET_USER_BEGIN,
});

const loadUserSuccess = response => ({
  type: GET_USER_SUCCESS,
  payload: response,
});

const loadUserFailure = error => ({
  type: GET_USER_FAILURE,
  payload: error,
});

//POST user create action creators
const createUserBegin = () => ({
  type: POST_USER_BEGIN,
});

const createUserSuccess = response => ({
  type: POST_USER_SUCCESS,
  payload: response,
});

const createUserFailure = error => ({
  type: POST_USER_FAILURE,
  payload: error,
});

//PATCH user update action creators
const updateUserBegin = () => ({
  type: PATCH_USER_BEGIN,
});

const updateUserSuccess = response => ({
  type: PATCH_USER_SUCCESS,
  payload: response,
});

const updateUserFailure = error => ({
  type: PATCH_USER_FAILURE,
  payload: error,
});

//Delete user action creators
const deleteUserBegin = () => ({
  type: DELETE_USER_BEGIN,
});

const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

const deleteUserFailure = error => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

export const loadUsers = () => async dispatch => {
  try {
    dispatch(loadUsersBegin());
    const request = await hubApi('/users');

    if (request.error) {
      return dispatch(loadUsersFailure(request));
    }
    dispatch(loadUsersSuccess(request));
  } catch (e) {
    return dispatch(loadUsersFailure(e));
  }
};

//GET user actions
export const loadUser = id => async dispatch => {
  try {
    dispatch(loadUserBegin());
    const request = await hubApi(`/users/${id}`);
    if (request.error) {
      return dispatch(loadUserFailure(request));
    }
    dispatch(loadUserSuccess(request));
  } catch (e) {
    return dispatch(loadUserFailure(e));
  }
};

//POST user actions
export const createUser = data => async dispatch => {
  console.log(data);
  dispatch(createUserBegin());
  try {
    const request = await hubApi('/users', {
      method: 'POST',
      body: { user: data },
    });
    request.error
      ? dispatch(createUserFailure(request.error))
      : dispatch(createUserSuccess(request));
  } catch (e) {
    dispatch(createUserFailure(e));
  }
};

//PATCH user actions
export const updateUser = (id, data) => async dispatch => {
  dispatch(updateUserBegin());
  try {
    const request = await hubApi(`/users/${id}`, {
      method: 'PATCH',
      body: {
        user: data,
      },
    });
    request.error
      ? dispatch(updateUserFailure(request.error))
      : dispatch(updateUserSuccess(request));
  } catch (e) {
    dispatch(updateUserFailure(e));
  }
};

//Delete user actions
export const deleteUser = id => async dispatch => {
  try {
    dispatch(deleteUserBegin());
    const request = await hubApi(`/users/${id}`, {
      method: 'DELETE',
    });
    request.error ? dispatch(deleteUserFailure(request.error)) : dispatch(deleteUserSuccess());
  } catch (e) {
    dispatch(deleteUserFailure(e));
  }
};

export const actions = {
  loadUsers,
  loadUser,
  createUser,
  updateUser,
  deleteUser,
};

//reducers
export default function usersManagementReducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case GET_USERS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USERS_FAILURE:
      error = action.payload.error || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        users: [],
        loading: false,
      };
    case GET_USER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case GET_USER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        user: {},
        loading: false,
      };
    case POST_USER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case POST_USER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        user: {},
        loading: false,
      };
    case PATCH_USER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case PATCH_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case PATCH_USER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        user: {},
        loading: false,
      };
    case DELETE_USER_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case DELETE_USER_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        loading: false,
      };
    default:
      return state;
  }
}
