import { hubApi } from '../../middleware/api';

//GET MILs constants
const GET_MIL_BEGIN = 'GET_MIL_BEGIN';
const GET_MIL_SUCCESS = 'GET_MIL_SUCCESS';
const GET_MIL_FAILURE = 'GET_MIL_FAILURE';

//GET MI constants
const GET_MI_BEGIN = 'GET_MI_BEGIN';
const GET_MI_SUCCESS = 'GET_MI_SUCCESS';
const GET_MI_FAILURE = 'GET_MI_FAILURE';

//POST MI constants
const POST_MI_BEGIN = 'POST_MI_BEGIN';
const POST_MI_SUCCESS = 'POST_MI_SUCCESS';
const POST_MI_FAILURE = 'POST_MI_FAILURE';

//PATCH MI constants
const PATCH_MI_BEGIN = 'PATCH_MI_BEGIN';
const PATCH_MI_SUCCESS = 'PATCH_MI_SUCCESS';
const PATCH_MI_FAILURE = 'PATCH_MI_FAILURE';

//DELETE MI constants
const DELETE_MI_BEGIN = 'DELETE_MI_BEGIN';
const DELETE_MI_SUCCESS = 'DELETE_MI_SUCCESS';
const DELETE_MI_FAILURE = 'DELETE_MI_FAILURE';

const initialState = {
  mil: [],
  mi: {},
  loading: false,
  error: null,
};

//GET MIL action creators
const loadMILBegin = () => ({
  type: GET_MIL_BEGIN,
});

const loadMILSuccess = response => ({
  type: GET_MIL_SUCCESS,
  payload: response,
});

const loadMILFailure = error => ({
  type: GET_MIL_FAILURE,
  payload: error,
});

//GET MI show action creators
const loadMIBegin = () => ({
  type: GET_MI_BEGIN,
});

const loadMISuccess = response => ({
  type: GET_MI_SUCCESS,
  payload: response,
});

const loadMIFailure = error => ({
  type: GET_MI_FAILURE,
  payload: error,
});

//POST MI action creators
const createMIBegin = () => ({
  type: POST_MI_BEGIN,
});

const createMISuccess = response => ({
  type: POST_MI_SUCCESS,
  payload: response,
});

const createMIFailure = error => ({
  type: POST_MI_FAILURE,
  payload: error,
});

//PATCH MI action creators
const updateMIBegin = () => ({
  type: PATCH_MI_BEGIN,
});

const updateMISuccess = response => ({
  type: PATCH_MI_SUCCESS,
  payload: response,
});

const updateMIFailure = error => ({
  type: PATCH_MI_FAILURE,
  payload: error,
});

//Delete user action creators
const deleteMIBegin = () => ({
  type: DELETE_MI_BEGIN,
});

const deleteMISuccess = () => ({
  type: DELETE_MI_SUCCESS,
});

const deleteMIFailure = error => ({
  type: DELETE_MI_FAILURE,
  payload: error,
});

export const loadMIL = () => async dispatch => {
  try {
    dispatch(loadMILBegin());
    const request = await hubApi('/mil');

    if (request.error) {
      return dispatch(loadMILFailure(request));
    }
    dispatch(loadMILSuccess(request));
  } catch (e) {
    return dispatch(loadMILFailure(e));
  }
};

//GET user actions
export const loadMI = id => async dispatch => {
  try {
    dispatch(loadMIBegin());
    const request = await hubApi(`/mil/${id}`);
    if (request.error) {
      return dispatch(loadMIFailure(request));
    }
    dispatch(loadMISuccess(request));
  } catch (e) {
    return dispatch(loadMIFailure(e));
  }
};

//POST MI actions
export const createMI = data => async dispatch => {
  dispatch(createMIBegin());
  try {
    const request = await hubApi('/mil', {
      method: 'POST',
      body: { master_ingredient: data },
    });
    request.error ? dispatch(createMIFailure(request.error)) : dispatch(createMISuccess(request));
  } catch (e) {
    dispatch(createMIFailure(e));
  }
};

//PATCH order actions
export const updateMI = (id, data) => async dispatch => {
  dispatch(updateMIBegin());
  try {
    const request = await hubApi(`/mil/${id}`, {
      method: 'PATCH',
      body: {
        master_ingredient: data,
      },
    });
    request.error ? dispatch(updateMIFailure(request.error)) : dispatch(updateMISuccess(request));
  } catch (e) {
    dispatch(updateMIFailure(e));
  }
};

// Delete MI actions
export const deleteMI = id => async dispatch => {
  try {
    dispatch(deleteMIBegin());

    const request = await hubApi(`/mil/${id}`, {
      method: 'DELETE',
    });

    console.log('Delete MI', request);
    request.error ? dispatch(deleteMIFailure(request.error)) : dispatch(deleteMISuccess());
  } catch (e) {
    dispatch(deleteMIFailure(e));
  }
};

export const actions = {
  loadMIL,
  loadMI,
  createMI,
  updateMI,
  deleteMI,
};

//reducers
export default function usersManagementReducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case GET_MIL_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_MIL_SUCCESS:
      return {
        ...state,
        mil: action.payload,
        loading: false,
      };
    case GET_MIL_FAILURE:
      error = action.payload.error || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        mil: [],
        loading: false,
      };
    case GET_MI_BEGIN:
      return {
        ...state,
        error: null,
        // loading: true,
      };
    case GET_MI_SUCCESS:
      return {
        ...state,
        mi: action.payload,
        // loading: false,
      };
    case GET_MI_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        mi: {},
        // loading: false,
      };
    case POST_MI_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case POST_MI_SUCCESS:
      return {
        ...state,
        mi: action.payload,
        loading: false,
      };
    case POST_MI_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        mi: {},
        loading: false,
      };
    case PATCH_MI_BEGIN:
      return {
        ...state,
        error: null,
        // loading: true,
      };
    case PATCH_MI_SUCCESS:
      return {
        ...state,
        mi: action.payload,
        // loading: false,
      };
    case PATCH_MI_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        mi: {},
        // loading: false,
      };
    case DELETE_MI_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case DELETE_MI_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
      };
    case DELETE_MI_FAILURE:
      error = action.payload.data || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        loading: false,
      };
    default:
      return state;
  }
}
