import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from '../views/Users/users.module';
import AuthRouter from './AuthRouter';

import Login from '../views/Users/signIn';
import SignUp from '../views/Users/signUp';

class AppRouter extends Component {
  state = {
    loading: true,
    user: null,
  };

  componentDidMount() {
    this.props.actions.authenticate().then(() => {
      this.setState({ user: this.props.user, loading: this.props.user.loading });
    });
  }

  componentDidUpdate() {
    if (this.state.user !== this.props.user) {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    const { loading } = this.state;
    if (!loading) {
      return (
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path="/login"
              render={props => <Login {...props} user={this.state.user} />}
            />
            <Route
              exact
              path="/signup"
              render={props => <SignUp {...props} user={this.state.user} />}
            />
            <AuthRouter user={this.state.user} />
          </Switch>
        </BrowserRouter>
      );
    }

    return <div style={{ marginTop: '15px', marginLeft: '15px' }}>Loading...</div>;
  }
}
const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
