const industries = [
  'Accounting',
  'Airlines/Aviation',
  'Architecture/Planning',
  'Automotive',
  'Aviation/Aerospace',
  'Biotechnology/Greentech',
  'Building Materials',
  'Business Supplies/Equipment',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Dairy',
  'Defense/Space',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Environmental Services',
  'Facilities Services',
  'Farming',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Furniture',
  'Glass/Ceramics/Concrete',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Industrial Automation',
  'Law Enforcement',
  'Library',
  'Logistics/Procurement',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Music',
  'Nanotechnology',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Other Industry',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Plastics',
  'Primary/Secondary Education',
  'Professional Training',
  'Public Safety',
  'Railroad Manufacture',
  'Ranching',
  'Recreational Facilities/Services',
  'Renewables/Environment',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Transportation',
  'Utilities',
  'Veterinary',
  'Warehousing',
  'Wholesale',
];

export default industries;
