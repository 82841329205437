const INSPECTION_NAMES = [
  'Internal scheduled audit',
  'Internal unscheduled inspection of teams/departments',
  'Internal scheduled inspection of departments',
  'Internal scheduled inspection of contractor/subcontractor',
  'Learning from incidents',
  'RCA / incident investigation',
];

export default INSPECTION_NAMES;
