import { hubApi } from '../../middleware/api';

const GET_DASHBOARD_ENTRIES_BEGIN = 'GET_DASHBOARD_ENTRIES_BEGIN';
const GET_DASHBOARD_ENTRIES_SUCCESS = 'GET_DASHBOARD_ENTRIES_SUCCESS';
const GET_DASHBOARD_ENTRIES_FAILURE = 'GET_DASHBOARD_ENTRIES_FAILURE';

const initialState = {
  dashboardEntries: {},
  loading: false,
  error: null,
};

const loadDashboardEntriesBegin = () => ({
  type: GET_DASHBOARD_ENTRIES_BEGIN,
});

const loadDashboardEntriesSuccess = response => ({
  type: GET_DASHBOARD_ENTRIES_SUCCESS,
  payload: response,
});

const loadDashboardEntriesFailure = error => ({
  type: GET_DASHBOARD_ENTRIES_FAILURE,
  payload: error,
});

export const loadDashboardEntries = options => async dispatch => {
  try {
    dispatch(loadDashboardEntriesBegin());
    const request = await hubApi('/dashboard', options);
    if (request.error) {
      return dispatch(loadDashboardEntriesFailure(request));
    }
    dispatch(loadDashboardEntriesSuccess(request));
  } catch (e) {
    return loadDashboardEntriesFailure(e);
  }
};

export const actions = {
  loadDashboardEntries,
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case GET_DASHBOARD_ENTRIES_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_DASHBOARD_ENTRIES_SUCCESS:
      return {
        ...state,
        dashboardEntries: action.payload,
        loading: false,
      };
    case GET_DASHBOARD_ENTRIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        dashboardEntries: [],
        loading: false,
      };
    default:
      return state;
  }
}
