import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
// //Redux
import { actions } from './users.module';

// //Middlewares
import vali from '../../middleware/vali';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Grid, Button, IconButton, TextField, Link, Typography, Checkbox } from '@material-ui/core';
import MuiPhoneInput from 'material-ui-phone-number';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import industryList from '../../lib/constants/industries';
import userValidationSchema from '../../lib/validationSchemas/userValidationSchema';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
  },
  grid: {
    height: '100%',
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '500px',
  },
  quoteText: {
    color: theme.palette.white,
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white,
  },
  bio: {
    color: theme.palette.white,
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(4),
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginTop: theme.spacing(2),
  },
  signInButton: {
    margin: theme.spacing(2, 0),
  },
});

class SignIn extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    companyName: '',
    companyEmail: '',
    jobTitle: '',
    companyAddress: '',
    companyCity: '',
    companyCountry: '',
    companyPhone: '',
    companyIndustry: '',
    loading: false,
    errors: {},
    checked: false,
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  handleSubmit = event => {
    event.preventDefault();
    this.setState({ loading: true });
    const {
      email,
      firstName,
      lastName,
      password,
      passwordConfirmation,
      jobTitle,
      companyName,
      companyEmail,
      companyPhone,
      companyAddress,
      companyCity,
      companyCountry,
      companyIndustry,
      errors,
    } = this.state;
    let error = vali.validate(
      {
        email,
        firstName,
        lastName,
        password,
        passwordConfirmation,
      },
      userValidationSchema,
    );
    if (error) {
      errors[error.field] = error.message;
      this.setState({ errors });
      return;
    }
    if (this.state.checked) {
      let company = {
        jobTitle,
        companyName,
        companyEmail,
        companyPhone,
        companyAddress,
        companyCity,
        companyCountry,
        companyIndustry,
      };
      error = vali.validate(
        {
          email,
          firstName,
          lastName,
          password,
          passwordConfirmation,
          jobTitle,
          companyName,
          companyEmail,
          companyPhone,
          companyAddress,
          companyCity,
          companyCountry,
          companyIndustry,
        },
        userValidationSchema,
      );
      this.props.actions.signUpUser({
        email,
        firstName,
        lastName,
        password,
        passwordConfirmation,
        company,
      });
    } else {
      this.props.actions.signUpUser({ email, firstName, lastName, password, passwordConfirmation });
    }
  };
  handleInputChange = event => {
    const { value, name } = event.target;
    const { errors } = this.state;

    errors[name] = null;
    errors.page = null;

    this.setState({ [name]: value, errors });
  };

  handleChecked = event => {
    this.setState({ checked: !this.state.checked });
  };

  handleIndustryAutocomplete = (event, value) => {
    this.setState({ companyIndustry: value });
  };

  handlePhoneChange = value => {
    this.setState({ companyPhone: value });
  };

  render() {
    const { classes } = this.props;
    const {
      email,
      password,
      firstName,
      lastName,
      passwordConfirmation,
      errors,
      checked,
      companyName,
      companyEmail,
      companyAddress,
      companyCity,
      companyCountry,
      jobTitle,
    } = this.state;

    return (
      <div className={classes.root}>
        <Grid className={classes.grid} container>
          <Grid className={classes.quoteContainer} item lg={5}>
            <div className={classes.quote}>
              <div className={classes.quoteInner}>
                <Typography className={classes.quoteText} variant="h6">
                  Hella narwhal Cosby sweater McSweeney's, salvia kitsch before they sold out High
                  Life.
                </Typography>
                <div className={classes.person}>
                  <Typography className={classes.name} variant="body1">
                    Takamaru Ayako
                  </Typography>
                  <Typography className={classes.bio} variant="body2">
                    Manager at inVision
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className={classes.content} item lg={7} xs={12}>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <IconButton onClick={this.handleBack}>
                  <ArrowBackIcon />
                </IconButton>
              </div>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={this.handleSubmit}>
                  <Typography className={classes.title} variant="h2">
                    Sign up
                  </Typography>
                  <Grid className={classes.socialButtons} container spacing={2}></Grid>
                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="email"
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email}
                    value={email}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="firstName"
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    value={firstName}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    style={{ marginBottom: 15 }}
                    type="text"
                    name="lastName"
                    label="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    value={lastName}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    type="password"
                    name="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password}
                    value={password}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <TextField
                    className={classes.textField}
                    fullWidth
                    type="password"
                    name="passwordConfirmation"
                    label="Password Confirmation"
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation}
                    value={passwordConfirmation}
                    onChange={this.handleInputChange}
                    variant="outlined"
                  />
                  <Typography className={classes.quoteText} variant="body">
                    I am starting an account for a company
                  </Typography>
                  <Checkbox
                    checked={checked}
                    onChange={this.handleChecked}
                    value="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  {checked ? (
                    <div>
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="companyName"
                        label="Company Name"
                        error={!!errors.companyName}
                        helperText={errors.companyName}
                        value={companyName}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="companyEmail"
                        label="Company Email"
                        error={!!errors.companyEmail}
                        helperText={errors.companyEmail}
                        value={companyEmail}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="companyAddress"
                        label="Address"
                        error={!!errors.companyAddress}
                        helperText={errors.companyAddress}
                        value={companyAddress}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="companyCity"
                        label="City"
                        error={!!errors.companyCity}
                        helperText={errors.companyCity}
                        value={companyCity}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="companyCountry"
                        label="Company Country"
                        error={!!errors.companyCountry}
                        helperText={errors.companyCountry}
                        value={companyCountry}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        fullWidth
                        style={{ marginBottom: 15 }}
                        type="text"
                        name="jobTitle"
                        label="Your job title"
                        error={!!errors.jobTitle}
                        helperText={errors.jobTitle}
                        value={jobTitle}
                        onChange={this.handleInputChange}
                        variant="outlined"
                      />
                      <MuiPhoneInput
                        onChange={this.handlePhoneChange}
                        className={classes.textField}
                        fullWidth
                        error={!!errors.companyPhone}
                        helperText={errors.companyPhone}
                        variant="outlined"
                        name="companyPhone"
                        label="Company Phone"
                        defaultCountry="ca"
                        regions={['north-america']}
                      />
                      <Autocomplete
                        id="combo-box-demo"
                        options={industryList}
                        onChange={(event, value) => this.handleIndustryAutocomplete(event, value)}
                        getOptionLabel={option => option}
                        renderInput={params => (
                          <TextField
                            {...params}
                            className={classes.textField}
                            fullWidth
                            style={{ marginBottom: 15 }}
                            type="text"
                            name="companyIndustry"
                            label="Industry"
                            error={!!errors.companyIndustry}
                            helperText={errors.companyIndustry}
                            variant="outlined"
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <Button
                    className={classes.signInButton}
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign up now
                  </Button>
                  <Typography color="textSecondary" variant="body1">
                    Have an account?
                    <Link component={RouterLink} to="/login" variant="h6">
                      Sign in
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

SignIn.propTypes = {
  history: PropTypes.object,
};

const mapStateToProps = state => ({
  user: state.user,
});
const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SignIn));
