import { hubApi } from '../../middleware/api';

//GET Ingredients constants
const GET_INGREDIENTS_BEGIN = 'GET_INGREDIENTS_BEGIN';
const GET_INGREDIENTS_SUCCESS = 'GET_INGREDIENTS_SUCCESS';
const GET_INGREDIENTS_FAILURE = 'GET_INGREDIENTS_FAILURE';

const initialState = {
  ingredients: [],
  ingredient: {},
  loading: false,
  error: null,
};

//GET Ingredients action creators
const loadIngredientsBegin = () => ({
  type: GET_INGREDIENTS_BEGIN,
});

const loadIngredientsSuccess = response => ({
  type: GET_INGREDIENTS_SUCCESS,
  payload: response,
});

const loadIngredientsFailure = error => ({
  type: GET_INGREDIENTS_FAILURE,
  payload: error,
});

export const loadIngredients = () => async dispatch => {
  try {
    dispatch(loadIngredientsBegin());
    const request = await hubApi('/dispensed_ingredients');

    if (request.error) {
      return dispatch(loadIngredientsFailure(request));
    }
    dispatch(loadIngredientsSuccess(request));
  } catch (e) {
    return dispatch(loadIngredientsFailure(e));
  }
};

export const actions = {
  loadIngredients,
};

//reducers
export default function ingredientsManagementReducer(state = initialState, action) {
  let error;
  switch (action.type) {
    case GET_INGREDIENTS_BEGIN:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case GET_INGREDIENTS_SUCCESS:
      return {
        ...state,
        ingredients: action.payload.slice(0, 50),
        loading: false,
      };
    case GET_INGREDIENTS_FAILURE:
      error = action.payload.error || action.payload.message; //2nd one is network or server down errors
      return {
        ...state,
        error: error,
        ingredients: [],
        loading: false,
      };

    default:
      return state;
  }
}
